import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
} from '@angular/core'
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSelectChange } from '@angular/material/select'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import {
	ChooseApprovalTemplateComponent,
	ChooseApprovalTemplateComponentResult,
} from 'core/components/create-new-approval-view/components/choose-approval-template/choose-approval-template.component'
import { indicate } from 'core/http'
import {
	ApprovalAssetModel,
	CollectionModel,
	EnumAppEndpoint,
	EnumFilterStateStatus,
	StepModel,
} from 'core/models'
import { NotificationMessage, NOTIFICATIONTYPE } from 'core/models/notification'
import { HeaderStateService } from 'core/services'
import { CollectionService } from 'core/services/collection.service'
import { ApprovalTemplateConverterService } from 'core/services/converter/approval-template-converter.service'
import { CreateApprovalService } from 'core/services/create-approval.service'
import { NotificationService } from 'core/services/notification.service'
import { Subject } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { Subscription } from 'rxjs/internal/Subscription'
import {
	ConfirmDialogComponent,
	ConfirmDialogDataModel,
} from 'shared/components/confirm-dialog/confirm-dialog.component'
import { isNullOrUndefined } from 'shared/utilities/utils'
import {
	ModifyApprovalStepDialogDataModel,
	ModifyApprovalStepsComponent,
} from './components/modify-approval-steps/modify-approval-steps.component'

export enum StartApproval {
	YES = 'yes',
	NO = 'no',
}

@Component({
	selector: 'app-create-new-approval',
	templateUrl: './create-new-approval-view.component.html',
	styleUrls: ['./create-new-approval-view.component.scss'],
})
export class CreateNewApprovalViewComponent implements OnInit, AfterContentInit, OnDestroy {
	approval = {
		steps: [] as StepModel[],
	} as ApprovalAssetModel

	formGroupState!: UntypedFormGroup
	formFields = {
		collection: 'collection',
		start: 'start',
		name: 'name',
		calcDueDate: 'calcDueDate',
		approvalSteps: 'approvalSteps',
	}
	fieldStartKeysData = [StartApproval.YES, StartApproval.NO]
	collections: Array<CollectionModel> = []

	loadingCollections$ = new Subject<boolean>()
	loadCollectionsSubscription!: Subscription
	sendingApproval$ = new Subject<boolean>()

	constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
		private headerStateService: HeaderStateService,
		private createApprovalService: CreateApprovalService,
		private approvalTemplateConverterService: ApprovalTemplateConverterService,
		private formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private translate: TranslateService,
		private collectionService: CollectionService,
		private notificationService: NotificationService
	) {
		this.formGroupState = this.formBuilder.group({
			collection: new UntypedFormControl(void 0, Validators.required),
			start: new UntypedFormControl(this.fieldStartKeysData[0]),
			name: new UntypedFormControl(void 0, [Validators.required]),
			calcDueDate: new UntypedFormControl(),
			approvalSteps: new UntypedFormControl(this.approval.steps, [Validators.required]),
		})

		this.formGroupState.controls[this.formFields.approvalSteps].valueChanges.subscribe(
			(approvalSteps: Array<StepModel>) => this.approvalStepsChanged(approvalSteps)
		)
	}

	ngOnInit(): void {
		this.headerStateService.setTitleTranslation('createNewApproval.titleHeader')
		this.changeDetector.detectChanges()
	}

	ngAfterContentInit(): void {
		this.loadCollections()
	}

	@HostListener('window:beforeunload')
	onBeforeUnload(): boolean {
		// when return value is false, the browser displays a native dialog to reload the page
		return !this.formGroupState.dirty
	}

	canDeactivate(): Observable<boolean> | boolean {
		if (this.formGroupState.dirty) {
			const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
				hasBackdrop: true,
				panelClass: ['page-unload-dialog'],
				disableClose: true,
				data: {
					title: this.translate.instant('createNewApproval.leavePageConfirmDialog.dialogTitle'),
					contentText: this.translate.instant(
						'createNewApproval.leavePageConfirmDialog.dialogMessage'
					),
				} as ConfirmDialogDataModel,
			})
			confirmDialog.componentInstance.hideClose = true
			confirmDialog.componentInstance.ok.subscribe(() => confirmDialog.close())

			return confirmDialog.componentInstance.ok
		}
		return true
	}

	loadCollections() {
		this.loadCollectionsSubscription = this.collectionService
			.getCollections()
			.pipe(indicate(this.loadingCollections$))
			.subscribe((collections) => {
				this.collections = collections
				this.checkCollections()
			})
	}

	checkCollections() {
		if (this.collections.length === 0) {
			this.notificationService.show(
				{ message: 'createNewApproval.errors.noCollection' } as NotificationMessage,
				NOTIFICATIONTYPE.ERROR
			)
		}
	}

	ngOnDestroy() {
		if (this.loadCollectionsSubscription) {
			this.loadCollectionsSubscription.unsubscribe()
		}
	}

	fillApproval() {
		// create approval doesn't need firstName and lastName, transform;
		this.approval.steps.map((value) => {
			value.approvers = value.approvers.map((user: any) => {
				return user.userId
			})
		})

		this.approval.collection = this.formGroupState.controls[this.formFields.collection].value
		this.approval.name = this.formGroupState.controls[this.formFields.name].value
		this.approval.dueDate = this.formGroupState.controls[this.formFields.calcDueDate].value
		this.approval.status = this.startApprovalToApprovalStatus(
			this.formGroupState.controls[this.formFields.start].value
		)
	}

	startApprovalToApprovalStatus(start: StartApproval): EnumFilterStateStatus {
		switch (start) {
			case StartApproval.YES:
				return EnumFilterStateStatus.IN_PROGRESS
			default:
				return EnumFilterStateStatus.PAUSED
		}
	}

	saveApproval() {
		this.fillApproval()
		this.createApprovalService
			.createApproval(this.approval)
			.pipe(indicate(this.sendingApproval$))
			.subscribe({
				next: (_) => {
					// TODO: show snackbar notification
					this.formGroupState.reset()
					this.closeView()
				},
			})
	}

	/**
	 * Access to form controls
	 */
	get f() {
		return this.formGroupState.controls
	}

	cancel() {
		this.closeView()
	}

	closeView() {
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigate([EnumAppEndpoint.Requester])
	}

	/**
	 * opens Dialog to add or modify an Approval Step
	 * @param step if step not given use add mode, otherwise edit mode
	 * @param index Array index of the given step
	 */
	openModifyApprovalSteps(step?: StepModel, index?: number) {
		const modifyStepDialog = this.dialog.open(ModifyApprovalStepsComponent, {
			hasBackdrop: true,
			panelClass: ['modify-approval-step-dialog'],
			disableClose: true,
			data: {
				index,
				step,
			} as ModifyApprovalStepDialogDataModel,
		})
		modifyStepDialog.afterClosed().subscribe((result: ModifyApprovalStepDialogDataModel) => {
			// when dialog close via cancel, the result is undefined, no changes required in UI
			if (result) {
				this.updateApprovalStep(result.step, result.index)
			}
		})
	}

	openTemplatesDialog() {
		const chooseApprovalDialog = this.dialog.open(ChooseApprovalTemplateComponent, {
			hasBackdrop: true,
			panelClass: ['choose-template-dialog'],
			disableClose: true,
		})
		chooseApprovalDialog
			.afterClosed()
			.subscribe((result: ChooseApprovalTemplateComponentResult) => {
				if (result?.selectedTemplate) {
					this.approvalTemplateConverterService
						.applyTemplate(this.approval, result.selectedTemplate)
						.subscribe((approval) => {
							this.formGroupState.controls[this.formFields.approvalSteps].setValue(approval.steps)
							this.validateApprovalSteps()
						})
				}
				if (result?.err) {
					this.notificationService.showError(result.err.error)
				}
			})
	}

	updateApprovalStep(step: StepModel, index?: number) {
		if (index !== undefined) {
			// edit approval step
			this.approval.steps[index] = step
		} else {
			// add new approval step
			this.approval.steps.push(step)
		}

		// after modify run control validation
		this.validateApprovalSteps()
	}

	deleteStep($event: number) {
		this.approval.steps.splice($event, 1)

		// after modify run control validation
		this.validateApprovalSteps()
	}

	/**
	 * Move an Approval step item up or down
	 * @param $event <{stepIndex, move}>
	 * stepIndex - current position in array
	 * move - array item move up or down to next position
	 */
	moveStep($event: { stepIndex: number; move: number }) {
		let newIndex = $event.stepIndex + $event.move
		if (newIndex >= this.approval.steps.length) {
			newIndex = this.approval.steps.length - 1
		} else if (newIndex < 0) {
			newIndex = 0
		}
		this.approval.steps.splice(newIndex, 0, this.approval.steps.splice($event.stepIndex, 1)[0])
		this.validateApprovalSteps()
	}

	// get the latest dueDate in Array. When array is empty dueDate is undefined
	approvalStepsChanged(approvalSteps?: Array<StepModel>) {
		this.updateApprovalDueDate(approvalSteps?.slice(-1)[0]?.dueDate || void 0)
	}

	updateApprovalDueDate(dueDateStep?: Date) {
		this.formGroupState.controls[this.formFields.calcDueDate].setValue(dueDateStep)
	}

	validateApprovalSteps() {
		this.formGroupState.controls[this.formFields.approvalSteps].updateValueAndValidity()
		this.formGroupState.controls[this.formFields.approvalSteps].markAsDirty()
	}

	editStep($event: { index: number; step: StepModel }) {
		this.openModifyApprovalSteps($event.step, $event.index)
	}

	onCollectionChanged($event: MatSelectChange) {
		const approvalNameControl = this.f[this.formFields.name]
		// handle selected collection name and copy into approvalName formControl
		if (
			!approvalNameControl.dirty ||
			approvalNameControl.value === '' ||
			isNullOrUndefined(approvalNameControl.value)
		) {
			approvalNameControl.setValue($event.value.name)
		}
	}
}
