<aside class='overflow-hidden d-flex flex-grow-1 flex-row'>
  <ng-container *ngIf='loading$ | async'>
    <app-loading-spinner class='primary-canto'></app-loading-spinner>
  </ng-container>
  <ng-container *ngIf='(loading$ | async) === false && approvalAsset'>
    <section class='col-2 overflow-hidden position-relative'>
      <mat-divider class='position-absolute dock-right height-100p' vertical='true'></mat-divider>
      <app-step-navigation [approvalAsset]='$any(approvalAsset)' [manager]='manager'></app-step-navigation>
    </section>
    <section class='d-flex flex-column flex-fill p-2'>
      <app-asset-requests-view class='overflow-hidden'
                               (eventCloseView)='onCloseView()'
                               [manager]='manager'
                               [approvalAsset]='$any(approvalAsset)'></app-asset-requests-view>
    </section>
  </ng-container>
</aside>

