import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { TranslateModule } from '@ngx-translate/core'
import { FilterNavStateComponent } from './filter-nav-state.component'

@NgModule({
	declarations: [FilterNavStateComponent],
	imports: [CommonModule, MatButtonModule, TranslateModule],
	exports: [FilterNavStateComponent],
})
export class FilterNavStateModule {}
