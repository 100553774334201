<div class='d-flex flex-row flex-center pb-2'>
  <div>
    <!--suppress CheckImageSize -->
    <img width='auto' height='30' alt='avatar' class='avatar img-circle' src='./assets/images/avatar.png'>
  </div>

  <div class='flex-1 flex-wrap'>{{user?.firstName}} {{user?.lastName}}</div>

  <div data-qa='action-buttons'>
    <ng-container *ngIf='removeClick.observers.length !== 0'>
      <button data-qa='button-remove' mat-mini-fab class='shadow-none' (click)='clickRemove(user)' color='primary'>
        <span class='material-icons'>close</span>
      </button>
    </ng-container>

    <ng-container *ngIf='addClick.observers.length !== 0'>
      <button data-qa='button-assign' mat-mini-fab [disabled]='user.isAssigned' class='shadow-none' (click)='clickAdd(user)' color='primary'>
        <span class='material-icons'>arrow_forward</span>
      </button>
    </ng-container>
  </div>
</div>
