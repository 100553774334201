'use strict'

declare global {
	export interface Number {
		toFormatPixel(): string

		toFormat(value: enumNumberFormatTypes): string
	}
}

export declare function toFormatPixel(): string

export declare function toFormat(): string

export declare enum enumNumberFormatTypes {}

export enum enumNumberFormatTypes {
	'%' = '%',
	'px' = 'px',
	'em' = 'em',
}

Number.prototype.toFormatPixel = function (): string {
	return this + 'px'
}
Number.prototype.toFormat = function (value): string {
	return this + value
}
