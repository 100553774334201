import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'
import { Activity, ApprovalAssetModel, AssetExtendModel, EnumFilterStateStatus } from 'core/models'
import { RatingService } from 'core/services'
import { CustomValidators } from 'core/validators/custom-validators'
import { Subject } from 'rxjs'
import { ApprovalBaseComponent } from 'shared/components/approval-base'

@Component({
	selector: 'app-asset-rating[approval][asset]',
	templateUrl: './asset-rating.component.html',
	styleUrls: ['./asset-rating.component.scss'],
})
export class AssetRatingComponent extends ApprovalBaseComponent implements OnInit, OnChanges {
	@Input() approval!: ApprovalAssetModel
	@Input() asset!: AssetExtendModel

	@Output() activitiesChanged = new EventEmitter<void>()

	updatingActivities$ = new Subject<boolean>()

	commentForm!: UntypedFormControl
	ratingForm!: UntypedFormControl

	constructor(
		private ratingService: RatingService,
		private changeDetector: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder
	) {
		super()
	}

	ngOnInit(): void {
		this.initForms()
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.initForms()
	}

	initForms() {
		this.commentForm = this.formBuilder.control(
			this.asset.newestComment?.comment,
			Validators.required
		)
		this.ratingForm = this.formBuilder.control(
			this.asset.newestActivity?.rating,
			CustomValidators.hasRating
		)
		this.updatingActivities$.next(false)
	}

	clickComment() {
		this.submitActivity(undefined, this.commentForm.value)
	}

	clickRating(rating: EnumFilterStateStatus) {
		if (this.commentForm.dirty) {
			this.submitActivity(rating, this.commentForm.value)
		} else {
			this.submitActivity(rating)
		}
	}

	clearActivity(activityId?: string) {
		if (activityId) {
			// disabling ui button until the changes are reloaded from parent component - see initForms
			this.updatingActivities$.next(true)
			this.ratingService
				.clearActivity(this.approval.id, this.approval.stepId, this.asset.id, activityId)
				.subscribe((_) => {
					this.activitiesChanged.emit()
				})
		}
	}

	submitActivity(rating?: EnumFilterStateStatus, comment?: string) {
		if (rating !== undefined || comment !== this.asset.newestComment?.comment) {
			// disabling ui button until the changes are reloaded from parent component - see initForms
			this.updatingActivities$.next(true)
			this.ratingService
				.submitActivity(this.approval.id, {
					stepId: this.approval.stepId,
					contentId: this.asset.id,
					rating,
					comment,
				} as Activity)
				.subscribe((_) => {
					this.activitiesChanged.emit()
				})
		}
	}
}
