import { ElementRef, Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'

const IMAGE_SIZE_PARAM = ':size'

export enum ImageOrientation {
	PORTRAIT = 'portrait',
	LANDSCAPE = 'landscape',
	SQUARE = 'square',
}

export const APP_FALLBACK_IMAGE = './assets/images/image-fallback.png'

@Injectable({
	providedIn: 'root',
})
export class ImageUtilsService {
	computeImage(url: string, size: number) {
		if (url) {
			return url.replace(IMAGE_SIZE_PARAM, size.toString())
		} else {
			// TODO error implementation
			return ''
		}
	}

	loadImage(src: string): Observable<HTMLImageElement> {
		const img = new Image()
		img.src = src
		const subscription$ = new Subject<HTMLImageElement>()
		img.onload = () => {
			subscription$.next(img)
			subscription$.complete()
		}

		img.onerror = (e) => {
			subscription$.error(e)
		}

		return subscription$
	}

	getImageOrientation(elementRef: ElementRef): ImageOrientation {
		const imageElement = elementRef.nativeElement as HTMLImageElement
		if (imageElement.height > imageElement.width) {
			return ImageOrientation.PORTRAIT
		} else if (imageElement.height < imageElement.width) {
			return ImageOrientation.LANDSCAPE
		} else {
			return ImageOrientation.SQUARE
		}
	}
}
