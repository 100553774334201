import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ApprovalAssetExtendModel, AssetExtendModel, EnumManager } from 'core/models'
import { ApprovalInteractionService } from 'core/services/approval-interaction.service'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-asset-requests-view[approvalAsset][eventCloseView][manager]',
	templateUrl: './asset-requests-view.component.html',
	styleUrls: ['./asset-requests-view.component.scss'],
})
export class AssetRequestsViewComponent implements OnInit {
	@Input() manager!: EnumManager
	@Input() approvalAsset!: ApprovalAssetExtendModel
	@Output() eventCloseView = new EventEmitter<any>()

	containedAssets = Array<AssetExtendModel>()
	countOfRatedItems$ = new Subject<number>()

	constructor(private interactionService: ApprovalInteractionService) {}

	ngOnInit(): void {
		if (this.approvalAsset?.containedAssets) {
			this.containedAssets = this.approvalAsset.containedAssets as Array<AssetExtendModel>
		}
	}

	closeView() {
		this.eventCloseView.emit()
	}

	getCountOfRatedItems(): number {
		return this.containedAssets.filter((value) => value.hasRating).length
	}

	onAssetLoaded() {
		const countOfRatedItems = this.getCountOfRatedItems()
		this.countOfRatedItems$.next(countOfRatedItems)
		this.interactionService.updateAllRated(
			countOfRatedItems,
			this.approvalAsset?.containedAssets?.length
		)
	}
}
