import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef,
} from '@angular/core'
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
} from '@angular/forms'
import { ItemSelectionEvent, Line } from './item-selection-event'

@Component({
	selector: 'app-select-list',
	templateUrl: './select-list.component.html',
	styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent implements OnChanges {
	@Input() data!: any[]
	@Input() checkedIcon = 'check_circle'
	@Input() uncheckedIcon = 'radio_button_unchecked'

	@Input() set multiSelectable(value: any) {
		this.multiSelectMode = value === 'true' || value === true
	}

	@Output() itemSelected = new EventEmitter<ItemSelectionEvent>()
	@ContentChild('lineTemplate') lineTemplateRef?: TemplateRef<any>
	@ContentChild('entryTemplate') entryTemplateRef?: TemplateRef<any>

	multiSelectMode!: boolean
	linesForms!: UntypedFormArray

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data?.currentValue) {
			const formGroups: UntypedFormGroup[] = []
			this.data.forEach((entry) => {
				formGroups.push(
					this.formBuilder.group({
						selected: false,
						data: entry,
					} as Line)
				)
			})
			this.linesForms = this.formBuilder.array(formGroups)
		}
	}

	clickedLine(formControl: AbstractControl, index: number) {
		if (!this.multiSelectMode) {
			let i = 0
			this.linesForms.controls.forEach((control) => {
				if (control !== formControl && control.value.selected) {
					control.value.selected = false
					const event = this.createItemSelectedEvent(control.value, i)
					this.itemSelected.emit(event)
				}
				++i
			})
		}

		formControl.value.selected = !formControl.value.selected
		const event = this.createItemSelectedEvent(formControl.value, index)
		this.itemSelected.emit(event)
	}

	createItemSelectedEvent(line: Line, index: number): ItemSelectionEvent {
		return { line, index } as ItemSelectionEvent
	}
}
