import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EnumAppEndpoint } from 'core/models'
import { AuthService } from 'core/services/auth.service'
import { DisplayErrorService } from 'core/services/error/display-error.service'
import { ApiHttpErrorCode, ApiHttpErrorResponse } from 'core/services/error/error-code.model'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private displayErrorService: DisplayErrorService,
		private authService: AuthService,
		private router: Router
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// handle http errors
		return next.handle(request).pipe(catchError((error) => this.errorHandler(error)))
	}

	errorHandler(error: HttpErrorResponse): Observable<HttpEvent<any>> {
		if (this.displayErrorService.isApiErrorResponse(error)) {
			return this.handleApiErrorResponse(error)
		}

		return throwError(() => error)
	}

	handleApiErrorResponse(error: HttpErrorResponse): Observable<HttpEvent<any>> {
		const errorResponse = error.error as ApiHttpErrorResponse
		switch (errorResponse.code) {
			case ApiHttpErrorCode.APP_TOKEN_EXPIRED:
				this.authService.logout()
				break
			case ApiHttpErrorCode.UNSPECIFIED_ERROR:
			case ApiHttpErrorCode.WORKFLOW_NOT_FOUND:
				this.routeToErrorPage()
				break
		}

		return throwError(() => error)
	}

	routeToErrorPage() {
		void this.router.navigate([EnumAppEndpoint.Error])
	}
}
