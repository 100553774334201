import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CoreModule } from 'core/core.module'
import { AuthInterceptor } from 'core/interceptors/auth.interceptor'
import { PlatformBrowserService, UiConfiguration } from 'core/services'
import { AuthService } from 'core/services/auth.service'
import { loadConfig } from 'core/services/config/load-config.service'
import { DisplayErrorService } from 'core/services/error/display-error.service'
import { GlobalErrorHandlerService } from 'core/services/error/global-error-handler.service'
import { HttpErrorInterceptor } from 'core/services/error/http-error.interceptor'
import { ComponentBootstrapModule } from 'features/pages/component-bootstrap/component-bootstrap.module'
import { ErrorComponent } from 'shared/components/error/error.component'
import { NotificationModule } from 'shared/components/notification/notification.module'
import { MaterialModule } from 'shared/modules/'
import { PendoService } from 'shared/services/pendo/pendo.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

export function initializeApp(http: HttpClient, uiConfig: UiConfiguration, pendo: PendoService) {
	return (): Promise<any> => {
		return new Promise<boolean>((resolve: (_: boolean) => void): void => {
			loadConfig(http, uiConfig).then(() => {
				pendo.init().then(() => {
					resolve(true)
				})
			})
		})
	}
}

@NgModule({
	declarations: [AppComponent, ErrorComponent],
	imports: [
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule,
		ComponentBootstrapModule,
		MaterialModule,
		TranslateModule,
		NotificationModule,
	],
	providers: [
		PlatformBrowserService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [HttpClient, UiConfiguration, PendoService],
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandlerService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			deps: [DisplayErrorService, AuthService, Router],
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private translate: TranslateService) {
		// initial we set the locale when the app start
		translate.setDefaultLang('en')
		translate.use('en')
	}
}
