import { Component, ElementRef, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { NOTIFICATIONTYPE } from 'core/models/notification'
import { PlatformBrowserService, UiConfiguration } from 'core/services'
import { AuthService } from 'core/services/auth.service'
import { HeaderStateService } from 'core/services/header-state.service'
import { NotificationService } from 'core/services/notification.service'
import { of } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title!: string

	constructor(
		private host: ElementRef,
		private zone: NgZone,
		public translate: TranslateService,
		private uiConfig: UiConfiguration,
		private platformBrowserService: PlatformBrowserService,
		private headerStateService: HeaderStateService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private notification: NotificationService
	) {
		platformBrowserService.init()
		this.defineLocale()
	}

	ngOnInit(): void {
		this.headerStateService.headerTitleChanged.subscribe((title) => {
			this.title = title
		})
		this.headerStateService.setTitleTranslation('app.title')

		this.route.queryParamMap.subscribe((params) => this.localeChangeAsQueryParam(params))

		document.documentElement.style.setProperty('--page-header-height', '66px')
	}

	defineLocale() {
		if (this.uiConfig?.supportedLocale) {
			this.translate.addLangs(this.uiConfig.supportedLocale)
		}
		if (!!this.uiConfig.defaultLocale) {
			this.translate.setDefaultLang(this.uiConfig.defaultLocale)
			this.translate.use(this.uiConfig.defaultLocale)
		} else {
			this.translate.setDefaultLang('en')
			this.translate.use('en')
		}
		const browserLang = this.translate.getBrowserLang()
		if (browserLang && this.uiConfig.supportedLocale?.includes(browserLang)) {
			this.translate.setDefaultLang(browserLang)
			this.translate.use(browserLang)
		} else {
			this.translate.setDefaultLang(this.translate.getDefaultLang())
			this.translate.use(this.translate.getDefaultLang())
		}
	}

	get env() {
		return environment
	}

	changeLanguage(language: string): Observable<boolean> {
		if (this.translate.langs.includes(language)) {
			return this.translate.use(language).pipe(switchMap((_) => of(true)))
		}
		return of(false)
	}

	localeChangeAsQueryParam(params: ParamMap) {
		if (params.has('locale')) {
			const paramLocale = params.get('locale')
			if (paramLocale) {
				this.changeLanguage(paramLocale).subscribe((_) => {
					// now we remove the query parameter locale from route
					// noinspection JSIgnoredPromiseFromCall
					this.router.navigate([], {
						queryParams: { locale: null },
						queryParamsHandling: 'merge',
					})
				})
			}
		}
	}

	doLogout() {
		this.authService.logout()
	}

	get notificationType() {
		return NOTIFICATIONTYPE
	}

	notificationShowMessage() {
		const refEle = this.notification.showMessage('A notification Message')
		refEle.onAction().subscribe(() => console.log('notification action clicked'))
		refEle.afterDismissed().subscribe(() => console.log('notification closed'))
	}

	notificationShow(type: NOTIFICATIONTYPE = NOTIFICATIONTYPE.DEFAULT) {
		this.notification.show({ message: 'A notification Message' }, type)
	}

	notificationError(code: number, message: string) {
		this.notification.showError({ code, message })
	}

	notificationShowMessageCustom() {
		const refEle = this.notification.show(
			{ message: 'A notification Message: no timeout and centered on top' },
			NOTIFICATIONTYPE.SUCCESS,
			{
				horizontalPosition: 'center',
				duration: 0,
			}
		)
		refEle.onAction().subscribe(() => console.log('notification action clicked'))
		refEle.afterDismissed().subscribe(() => console.log('notification closed'))
	}

	notificationClose() {
		this.notification.close()
	}
}
