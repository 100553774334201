import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { ImageNavigationComponent } from './image-navigation.component'

@NgModule({
	declarations: [ImageNavigationComponent],
	imports: [CommonModule, MatButtonModule],
	exports: [ImageNavigationComponent],
})
export class ImageNavigationModule {}
