import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { ButtonGroupRatingComponent } from 'core/components/button-group-rating/button-group-rating.component'
import { LocalizedStringPipeModule } from 'core/pipes/localized-string'

@NgModule({
	declarations: [ButtonGroupRatingComponent],
	exports: [ButtonGroupRatingComponent],
	imports: [CommonModule, MatButtonModule, LocalizedStringPipeModule],
})
export class ButtonGroupRatingModule {}
