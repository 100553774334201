<aside class='overflow-hidden d-flex flex-grow-1 flex-row'>
  <ng-container *ngIf='loading$ | async'>
    <app-loading-spinner class='primary-canto'></app-loading-spinner>
  </ng-container>
  <ng-container *ngIf='(loading$ | async) === false && workflowConfig'>
    <section class='d-flex flex-column flex-fill p-2'>
      <app-consent-list-view></app-consent-list-view>
    </section>
  </ng-container>
</aside>
