import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { TranslateModule } from '@ngx-translate/core'
import { ModifyApprovalStepsModule } from 'core/components/create-new-approval-view/components/modify-approval-steps/modify-approval-steps.module'
import { CreateNewApprovalViewRoutingModule } from 'core/components/create-new-approval-view/create-new-approval-view-routing.module'
import { CreateNewApprovalViewComponent } from 'core/components/create-new-approval-view/create-new-approval-view.component'
import { SelectListModule } from 'shared/components/select-list/select-list.module'
import { DynamicMomentPipeModule } from 'shared/pipes/dynamic-moment-pipe.module'
import { ChooseApprovalTemplateComponent } from './components/choose-approval-template/choose-approval-template.component'
import { ListApprovalStepsComponent } from './components/list-approval-steps/list-approval-steps.component'

@NgModule({
	declarations: [
		CreateNewApprovalViewComponent,
		ListApprovalStepsComponent,
		ChooseApprovalTemplateComponent,
	],
	imports: [
		CommonModule,
		CreateNewApprovalViewRoutingModule,
		TranslateModule,
		FormsModule,
		ModifyApprovalStepsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		ReactiveFormsModule,
		DynamicMomentPipeModule,
		MatDividerModule,
		MatDialogModule,
		MatIconModule,
		SelectListModule,
	],
	exports: [CreateNewApprovalViewComponent],
})
export class CreateNewApprovalViewModule {}
