<header class='page-header' role='banner'>
  <div>
    <app-header class='d-flex'></app-header>
  </div>
  <div class='flex-fill'></div>
  <div class='element-position-absolute-center header-title-wrapper'>
    <span data-qa='app-header-title' class='header-title text-center m-0'>{{title}}</span>
  </div>
  <div class='d-flex justify-content-end flex-row flex-nowrap'>
    <ng-container>
      <!-- TODO create language switcher component -->
      ´
      <article class='d-flex flex-center pe-3 mat-body-strong'>
        <mat-icon class='me-1'>public</mat-icon>
        <a data-qa='language-de' [ngClass]="{'primary-canto text-decoration-none': translate.currentLang === 'de'}" href='javascript:void(0)'
           (click)="changeLanguage('de')">DE</a>
        &nbsp;|&nbsp;
        <a data-qa='language-en' [ngClass]="{'primary-canto text-decoration-none': translate.currentLang === 'en'}" href='javascript:void(0)'
           (click)="changeLanguage('en')">EN</a>
      </article>
    </ng-container>
    <ng-container>
      <!-- TODO create component for menu -->
      <button data-qa='app-header-user-menu' class='dimension-auto' mat-icon-button disableRipple
              matMenuTriggerRestoreFocus='true'
              [matMenuTriggerFor]='avatarMenu'
      >
        <img alt='avatar' class='avatar img-circle' src='./assets/images/avatar.png'>
      </button>
      <mat-menu
        #avatarMenu='matMenu'
      >
        <span>
        <button disabled mat-menu-item>
          <span>About Us</span>
        </button>
        <button data-qa='app-header-user-menu-logout' mat-menu-item (click)='doLogout()'>
          <span>{{'components.avatarMenu.buttonLogout' | translate}}</span>
        </button>
        </span>
      </mat-menu>
    </ng-container>
  </div>
</header>

<section class='content' role='main'>

  <router-outlet class='page-router'></router-outlet>
</section>

<ng-container *ngIf="env.zone === 'localhost' || env.zone === 'test'">
  <div style='background: red;
    color: white;
    position: fixed;
    padding: 0.5em;
    bottom: 0;
    right: 0;' class='environment h5'>Environment:&nbsp;<h3 class='environment-zone'>{{env.zone}}</h3>
    <p class='visually-hidden'>API Endpoint: {{env.api.url}}/</p>
    <mat-expansion-panel #utilsPanel expanded='false'>
      <mat-expansion-panel-header collapsedHeight='auto' expandedHeight='auto' class='p-2'>
        Utils
      </mat-expansion-panel-header>
      <div *ngIf='utilsPanel.expanded'>
        <!-- example to work with the snackbar, later we can remove it -->
        Notifications
        <mat-icon (click)='notificationClose()'>close</mat-icon>
        :
        <button mat-raised-button class='notification default me-1' (click)='notificationShowMessage()'>Default</button>
        <button mat-raised-button class='notification success me-1' (click)='notificationShow(notificationType.SUCCESS)'>Success</button>
        <button mat-raised-button class='notification info me-1' (click)='notificationShow(notificationType.INFO)'>Info</button>
        <button mat-raised-button class='notification warning me-1' (click)='notificationShow(notificationType.WARNING)'>Warning</button>
        <button mat-raised-button class='notification error me-1' (click)="notificationError(400, 'Bad request')">Error 400</button>
        <button mat-raised-button class='notification error me-1' (click)="notificationError(404, 'Not found')">Error 404</button>
        <button mat-raised-button class='notification default me-1' (click)='notificationShowMessageCustom()'>Custom</button>
      </div>
    </mat-expansion-panel>
  </div>
</ng-container>
