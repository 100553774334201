import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatFormField } from '@angular/material/form-field'
import { indicate } from 'core/http'
import { UserExtendModel, UserModel } from 'core/models'
import { UserService } from 'core/services/user.service'
import { Subject } from 'rxjs'

export interface ModifyApprovalUserDialogDataModel {
	assignedApprovers: Array<UserModel | any>
}

@Component({
	selector: 'app-modify-approval-user',
	templateUrl: './modify-approval-user.component.html',
	styleUrls: ['./modify-approval-user.component.scss'],
})
export class ModifyApprovalUserComponent implements OnInit, AfterViewInit {
	@ViewChild('searchField', { static: true }) searchFieldElement!: MatFormField

	formGroupState!: UntypedFormGroup
	approversList: Array<UserModel | any> = []
	allUsers: Array<UserExtendModel> = []

	loadingUsers$ = new Subject<boolean>()

	formFields = {
		assignedApprovers: 'assignedApprovers',
	}
	searchFieldHeight = 0
	searchQuery!: string

	constructor(
		private formBuilder: UntypedFormBuilder,
		private userService: UserService,
		public componentDialogRef: MatDialogRef<ModifyApprovalUserComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModifyApprovalUserDialogDataModel
	) {}

	ngOnInit(): void {
		this.formGroupState = this.formBuilder.group({
			assignedApprovers: new UntypedFormControl(this.approversList, [Validators.required]),
		})

		setTimeout(() => {
			this.loadUsers()
		})
	}

	loadUsers() {
		this.userService
			.getUsers()
			.pipe(indicate(this.loadingUsers$))
			.subscribe((users: Array<any>) => this.constructUsers(users as Array<UserExtendModel>))
	}

	constructUsers(users: Array<UserExtendModel>) {
		users.filter((user, index) => {
			user.isAssigned = this.data.assignedApprovers.some(
				(assignedUser) => assignedUser.userId === user.userId
			)

			user.index = index
			return user
		})
		this.approversList.push(...users.filter((u) => u.isAssigned))
		this.allUsers = users

		this.validateAssignedUser()
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.searchFieldHeight = this.searchFieldElement._elementRef.nativeElement.clientHeight
		})
	}

	get f() {
		return this.formGroupState.controls
	}

	validateAssignedUser() {
		this.formGroupState.controls[this.formFields.assignedApprovers].updateValueAndValidity()
	}

	save() {
		this.componentDialogRef.close(
			this.formGroupState.controls[this.formFields.assignedApprovers].value
		)
	}

	assignUser(user: UserExtendModel) {
		user.isAssigned = true
		this.approversList.push(user)
		this.validateAssignedUser()
	}

	removeAssigned(user: UserExtendModel, index: number) {
		user.isAssigned = false
		this.approversList.splice(index, 1)
		this.validateAssignedUser()
	}
}
