<ng-template #RequesterCurrentStepButtonTemplate let-step='step' let-index='index'>
  <ng-container *ngIf='index <= approvalAsset.stepIndex'>
    <button mat-button (click)='onClickViewComments(index)' color='primary'
            class='m-1 active btn-rounded'>{{'stepNavigation.buttonViewComments' | translate}}</button>
  </ng-container>
  <ng-container *ngIf='index === approvalAsset.stepIndex'>
    <ng-container *ngIf='approvalAsset.status === FilterStateStatus.APPROVED'>
      <button mat-button data-qa='button-close-approval' [disabled]='$any(changingApprovalStatus$ | async)'
              (click)='onChangeApprovalStatus(FilterStateStatus.CLOSED, closeView)' color='primary'
              class='m-1 active btn-rounded'>{{'stepNavigation.buttonClose' | translate}}</button>
    </ng-container>
    <ng-container
      *ngIf='(approvalAsset.status !== FilterStateStatus.APPROVED && approvalAsset.status !== FilterStateStatus.CLOSED)'>
      <button mat-button data-qa='button-send-approval-reminder' [disabled]='$any(sendingReminder$ | async)'
              (click)='onSendReminder()' color='primary'
              class='m-1 active btn-rounded'>{{'stepNavigation.buttonSendReminder' | translate}}</button>
      <button
        *ngIf='approvalAsset.status === FilterStateStatus.IN_PROGRESS || approvalAsset.status === FilterStateStatus.OVERDUE'
        mat-button data-qa='button-pause-approval' [disabled]='$any(changingApprovalStatus$ | async)'
        (click)='onChangeApprovalStatus(FilterStateStatus.PAUSED)' color='primary'
        class='m-1 active btn-rounded'>{{'stepNavigation.buttonPause' | translate}}</button>
      <button *ngIf='approvalAsset.status === FilterStateStatus.PAUSED' mat-button data-qa='button-resume-approval'
              [disabled]='$any(changingApprovalStatus$ | async)'
              (click)='onChangeApprovalStatus(FilterStateStatus.IN_PROGRESS)' color='primary'
              class='m-1 active btn-rounded'>{{'stepNavigation.buttonResume' | translate}}</button>
      <button mat-button data-qa='button-reset-approval' color='primary' class='m-1 active btn-rounded'
              (click)='openResetStepDialog(step.id)'>{{'stepNavigation.resetStep.buttonReset' | translate}}</button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #ApproverCurrentStepButtonTemplate let-currentStep='currentStep' let-index='index'>
  <ng-container
    *ngIf='approvalAsset.status === FilterStateStatus.FINISHED || approvalAsset.status === FilterStateStatus.REJECTED'>
    <app-thumb-rating
      class='d-flex flex-center flex-row'
      [status]='approvalAsset.status === FilterStateStatus.FINISHED ? currentStep.rating : FilterStateStatus.REJECTED'
    ></app-thumb-rating>
  </ng-container>

  <!-- Any Approvals they are closed or ends has no finish button -->
  <ng-container *ngIf='approvalAsset.status !== FilterStateStatus.CLOSED'>
    <ng-container
      *ngIf='approvalAsset.status !== FilterStateStatus.FINISHED && approvalAsset.status !== FilterStateStatus.REJECTED && !approvalAsset.isApprovalEnds'>
      <button data-qa='finish-step-button' [disabled]='finishButtonDisabled || $any(loadingStepFinishStatus$ | async)'
              (click)='onClickFinish()'
              mat-button color='primary'
              class='m-1 active btn-rounded'>{{'stepNavigation.buttonFinish' | translate}}</button>
      <i data-qa='finish-step-button-hint'
         *ngIf='finishButtonDisabled'>{{'stepNavigation.buttonFinishHint' | translate}}</i>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #CurrentStepTemplate let-step='step' let-currentStep='currentStep' let-index='index'>
  <div class='mat-h2 mb-1 pt-2'>
    <div data-qa='current-step-title'
         *ngIf='!approvalAsset.isApprovalEnds'>{{'stepNavigation.' + manager + '.titleCurrentStep' | translate}}</div>
    <div data-qa='finished-approval-step-title'
         *ngIf='approvalAsset.isApprovalEnds'>{{'stepNavigation.requester.titleCloseCurrentStep' | translate}}</div>
  </div>

  <ng-container *ngIf='approvalAsset.isApprovalEnds || approvalAsset.status === FilterStateStatus.CLOSED'>
    <!-- All closed approvals are APPROVED -->
    <app-thumb-rating
      class='pt-3 pb-3 d-flex flex-row flex-center'
      [status]='FilterStateStatus.APPROVED'
    ></app-thumb-rating>
  </ng-container>

  <ng-container *ngIf='!approvalAsset.isApprovalEnds'>
    <div class='mat-body-1'>
      <div>
        <div data-qa='current-step-name'>{{step.name}}</div>
      </div>
      <div>
        <div data-qa='current-step-description' class='mat-body-2'>{{step.description}}</div>
      </div>
    </div>
    <div class='mat-body-1' *ngIf='step.dueDate'>
      <div>
        {{'stepNavigation.labelDueDate' | translate}}
      </div>
      <div class='mat-body-2'>
        <mat-icon class='pe-1' inline='inline'>calendar_today</mat-icon>
        <span>{{step.dueDate | dynamicMoment | async}}</span>
      </div>
    </div>
    <div class='mat-body-1' *ngIf='manager === ManagerEnum.Requester'>
      <div>{{'stepNavigation.labelStatus' | translate}}</div>
      <div class='mat-body-2' *ngIf='!allAssetsHaveRatings'>
        <span
          data-qa='status-waiting-for-rating'>{{'stepNavigation.labelStatusWaitingRatingDescription' | translate}}</span>
      </div>
      <div class='mat-body-2' *ngIf='allAssetsHaveRatings'>
        <span
          data-qa='status-waiting-for-finishing'>{{'stepNavigation.labelStatusWaitingFinishedDescription' | translate}}</span>
      </div>
    </div>
    <div class='mat-body-1' *ngIf='manager === ManagerEnum.Requester'>
      <div>{{'stepNavigation.labelApprover' | translate}}</div>
      <div class='mat-body-2' *ngFor='let user of step.approvers'>
        <div>{{user | userFullname}}</div>
      </div>
    </div>
  </ng-container>

  <div class='d-flex flex-column align-content-center'>
    <ng-container *ngIf='manager === ManagerEnum.Requester'>
      <ng-container
        *ngTemplateOutlet='RequesterCurrentStepButtonTemplate; context: {step: step, index: index}'></ng-container>
    </ng-container>
    <ng-container *ngIf='manager === ManagerEnum.Approver'>
      <ng-container
        *ngTemplateOutlet='ApproverCurrentStepButtonTemplate; context: {currentStep: currentStep, index: index}'></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #otherStepTemplate let-step='step' let-index='index'>
  <mat-expansion-panel class='mat-elevation-z0'>
    <!--suppress TypeScriptUnresolvedVariable -->
    <mat-expansion-panel-header collapsedHeight='auto' expandedHeight='auto' class='ps-0 pe-2'>
      {{step.name}}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class='mat-body-1'>
        <div>
          <div class='mat-body-2'>{{step.description}}</div>
        </div>
      </div>
      <div class='mat-body-1' *ngIf='step.dueDate'>
        <div>
          {{'stepNavigation.labelDueDate' | translate}}
        </div>
        <div class='mat-body-2'>
          <mat-icon class='pe-1' inline='inline'>calendar_today</mat-icon>
          <span>{{step.dueDate | dynamicMoment | async}}</span>
        </div>
      </div>
      <div class='mat-body-1' *ngIf='manager === ManagerEnum.Requester'>
        <div>{{'stepNavigation.labelApprover' | translate}}</div>
        <div class='mat-body-2' *ngFor='let user of step.approvers'>
          <div>{{user | userFullname}}</div>
        </div>
      </div>
      <div class='d-flex flex-column align-content-center'>
        <ng-container *ngIf='manager === ManagerEnum.Requester'>
          <ng-container
            *ngTemplateOutlet='RequesterCurrentStepButtonTemplate; context: {step: step, index: index}'></ng-container>
        </ng-container>
      </div>
    </ng-template>
    <ng-container>

    </ng-container>
  </mat-expansion-panel>
</ng-template>

<ng-template #InfoPanel>
  <mat-expansion-panel class='mat-elevation-z0' expanded='true'>
    <mat-expansion-panel-header collapsedHeight='auto' expandedHeight='auto' class='ps-0 pe-2'>
      <div class='mat-h2 m-0'>{{'stepNavigation.infoPanel.titleInfo' | translate}}</div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class='mat-body-1 pt-2'>
        <div>
          {{'stepNavigation.infoPanel.labelApprovalName' | translate}}
        </div>
        <div class='mat-body-2'>
          <span>{{approvalAsset.name}}</span>
        </div>
      </div>
      <div class='mat-body-1'>
        <div>
          {{'stepNavigation.infoPanel.labelCollectionName' | translate}}
        </div>
        <div class='mat-body-2'>
          <span>{{approvalAsset.collection.name}}</span>
        </div>
      </div>
      <div class='d-flex flex-center pb-4'>
        <a [href]='approvalAsset.collection.url' target='_blank' mat-button class='btn-rounded active'>
          <mat-icon class='' [inline]='true'>launch</mat-icon>
          {{'stepNavigation.infoPanel.buttonOpenCollectionLabel' | translate}}
        </a>
      </div>
    </ng-template>
  </mat-expansion-panel>
</ng-template>

<div #StepNavigationContainer class='step-navigation-wrapper d-flex flex-grow-1 flex-column overflow-hidden-100p'>
  <div class='p-2'>
    <ng-container *ngTemplateOutlet='InfoPanel'></ng-container>
  </div>
  <mat-divider></mat-divider>
  <div class='overflow-auto height-100p'>
    <ng-container *ngFor='let step of approvalAsset?.steps;let i = index; let last = last'>
      <div #StepNavigationItem class='pt-2 pb-2'
           [ngClass]="{'current-step': approvalAsset.stepIndex === i}">
        <div class='ps-2 pe-2'>
          <ng-container
            *ngTemplateOutlet='approvalAsset.stepIndex === i? CurrentStepTemplate: otherStepTemplate; context: {step: step, currentStep: approvalAsset.currentStep, index: i}'></ng-container>
        </div>
      </div>
      <mat-divider [ngClass]="{'mb-4': last}"></mat-divider>
    </ng-container>
  </div>
</div>
