import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router'
import { EnumAppEndpoint } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { UserRoleGuard } from 'core/services/user-role.guard'

@Injectable({
	providedIn: 'root',
})
export class RedirectRoleGuard implements CanActivate {
	constructor(private userRoleGuard: UserRoleGuard, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		this.userRoleGuard.canActivate(route).subscribe(() => this.redirectTo(route.queryParams))
		return false
	}

	redirectTo(queryParams: Params) {
		if (this.userRoleGuard.hasRole(APP_PERMISSION.request)) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([EnumAppEndpoint.Requester], { queryParams: { ...queryParams } })
		} else {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([EnumAppEndpoint.Approver], { queryParams: { ...queryParams } })
		}
	}
}
