<aside class='overflow-hidden d-flex flex-grow-1 flex-column'>
  <ng-container *ngIf='currentActiveAssetIndex === undefined && (loading$ | async)'>
    <div class='position-absolute'>
      <app-loading-spinner class='primary-canto'></app-loading-spinner>
    </div>
  </ng-container>
  <ng-container>
    <section>
      <ng-container *ngIf='(loading$ | async) === false || workflowConfig'>
        <header class='header-sub-view' *ngIf='currentActiveAssetIndex>=0 && workflowConfig'>
          <section class='d-flex flex-row ms-2 p-2 ps-0'>
            <div class='m-0 mat-h3 d-flex flex-fill justify-content-start align-items-center'>
              <span data-qa='approval-asset-count'>{{'detailsView.headerAssetNavigationTitle' | translate: {
                from: (currentActiveAssetIndex + 1),
                to: workflowConfig.containedAssets.length
              } }}</span>
            </div>
            <div class='d-flex flex-fill justify-content-end align-items-center me-5'>
              <button data-qa='details-navigation-left-button' mat-mini-fab
                      (click)='onClickNavigate(EnumNavigateDirectionImage.LEFT)'
                      color='primary'
                      [ngClass]='{hide: currentActiveAssetIndex < 1 }'
                      class='shadow-none'>
                    <span class='material-icons'>
                      chevron_left
                    </span>
              </button>
              <button data-qa='details-navigation-right-button' mat-mini-fab
                      (click)='onClickNavigate(EnumNavigateDirectionImage.RIGHT)'
                      color='primary'
                      [ngClass]='{hide: currentActiveAssetIndex >= workflowConfig.containedAssets.length-1}'
                      class='shadow-none ms-2'>
                    <span class='material-icons'>
                      chevron_right
                    </span>
              </button>
            </div>
            <div class='d-flex justify-content-end'>
              <button data-qa='close-details-view-button' mat-mini-fab
                      (click)='onCloseView()'
                      color='primary'
                      class='shadow-none'>
                    <span class='material-icons'>
                      close
                    </span>
              </button>
            </div>
          </section>
          <mat-divider></mat-divider>
        </header>
      </ng-container>
    </section>
    <div class='d-flex flex-grow-1 flex-row overflow-hidden-100p'>
      <ng-container>
        <ng-container *ngIf='currentActiveAssetIndex>=0 && workflowConfig'>
          <section class='image-navigation-wrapper p-2 flex-1'>
            <ng-container *ngIf='loading$ | async'>
              <div class='position-absolute'>
                <app-loading-spinner class='primary-canto'></app-loading-spinner>
              </div>
            </ng-container>
            <app-image-navigation (eventNavigate)='onClickNavigate($event)'
                                  [imageSrc]='workflowConfig.containedAssets[currentActiveAssetIndex].imageUrl'
                                  [currentActiveAssetIndex]='currentActiveAssetIndex'
                                  [numberOfAssets]='workflowConfig.containedAssets.length'></app-image-navigation>
          </section>
          <section class='details-sidebar-wrapper overflow-hidden-100p'>
            <app-consent-details-sidebar [workflowId]='workflowId' [scheme]='scheme'
                                         [contentId]='contentId'
                                         [statusList]='workflowConfig.configuration.statusList'
                                         [assetStatus]='workflowConfig.containedAssets[currentActiveAssetIndex].status'></app-consent-details-sidebar>
          </section>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</aside>
