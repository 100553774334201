import { Component, Input } from '@angular/core'
import { indicate } from 'core/http'
import { EnumAssetScheme } from 'core/models'
import { AssetStatus, StatusItem, StatusList } from 'core/models/consents'
import { ConsentInteractionService } from 'core/services/consent-interaction.service'
import { ConsentService } from 'core/services/consent.service'
import { Subject } from 'rxjs'
import { ReloadRouterService } from 'shared/services/reload-router.service'

@Component({
	selector: 'app-consent-details-sidebar[workflowId][scheme][contentId][statusList][assetStatus]',
	templateUrl: './consent-details-sidebar.component.html',
	styleUrls: ['./consent-details-sidebar.component.scss'],
})
export class ConsentDetailsSidebarComponent {
	@Input() workflowId!: string
	@Input() scheme!: EnumAssetScheme
	@Input() contentId!: string
	@Input() statusList!: StatusList
	@Input() assetStatus!: AssetStatus

	updatingStatus$ = new Subject<boolean>()

	constructor(
		private consentService: ConsentService,
		private consentInteractionService: ConsentInteractionService,
		private reloadRouterService: ReloadRouterService,
	) {}

	get workflowConfig() {
		return this.consentInteractionService.workflowConfig
	}

	onStatusClick(status?: StatusItem): void {
		this.consentService
			.updateStatus(this.workflowId, this.scheme, this.contentId, status?.id)
			.pipe(indicate(this.updatingStatus$))
			.subscribe({
				next: (newStatusList) => {
					if (newStatusList.contentId === this.contentId && newStatusList.scheme === this.scheme) {
						// UI should be re-rendered only if the component displays the same asset
						this.assetStatus = newStatusList.statusIds
					}
					this.consentInteractionService.updateStatusListFromAsset(
						newStatusList.scheme,
						newStatusList.contentId,
						newStatusList.statusIds,
					)
				},
				error: (error) => this.handleError(error),
			})
	}

	handleError(error: any): void {
		console.error('Error:', error)
		void this.reloadRouterService.navigateSelf()
	}
}
