import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { AssetRatingModule } from './asset-rating/asset-rating.module'
import { DetailsSidebarComponent } from './details-sidebar.component'

@NgModule({
	declarations: [DetailsSidebarComponent],
	imports: [CommonModule, AssetRatingModule, MatButtonModule, TranslateModule, MatIconModule],
	exports: [DetailsSidebarComponent],
})
export class DetailsSidebarModule {}
