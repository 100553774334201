import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { ApprovalRequestOverviewRoutingModule } from 'core/components/approval-request-overview/approval-request-overview-routing.module'
import { ApprovalRequestOverviewComponent } from 'core/components/approval-request-overview/approval-request-overview.component'
import {
	ApprovalRequestsViewModule,
	FilterNavStateModule,
} from 'core/components/approval-request-overview/components/index'

@NgModule({
	declarations: [ApprovalRequestOverviewComponent],
	imports: [
		CommonModule,
		FilterNavStateModule,
		ApprovalRequestOverviewRoutingModule,
		ApprovalRequestsViewModule,
		MatDividerModule,
	],
})
export class ApprovalRequestOverviewModule {}
