import { Injectable } from '@angular/core'
import { EnumFilterStateStatus, EnumManager } from 'core/models'
import { Observable, of, throwError } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class StateFilterService {
	readonly statusRequestsList = [
		EnumFilterStateStatus.ALL,
		EnumFilterStateStatus.IN_PROGRESS,
		EnumFilterStateStatus.PAUSED,
		EnumFilterStateStatus.OVERDUE,
		EnumFilterStateStatus.REJECTED,
		EnumFilterStateStatus.APPROVED,
		EnumFilterStateStatus.CLOSED,
	]
	readonly statusApprovalsList = [
		EnumFilterStateStatus.ALL,
		EnumFilterStateStatus.IN_PROGRESS,
		EnumFilterStateStatus.OVERDUE,
		EnumFilterStateStatus.CLOSED,
	]

	getStatusList(manager: EnumManager | undefined): Observable<any> {
		switch (manager) {
			case EnumManager.Approver:
				return of(this.statusApprovalsList)
			case EnumManager.Requester:
				return of(this.statusRequestsList)
			default:
				return throwError(() => new Error('App manager undefined'))
		}
	}
}
