import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import { enumNumberFormatTypes } from 'shared/prototypings'
import { LogoImageDefinition, LogosDefinition } from './logo.models'

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
	@Input() logos: LogosDefinition = {} as LogosDefinition
	@Input() link?: string | undefined = '/'

	public logo: LogoImageDefinition | undefined

	constructor(
		private router: Router,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private breakpointObserver: BreakpointObserver
	) {}

	ngOnInit(): void {
		this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
			if (state.matches) {
				this.switchLogo(this.logos?.small)
			} else {
				this.switchLogo(this.logos?.normal)
			}
		})
	}

	switchLogo(toLogo: LogoImageDefinition | undefined): void {
		if (toLogo) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'width', toLogo.width.toFormatPixel())
			this.renderer.setStyle(this.elementRef.nativeElement, 'height', toLogo.height.toFormatPixel())
			this.renderer.setStyle(
				this.elementRef.nativeElement,
				'minWidth',
				toLogo.minWidth.toFormat(enumNumberFormatTypes.px)
			)
			this.logo = toLogo
		}
	}
}
