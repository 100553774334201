import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { apiEndpointUrl } from 'core/http/environment-api'
import { ApprovalAssetModel } from 'core/models'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class CreateApprovalService {
	constructor(private http: HttpClient) {
		//
	}

	buildEndpointUrl(): string {
		return '{apiUrl}/{endpoint}'.format({
			apiUrl: apiEndpointUrl(),
			endpoint: ApiEndpoint.approval,
		})
	}

	createApproval(approval: ApprovalAssetModel): Observable<ApprovalAssetModel> {
		const url = this.buildEndpointUrl()
		return this.http.put<ApprovalAssetModel>(url, approval)
	}
}
