import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { AssetRatingComponent } from 'core/components/details-view/components/details-sidebar/asset-rating/asset-rating.component'
import { ThumbRatingModule } from 'shared/components/thumb-rating/thumb-rating.module'

@NgModule({
	declarations: [AssetRatingComponent],
	exports: [AssetRatingComponent],
	imports: [
		CommonModule,
		TranslateModule,
		MatIconModule,
		MatButtonModule,
		ThumbRatingModule,
		ReactiveFormsModule,
	],
})
export class AssetRatingModule {}
