import { ErrorHandler, Injectable } from '@angular/core'
import { DisplayErrorService } from 'core/services/error/display-error.service'

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
	constructor(private displayErrorService: DisplayErrorService) {}

	handleError(error: any) {
		this.displayErrorService.show(error)
	}
}
