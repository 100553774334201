import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpUrlEncodingCodec } from 'core/http'
import { apiEndpointUrl } from 'core/http/environment-api'
import { Activity, ActivityResponse, AssetActivities, RatingStepStatusResponse } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class RatingService {
	constructor(private http: HttpClient, private backendUrlService: BackendUrlService) {}

	getStepStatus(approvalId: string, stepId: string): Observable<RatingStepStatusResponse> {
		const endpointUrl = '{apiUrl}/{endpoint}'
			.format({
				apiUrl: apiEndpointUrl(),
				endpoint: ApiEndpoint.stepStatus,
			})
			.format({
				approvalId,
				stepId,
			})

		let params: HttpParams
		params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })

		let headers: HttpHeaders
		headers = new HttpHeaders()

		return this.http
			.get<RatingStepStatusResponse>(endpointUrl, {
				headers,
				params,
			})
			.pipe
			// Testing UI button finished disabled when call is pending
			// delay( 10000 )
			()
	}

	finishStep(
		approvalId: string,
		stepId: string,
		comment?: string | any
	): Observable<AssetActivities> {
		const endpointUrl = '{apiUrl}/{endpoint}'
			.format({
				apiUrl: apiEndpointUrl(),
				endpoint: ApiEndpoint.stepFinish,
			})
			.format({
				approvalId,
				stepId,
			})

		let params: HttpParams
		params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })

		const bodyParams = {
			comment,
		}

		let headers: HttpHeaders
		headers = new HttpHeaders()
		return this.http
			.post<AssetActivities>(endpointUrl, bodyParams, {
				params,
				headers,
			})
			.pipe
			// Testing UI button finished disabled when call is pending
			// delay( 10000 )
			()
	}

	submitActivity(
		approvalId: string,
		activity: Activity,
		isMock: boolean = false
	): Observable<ActivityResponse> {
		const endpointUrl = '{apiUrl}/{endpoint}'
			.format({
				apiUrl: apiEndpointUrl(isMock),
				endpoint: ApiEndpoint.activities,
			})
			.format({
				id: approvalId,
			})

		return this.http.put<ActivityResponse>(endpointUrl, activity)
	}

	clearActivity(
		approvalId: string,
		stepId: string,
		contentId: string,
		activityId: string
	): Observable<any> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.clearActivity, {
			approvalId,
			stepId,
			contentId,
			activityId,
		})

		return this.http.delete(url)
	}
}
