<div class='d-flex flex-column overflow-auto height-100p'>
  <section class='border-bottom border-primary'>
    <article class='d-flex flex-column'>
      <div data-qa='details-sidebar-asset-title' class='mat-h2'>{{asset?.name}}</div>
      <div class='flex-center'>
        <a data-qa='details-sidebar-link-openLibrary' [href]='asset?.detailsUrl' target='_blank' mat-button
           class='btn-rounded active'>
          <mat-icon class='pe-1' [inline]='true'>launch</mat-icon>
          <span data-qa='details-sidebar-label-openLibrary'>{{'detailsView.buttonOpenLibraryLabel' | translate}}</span>
        </a>
      </div>
    </article>
  </section>
  <section class='border-bottom border-primary'>
    <article>
      <div data-qa='details-sidebar-step-title'
           class='mat-h2 mb-1 pt-2'>{{'stepNavigation.approver.titleCurrentStep' | translate}}</div>
      <div class='mat-body-1'>
        <div>
          <div data-qa='details-sidebar-currentStep-name'>{{approvalAsset?.currentStep.step.name}}</div>
        </div>
        <div data-qa='details-sidebar-currentStep-description'
             class='mat-body-2'>{{approvalAsset?.currentStep.step.description}}</div>
      </div>
    </article>
  </section>
  <section class='border-bottom border-primary'>
    <article>
      <app-asset-rating [approval]='approvalAsset' [asset]='asset'
                        (activitiesChanged)='assetChanged.emit()'></app-asset-rating>
    </article>
  </section>
</div>
