import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ApprovalAssetExtendModel, AssetExtendModel, EnumManager } from 'core/models'

@Component({
	selector: 'app-details-sidebar[asset][approvalAsset][manager]',
	templateUrl: './details-sidebar.component.html',
	styleUrls: ['./details-sidebar.component.scss'],
})
export class DetailsSidebarComponent {
	@Input() asset!: AssetExtendModel
	@Input() approvalAsset!: ApprovalAssetExtendModel
	@Input() manager!: EnumManager

	@Output() assetChanged = new EventEmitter<void>()
}
