import { Injectable } from '@angular/core'
import {
	ApprovalAssetExtendModel,
	ApprovalAssetModel,
	AssetActivities,
	AssetComment,
	AssetExtendModel,
	AssetModel,
	CurrentStepModel,
	EnumFilterStateStatus,
} from 'core/models'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
/**
 * @description
 * shared page state service of Manage and Review Approval
 */
export class ApprovalInteractionService {
	private ratingSubject = new Subject<boolean>()
	ratingSubject$ = this.ratingSubject.asObservable()

	/**
	 * @description
	 * Trigger the state value when assets rated
	 * @emits ratingSubject$.next
	 */
	updateAllRated(currentRatedCount: number, totalOfRates: number) {
		if (currentRatedCount === totalOfRates) {
			this.ratingSubject.next(true)
		} else {
			this.ratingSubject.next(false)
		}
	}

	constructApproval(approvalAsset: ApprovalAssetModel): ApprovalAssetExtendModel {
		// set the currentStep
		Object.assign(approvalAsset, {
			currentStep: { step: approvalAsset.steps[approvalAsset.stepIndex] },
		} as ApprovalAssetExtendModel)

		this.constructRelatedApprovalUsers(approvalAsset as ApprovalAssetExtendModel)
		this.constructCurrentStep(
			(approvalAsset as ApprovalAssetExtendModel).currentStep,
			approvalAsset.activities
		)
		this.constructAssetActivities(approvalAsset.containedAssets, approvalAsset.activities)

		return approvalAsset as ApprovalAssetExtendModel
	}

	/**
	 * Collects all Users in the Approval
	 * From:
	 * Requester (Creator of the Approval),
	 * of all Steps,
	 * of all activities comments
	 * @param approvalAsset the approval
	 */
	constructRelatedApprovalUsers(approvalAsset: ApprovalAssetExtendModel): Array<string> {
		// get all users in approval and stored in the relatedUsers object
		let users: Array<string> = []
		users.push(approvalAsset.requester)
		approvalAsset.steps.forEach((step) => {
			users = users.concat(step.approvers as Array<string>)
		})
		approvalAsset.activities?.forEach((activity) => {
			activity.comments?.forEach((comments) => {
				users = users.concat(comments.user)
			})
		})

		users = [...new Set(users)] // remove duplicates
		approvalAsset.relatedUsers = users

		return users
	}

	constructCurrentStep(currentStep: CurrentStepModel, activities: Array<AssetActivities>) {
		const activitiesFiltered = activities?.filter((activity) => !activity.contentId) || []

		currentStep.activities = activitiesFiltered
		activitiesFiltered.forEach((activity) => {
			currentStep.newestComment = this.getLastComment(activity)
		})
	}

	constructAssetActivities(containedAssets: Array<AssetModel>, activities: Array<AssetActivities>) {
		containedAssets
			.map((asset) => asset as AssetExtendModel)
			.forEach((asset) => {
				const assetActivities =
					activities?.filter((activity) => asset.id === activity.contentId) || []

				this.fillAssetExtendedData(asset, assetActivities)
			})
	}

	fillAssetExtendedData(asset: AssetExtendModel, assetActivities: Array<AssetActivities>) {
		asset.activities = assetActivities
		if (assetActivities.length > 0) {
			asset.newestActivity = assetActivities[assetActivities.length - 1]
			asset.newestComment = this.getLastComment(asset.newestActivity)
			asset.hasRating =
				asset.newestActivity.rating !== undefined &&
				asset.newestActivity.rating !== EnumFilterStateStatus.RESET
		} else {
			asset.hasRating = false
			asset.newestComment = undefined
			asset.newestActivity = undefined
		}
	}

	getLastComment(activity: AssetActivities): AssetComment | undefined {
		return activity.comments?.reduce((m, v, i) => (v.date > m.date && i ? v : m))
	}
}
