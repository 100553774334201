import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AssetStatus, StatusItem, StatusList } from 'core/models/consents'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-button-group-rating[assetStatus][statusList][clickStatus]',
	templateUrl: './button-group-rating.component.html',
	styleUrls: ['./button-group-rating.component.scss'],
})
export class ButtonGroupRatingComponent {
	@Input() statusList!: StatusList
	@Input() assetStatus!: AssetStatus
	@Input() loading$!: Subject<boolean>
	@Output() clickStatus = new EventEmitter<StatusItem | undefined>()

	getActive(button: StatusItem) {
		return this.assetStatus.find((status) => status === button.id)
	}

	onClickStatus(statusItem: StatusItem) {
		if (this.getActive(statusItem)) {
			// remove the status
			this.clickStatus.emit()
		} else {
			this.clickStatus.emit(statusItem)
		}
	}
}
