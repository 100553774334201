import { Pipe, PipeTransform } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { APP_DATE_FORMATS } from 'core/models'
import * as moment from 'moment-timezone'
import { BehaviorSubject } from 'rxjs'

@Pipe({
	name: 'dynamicMoment',
})
export class DynamicMomentPipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	/**
	 * Make moment dynamic on language switch
	 * if value not defined it returns the current formated Date()
	 */
	transform(value: moment.MomentInput, format?: string): any {
		// make the moment format configurable
		format = format ? format : APP_DATE_FORMATS.display.dateInput
		// get the initial value
		const initVal = moment(value).locale(moment.locale()).format(format)
		// insert the value into a new behaviour subject. If the language changes, the behaviour subject will be
		// updated
		const momentObs = new BehaviorSubject<string>(initVal)
		this.translate.onLangChange.subscribe(($event: LangChangeEvent) => {
			// format the new date according to the new locale
			const val = moment(value).locale($event.lang).format(format)
			momentObs.next(val)
		})
		return momentObs // needs to be piped into the async pipe
	}
}
