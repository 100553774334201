<h2>Combine Designs: Material & Bootstrap & Canto </h2>

<section>
  <h3>Input fields Angular</h3>
  <mat-form-field class='flex-fill-100' appearance='outline'>
    <mat-label>Email address</mat-label>
    <input type='text' matInput placeholder='Enter email'>
    <mat-hint>We'll never share your email with anyone else.</mat-hint>
  </mat-form-field>

  <mat-form-field class='flex-fill-100 mat-form-field-small' appearance='outline'>
    <mat-label>Email address</mat-label>
    <input type='text' matInput placeholder='Enter email'>
    <mat-hint>We'll never share your email with anyone else.</mat-hint>
  </mat-form-field>

  <h3>Input fields Bootstrap</h3>
  <div class='form-group'>
    <label>Email address</label>
    <input type='text' class='form-control' placeholder='Enter email'>
    <small class='form-text text-muted'>We'll never share your email with anyone else.</small>
  </div>
</section>
<section>
  <h3>Buttons Angular</h3>
  <button type='button' mat-button mat-raised-button mat-ripple color='primary'>Primary</button>
  <button type='button' mat-button mat-raised-button class='mat-accent'>Accent</button>
  <button type='button' mat-button mat-raised-button color='warn' class=''>Warn</button>
  <button type='button' mat-button mat-raised-button color='primary' class='btn-rounded'>Primary Rounded</button>
  <button type='button' mat-button mat-raised-button class='mat-accent btn-rounded'>Accent</button>
  <button type='button' mat-button mat-raised-button color='warn' class='btn-rounded'>Warn</button>
</section>

<section>
  <h3>Buttons tags Angular</h3>
  <a mat-button mat-raised-button class='shadow-none mat-ripple' color='primary' href='javascript://' role='button'>Link</a>
  <input type='Submit' class='mat-button mat-raised-button mat-primary mat-ripple shadow-none' value='Input'>
  <h3>Buttons tags Bootstrap</h3>
  <a class='btn btn-primary' href='javascript://' role='button'>Link</a>
  <button class='btn btn-primary' type='submit'>Button</button>
  <input class='btn btn-primary' type='button' value='Input'>
  <input class='btn btn-primary' type='submit' value='Submit'>
  <input class='btn btn-primary' type='reset' value='Reset'>
</section>

<section>
  <h3>Buttons outline Angular</h3>
  <button type='button' mat-button mat-stroked-button class=''>Primary 1</button>
  <button type='button' mat-button mat-stroked-button color='accent' class=''>Accent 1</button>
  <h3>Buttons outline Bootstrap</h3>
  <button type='button' class='btn btn-outline-danger'>Sample 3</button>
  <button type='button' class='btn btn-outline-primary'>Sample 4</button>
  <button type='button' class='btn btn-outline-secondary'>Sample 5</button>
  <button type='button' class='btn btn-outline-success'>Sample 6</button>
</section>

<section>
  <h3>Buttons group Angular</h3>
  <div class='btn-group' color='primary' role='group'>
    <button type='button' mat-button class='shadow-none'>Button 1</button>
    <button type='button' disabled mat-button class='shadow-none'>Button 2</button>
    <button type='button' mat-button class='active shadow-none'>Button 3</button>
    <button type='button' mat-button class='shadow-none'>Button 4</button>
  </div>
  <h3>Buttons group Bootstrap</h3>
  <div class='btn-group' role='group'>
    <button type='button' mat-button class='btn btn-primary shadow-none'>Button 1</button>
    <button type='button' mat-button class='btn btn-primary active disabled shadow-none'>Button 2</button>
    <button type='button' mat-button class='btn btn-primary active shadow-none'>Button 3</button>
    <button type='button' mat-button class='btn btn-primary shadow-none'>Button 4</button>
  </div>
</section>

<section>
  <h3>Buttons group toggle Angular</h3>
  <div class='btn-group btn-rounded' color='primary' role='group'>
    <button type='button' [ngClass]="{'active': btnGroupToggleStateIndex===0}" (click)='btnGroupToggleStateIndex=0' mat-button class='shadow-none'>Button 1</button>
    <button type='button' [ngClass]="{'active': btnGroupToggleStateIndex===1}" (click)='btnGroupToggleStateIndex=1' mat-button class='shadow-none'>Button 2</button>
    <button type='button' [ngClass]="{'active': btnGroupToggleStateIndex===3}" (click)='btnGroupToggleStateIndex=3' mat-button class='shadow-none'>Button 2</button>
  </div>
</section>

