<section>
  <table class='col-12'>
    <thead>
    <tr>
      <th class='mat-body-1' colspan='2'>{{'createNewApproval.form.areaApprovalSteps.labelStepName' | translate}}</th>
      <th class='mat-body-1 size-date'>{{'createNewApproval.form.areaApprovalSteps.labelDueDate' | translate}}</th>
      <th class='mat-body-1'>{{'createNewApproval.form.areaApprovalSteps.labelApprover' | translate}}</th>
      <th
        class='mat-body-1 table-cell-width-auto'>{{'createNewApproval.form.areaApprovalSteps.labelActions' | translate}}</th>
    </tr>
    </thead>
    <tr>
      <td colspan='5'>
        <mat-divider></mat-divider>
      </td>
    </tr>
    <tbody>
    <tr data-qa='approval-step-entry'
        *ngFor='let step of steps; let stepIndex = index; let last = last; let first = first'>
      <td class='table-cell-width-auto text-end'>
        {{stepIndex + 1}}:
      </td>
      <td>
        {{step.name}}
      </td>
      <td nowrap class='size-date'>
        <span *ngIf='step.dueDate'>
          {{step.dueDate | dynamicMoment | async}}
        </span>
      </td>
      <td>
        <ng-container *ngFor='let approver of step.approvers; let last = last'>
          <span>{{approver.firstName}} {{approver.lastName}}<span *ngIf='!last'>, </span></span>
        </ng-container>
      </td>
      <td class='can-actions' nowrap>
        <button data-qa='new-approval-step-list-edit-button' mat-icon-button (click)='edit(stepIndex, step)'>
          <span class='material-icons'>edit</span>
        </button>
        <button data-qa='new-approval-step-list-delete-button' mat-icon-button (click)='delete(stepIndex)'>
          <span class='material-icons'>delete</span>
        </button>
        <button data-qa='new-approval-step-list-down-button' [ngClass]="{'hide':last}" mat-icon-button
                (click)='move(stepIndex, 1)'>
          <span class='material-icons'>arrow_downward</span>
        </button>
        <button data-qa='new-approval-step-list-up-button' [ngClass]="{'hide':first}" mat-icon-button
                (click)='move(stepIndex,-1)'>
          <span class='material-icons'>arrow_upward</span>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</section>
