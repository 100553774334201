import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'

export interface ConfirmDialogDataModel {
	title: string
	contentText: string
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
	@Output() ok = new EventEmitter<boolean>()
	@Input() loadingIndicator$!: Subject<boolean>
	@Input() hideClose = false

	constructor(
		public componentDialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogDataModel
	) {
		componentDialogRef.addPanelClass('confirm-dialog')
	}

	onOk() {
		this.ok.emit(true)
	}
}
