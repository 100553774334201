import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { ModifyApprovalStepsComponent } from 'core/components/create-new-approval-view/components/modify-approval-steps/modify-approval-steps.component'
import { ModifyApprovalUserModule } from 'core/components/create-new-approval-view/components/modify-approval-steps/modify-approval-user/modify-approval-user.module'
import { DynamicMomentPipeModule } from 'shared/pipes/dynamic-moment-pipe.module'

@NgModule({
	declarations: [ModifyApprovalStepsComponent],
	imports: [
		CommonModule,
		ModifyApprovalUserModule,
		FormsModule,
		MatDialogModule,
		MatIconModule,
		MatFormFieldModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		TranslateModule,
		MatDividerModule,
		MatButtonModule,
		MatInputModule,
		DynamicMomentPipeModule,
	],
	exports: [ModifyApprovalStepsComponent],
})
export class ModifyApprovalStepsModule {}
