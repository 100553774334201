import { NgModule } from '@angular/core'
import { LocalizedStringPipe, LocalizedHtmlStringPipe } from 'core/pipes/localized-string'

@NgModule({
	declarations: [LocalizedStringPipe, LocalizedHtmlStringPipe],
	imports: [],
	exports: [LocalizedStringPipe, LocalizedHtmlStringPipe],
	providers: [LocalizedStringPipe, LocalizedHtmlStringPipe],
})
export class LocalizedStringPipeModule {}
