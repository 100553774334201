export enum ApiHttpErrorCode {
	APP_TOKEN_EXPIRED = 3002,
	WORKFLOW_NOT_FOUND = 3003,
	UNSPECIFIED_ERROR = 3999,
}

export enum AppUiErrorCode {
	NO_TEMPLATE_SELECTED = 'ui-9000',
	CONTENT_ID_NOT_FOUND_IN_CONSENT_WORKFLOW = 'ui-9001',
}

export type ApiHttpErrorResponse = {
	code: number
	message: string
}
