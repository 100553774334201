import { Component } from '@angular/core'

@Component({
	selector: 'app-component-bootstrap',
	templateUrl: './component-bootstrap.component.html',
	styleUrls: ['./component-bootstrap.component.scss'],
})
export class ComponentBootstrapComponent {
	btnGroupToggleStateIndex = 0
}
