import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { indicate } from 'core/http/rxjs-operators'
import { ApprovalModel, EnumFilterStateStatus, EnumManager } from 'core/models'
import { ApprovalService, HeaderStateService } from 'core/services'
import { Subject } from 'rxjs'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'

@Component({
	selector: 'app-approval-requests-view[status][manager]',
	templateUrl: './approval-requests-view.component.html',
	styleUrls: ['./approval-requests-view.component.scss'],
})
export class ApprovalRequestsViewComponent
	extends ApprovalBaseComponent
	implements OnInit, OnChanges, OnDestroy
{
	@Input() status!: EnumFilterStateStatus
	@Input() manager!: EnumManager

	loading$ = new Subject<boolean>()
	unscribeSearch$: any

	createNewApproval$ = new Subject<void>()

	approvalList: ApprovalModel = {} as ApprovalModel

	constructor(
		private approvalService: ApprovalService,
		private headerStateService: HeaderStateService
	) {
		super()
	}

	ngOnInit(): void {
		this.headerStateService.setTitleTranslation('app.title')
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.status) {
			setTimeout(() => {
				this.search()
			})
		}
	}

	doSearchAgain() {
		this.search()
	}

	search() {
		this.unscribeSearch$?.unsubscribe()
		this.approvalList = {} as ApprovalModel

		this.unscribeSearch$ = this.approvalService
			.search(this.manager, this.status)
			.pipe(indicate(this.loading$))
			.subscribe({
				next: (result: ApprovalModel) => {
					this.approvalList = result
				},
			})
	}

	ngOnDestroy(): void {
		this.unscribeSearch$?.unsubscribe()
		this.createNewApproval$.unsubscribe()
	}

	clickNewApproval(): void {
		this.createNewApproval$.next()
	}
}
