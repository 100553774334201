<div mat-dialog-title class='d-flex flex-row'>
  <h3 class='flex-1'>
    <span *ngIf='data.index === undefined'>{{'modifyApprovalStep.titleHeader.isNew' | translate}}</span>
    <span *ngIf='data.index !== undefined'>{{'modifyApprovalStep.titleHeader.isEdit' | translate}}</span>
  </h3>
  <div class='col-2 d-flex justify-content-end'>
    <button mat-mini-fab
            [mat-dialog-close]
            color='primary'
            class='shadow-none'>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content [formGroup]='formGroupState'
                    #formDirective='ngForm'>
  <section class='pb-3'>
    <table class='col-12' style='table-layout: fixed'>
      <tbody>
      <tr>
        <td class=''>
          <article>
            <div class='mat-body-1'>
              {{'modifyApprovalStep.labelStepName' | translate}}
            </div>
            <div>
              <mat-form-field class='d-block mat-form-field-small field-rounded'
                              appearance='outline'>
                <input matInput data-qa='new-approval-step-name-input' autocomplete='off' required
                       [formControlName]='formFields.stepName'>
              </mat-form-field>
            </div>
          </article>
        </td>
        <td class='col-1'></td>
        <td class='col-3'>
          <article>
            <div class='mat-body-1'>
              {{'modifyApprovalStep.labelDueDate' | translate}}
            </div>
            <div>
              <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                <input data-qa='new-approval-step-due-date-input' matInput #dueDateInput [matDatepicker]='dueDatePicker'
                       [min]='minDueDate'
                       readonly
                       (focus)='dueDatePicker.open()' (click)='dueDatePicker.open()'
                       [formControlName]='formFields.dueDate'>
                <mat-icon matSuffix class='cursor-pointer'
                          [ngClass]='{hide: !dueDateInput.value}'
                          (click)='f[formFields.dueDate].setValue(undefined); $event.stopPropagation()'>clear
                </mat-icon>
                <mat-datepicker #dueDatePicker color='primary'
                                (closed)='dueDateInput.blur()'></mat-datepicker>
              </mat-form-field>
            </div>
          </article>
        </td>
      </tr>
      <tr>
        <td colspan='3'>
          <article>
            <div class='mat-body-1'>
              {{'modifyApprovalStep.labelDescription' | translate}}
            </div>
            <div>
              <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                  <textarea data-qa='new-approval-step-description-input' matInput rows='5' style='resize: none'
                            [formControlName]='formFields.description'
                            [textContent]=''></textarea>
              </mat-form-field>
            </div>
          </article>
        </td>
      </tr>
      </tbody>
    </table>
  </section>

  <section class='pb-3'>
    <section class='d-flex flex-row pb-3'>
      <div class='mat-body-1 flex-1'>
        {{'modifyApprovalStep.labelApprover' | translate}}
      </div>
      <div>
        <button data-qa='new-approval-step-add-approver-button' mat-button (click)='openAddApproverDialog()'
                color='primary'
                class='m-1 active btn-rounded'>{{'modifyApprovalStep.buttonLabelAddApprover' | translate}}</button>
      </div>
    </section>
    <section class='pb-5'>
      <table class='col-12'>
        <thead>
        <tr>
          <th class='mat-body-1' style='width: 30%'>{{'modifyApprovalStep.labelFirstName' | translate}}</th>
          <th class='mat-body-1'>{{'modifyApprovalStep.labelLastName' | translate}}</th>
          <th class='mat-body-1' style='width: 30px'></th>
        </tr>
        </thead>
        <tr>
          <td colspan='3'>
            <mat-divider></mat-divider>
          </td>
        </tr>
        <tbody>
        <tr *ngFor='let approver of data.step.approvers; let i = index'>
          <td>{{approver.firstName}}</td>
          <td>{{approver.lastName}}</td>
          <td class='can-actions'>
            <button mat-icon-button (click)='removeApprover(i)'>
              <span class='material-icons'>delete</span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </section>
  </section>
</mat-dialog-content>

<mat-dialog-actions class='justify-content-end'>
  <button mat-button data-qa='new-approval-step-cancel-button' [mat-dialog-close]
          class='active btn-rounded'>{{'modifyApprovalStep.buttonLabelCancel' | translate}}</button>
  <button mat-button data-qa='new-approval-step-ok-button' (click)='save()' [disabled]='formDirective.invalid'
          class='active btn-rounded'>{{'modifyApprovalStep.buttonLabelSave' | translate}}</button>
</mat-dialog-actions>
