import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as moment from 'moment-timezone'

moment.locale(window.navigator.language)
//console.log('locale: %s - %s', moment.locale(), moment.tz.guess());

if (environment.production) {
	enableProdMode()
}

const plattform = platformBrowserDynamic()

setTimeout(() => {
	plattform
		.bootstrapModule(AppModule)
		.then((ref) => {})
		.catch((err) => console.error(err))
}, 100)
