<ng-template #DueDateDisplay let-dueDate='dueDate'>
  <ng-container *ngIf='dueDate'>
    <span>{{dueDate | dynamicMoment | async}}</span>
  </ng-container>
  <ng-container *ngIf='dueDate === undefined'>
    <span>{{'shared.labelDueDateEmpty' | translate}}</span>
  </ng-container>
</ng-template>

<div tabindex='0'
     class='mat-card border cursor-pointer outline-0 border-primary d-flex flex-column justify-content-between pt-2 pb-2'
     (keydown.enter)='openApproval(approvalAsset)'
     (click)='openApproval(approvalAsset)'>
  <div class='can-card-metadata flex-column height-100p'>
    <div data-qa='approval-name'><span class='text-center'>{{approvalAsset.name}}</span></div>
  </div>
  <div class='can-keyphoto d-flex flex-center'>
    <div class='d-flex flex-center'>
      <img #thumbnail [src]='thumbnailUrl' (load)='checkThumbnailOrientation()' [ngClass]='thumbnailOrientation'>
    </div>
  </div>

  <div class='can-card-metadata flex-column' *ngIf='approvalAsset'>
    <div>
      <mat-icon class='pe-1' inline='inline'>content_copy</mat-icon>
      <span>{{'approvalAsset.labelAssetsInApproval' | translate: { count: approvalAsset.numberOfAssets } }}</span></div>
    <div>
      <mat-icon class='pe-1' inline='inline'>calendar_today</mat-icon>
      <ng-container
        *ngTemplateOutlet='DueDateDisplay; context: {dueDate: manager === ManagerEnum.Approver ? approvalAsset.stepDueDate : approvalAsset.dueDate}'></ng-container>
    </div>
    <div>
      <app-approval-progress [approvalAsset]='approvalAsset' [manager]='manager'></app-approval-progress>
    </div>
    <div>
      <ng-container>
        <button mat-button tabindex='-1'
                color='primary'
                class='active btn-rounded shadow-none'>
          <span *ngIf='manager === ManagerEnum.Requester'>{{'approvalAsset.buttonEditRequester' | translate}}</span>
          <span *ngIf='manager === ManagerEnum.Approver'>{{'approvalAsset.buttonEditApprover' | translate}}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
