import { Component, Input } from '@angular/core'
import { ApprovalAssetModel, EnumManager } from 'core/models'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'

@Component({
	selector: 'app-approval-progress[approvalAsset][manager]',
	templateUrl: './approval-progress.component.html',
	styleUrls: ['./approval-progress.component.scss'],
})
export class ApprovalProgressComponent extends ApprovalBaseComponent {
	@Input() approvalAsset!: ApprovalAssetModel
	@Input() manager!: EnumManager

	constructor() {
		super()
	}
}
