import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpUrlEncodingCodec } from 'core/http'
import { ApprovalModel } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ResetService {
	constructor(private backendUrlService: BackendUrlService, private httpClient: HttpClient) {}

	resetStep(approvalId: string, stepId: string): Observable<any> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.reset, {
			approvalId,
		})

		let params: HttpParams
		params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
		params = params.set('stepId', stepId)
		return this.httpClient.post<ApprovalModel>(url, null, { params })
	}
}
