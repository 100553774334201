import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { AuthService } from 'core/services/auth.service'

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private window: Window
	) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (this.authService.isLoggedIn) {
			return true
		}

		const isJwtAuth = this.authService.checkJWT(route.queryParams)
		if (isJwtAuth) {
			const queryParams = { ...route.queryParams }
			Object.assign(queryParams, {
				redirect_uri: null,
				jwt: null,
			})
			// change the browser location without query params jwt
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([this.window.location.pathname], {
				queryParams: { ...queryParams },
				queryParamsHandling: 'merge',
			})
		}
		return isJwtAuth
	}
}
