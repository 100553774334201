import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ApprovalRequestOverviewRoutingModule } from 'core/components/approval-request-overview'
import { ConsentDetailsOverviewRoutingModule } from 'core/components/consent/consent-details-overview/consent-details-overview-routing.module'
import { ConsentListOverviewRoutingModule } from 'core/components/consent/consent-list-overview/consent-list-overview-routing.module'
import { CreateNewApprovalViewRoutingModule } from 'core/components/create-new-approval-view/create-new-approval-view-routing.module'
import { DetailsViewRoutingModule } from 'core/components/details-view/details-view-routing.module'
import { ManageReviewApprovalViewRoutingModule } from 'core/components/manage-review-approval-view'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { RedirectRoleGuard } from 'core/services/redirect-role.guard'
import { ErrorComponent } from 'shared/components/error/error.component'

export const routes: Routes = [
	{ path: 'error', component: ErrorComponent },
	{ path: '', pathMatch: 'full', children: [], canActivate: [AuthGuardService, RedirectRoleGuard] },
	{ path: '**', redirectTo: '' },
]

@NgModule({
	imports: [
		DetailsViewRoutingModule,
		ApprovalRequestOverviewRoutingModule,
		CreateNewApprovalViewRoutingModule,
		ManageReviewApprovalViewRoutingModule,
		ConsentListOverviewRoutingModule,
		ConsentDetailsOverviewRoutingModule,
		// ComponentBootstrapRoutingModule,
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
