import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { indicate } from 'core/http'
import { ActivityResponse, AssetExtendModel, CurrentStepModel } from 'core/models'
import { ApprovalInteractionService } from 'core/services'
import { ActivityService } from 'core/services/activity.service'
import { Subject } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { ApprovalBaseComponent } from 'shared/components'
import { deepClone } from 'shared/prototypings'

export interface CommentDialogDataModel {
	approvalId: string
	currentStep: CurrentStepModel
	containedAssets: Array<AssetExtendModel>
}

@Component({
	selector: 'app-comment-view-dialog',
	templateUrl: './comment-view-dialog.component.html',
	styleUrls: ['./comment-view-dialog.component.scss'],
})
export class CommentViewDialogComponent
	extends ApprovalBaseComponent
	implements OnInit, AfterContentInit, OnDestroy
{
	loading$ = new Subject<boolean>()
	loadSubscription!: Subscription

	assets = [] as Array<AssetExtendModel>
	step = {} as CurrentStepModel

	constructor(
		private approvalInteractionService: ApprovalInteractionService,
		private activityService: ActivityService,
		private changeDetectorRef: ChangeDetectorRef,
		public componentDialogRef: MatDialogRef<CommentViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: CommentDialogDataModel
	) {
		super()
	}

	ngOnInit(): void {
		this.changeDetectorRef.detectChanges()
	}

	ngAfterContentInit(): void {
		if (this.data.approvalId && this.data.currentStep.step) {
			this.loadSubscription = this.activityService
				.getActivities(this.data.approvalId, this.data.currentStep.step.id)
				.pipe(indicate(this.loading$))
				.subscribe((value: ActivityResponse) => {
					// do a deep copy, we don't wanted to change the referenced objects
					this.assets = deepClone(this.data.containedAssets)
					this.step = deepClone(this.data.currentStep)

					this.approvalInteractionService.constructAssetActivities(this.assets, value.activities)
					this.approvalInteractionService.constructCurrentStep(this.step, value.activities)
				})
		}
	}

	ngOnDestroy(): void {
		if (this.loadSubscription) {
			this.loadSubscription.unsubscribe()
		}
	}

	closeView() {
		this.componentDialogRef.close()
	}
}
