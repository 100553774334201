import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { NotificationComponent } from 'shared/components/notification/notification.component'
import {
	NotificationConfig,
	NotificationData,
	NotificationMessage,
	NOTIFICATIONTYPE,
} from '../models/notification'

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	/** to handle action events you can use in a component
	 * snackBarRef.onAction().subscribe(() => console.log('notification action clicked'));
	 * snackBarRef.afterDismissed().subscribe(() => console.log('notification closed'));
	 */

	snackBarRef!: MatSnackBarRef<any>
	defaultConfig: MatSnackBarConfig = {}

	constructor(
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private notificationConfig: NotificationConfig
	) {
		this.defaultConfig = {
			panelClass: ['notification'],
			horizontalPosition: 'right',
			verticalPosition: 'top',
			duration: this.notificationConfig.hideTimeout,
		}
	}

	showMessage(message: string): MatSnackBarRef<NotificationComponent> {
		return this.show({ message })
	}

	showError(message: NotificationMessage): MatSnackBarRef<NotificationComponent> {
		if (message.code) {
			// if translation key errors.{code}
			message.message = this.translate.instant('errors.' + message.code, {
				default: message.message,
			})
		}
		return this.show(message, NOTIFICATIONTYPE.ERROR)
	}

	show(
		message: NotificationMessage,
		type: NOTIFICATIONTYPE = NOTIFICATIONTYPE.DEFAULT,
		customConfig?: MatSnackBarConfig
	): MatSnackBarRef<NotificationComponent> {
		if (message.message) {
			message.message = this.translate.instant(message.message, { default: message.message })
		}
		this.snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
			...this.defaultConfig,
			...customConfig,
			panelClass: (this.defaultConfig.panelClass as string[]).concat(type),
			data: {
				type,
				message,
			} as NotificationData,
		})
		return this.snackBarRef
	}

	close() {
		this.snackBarRef.dismissWithAction()
	}
}
