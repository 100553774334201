import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ApproveRejectAssetComponent } from 'core/components/manage-review-approval-view/components/approve-reject-asset/approve-reject-asset.component'

@NgModule({
	declarations: [ApproveRejectAssetComponent],
	imports: [CommonModule, MatIconModule],
	exports: [ApproveRejectAssetComponent],
})
export class ApproveRejectAssetModule {}
