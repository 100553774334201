export class NotificationConfig {
	hideTimeout!: number
}

export enum NOTIFICATIONTYPE {
	DEFAULT = 'default',
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
}

export interface NotificationMessage {
	code?: number | string
	message?: string
}

export interface NotificationData {
	type: NOTIFICATIONTYPE
	message: NotificationMessage
}
