import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import { ApprovalAssetModel } from 'core/models/approvals'
import { ImageOrientation, ImageUtilsService } from 'core/services/image-utils.service'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'

const DEFAULT_THUMBNAILSIZE = 200

@Component({
	selector: 'app-approval-asset[approvalAsset][manager]',
	templateUrl: './approval-asset.component.html',
	styleUrls: ['./approval-asset.component.scss'],
})
export class ApprovalAssetComponent extends ApprovalBaseComponent implements OnInit {
	@Input() approvalAsset!: ApprovalAssetModel
	@Input() manager!: EnumManager
	@ViewChild('thumbnail') thumbnail!: ElementRef

	thumbnailUrl = ''
	thumbnailOrientation: ImageOrientation = ImageOrientation.PORTRAIT

	constructor(private router: Router, private imageUtilsService: ImageUtilsService) {
		super()
	}

	ngOnInit(): void {
		this.thumbnailUrl = this.imageUtilsService.computeImage(
			this.approvalAsset.thumbnailUrl,
			DEFAULT_THUMBNAILSIZE
		)
	}

	checkThumbnailOrientation() {
		this.thumbnailOrientation = this.imageUtilsService.getImageOrientation(this.thumbnail)
	}

	openApproval(approval: ApprovalAssetModel) {
		this.router.navigate([EnumAppEndpoint.ManageApproval, this.manager, approval.id])
	}
}
