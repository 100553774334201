import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserExtendModel } from 'core/models'

@Component({
	selector: 'app-user[user]',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
})
export class UserComponent {
	@Input() user!: UserExtendModel
	@Input() index!: number
	@Output() removeClick = new EventEmitter<{ user: UserExtendModel; index: number }>()
	@Output() addClick = new EventEmitter<{ user: UserExtendModel }>()

	clickRemove(user: UserExtendModel) {
		this.removeClick.emit({ user, index: this.index })
	}

	clickAdd(user: UserExtendModel) {
		this.addClick.emit({ user })
	}
}
