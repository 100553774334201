import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ApprovalProgressComponent } from './approval-progress.component'

@NgModule({
	declarations: [ApprovalProgressComponent],
	imports: [CommonModule, TranslateModule, MatIconModule],
	exports: [ApprovalProgressComponent],
})
export class ApprovalProgressModule {}
