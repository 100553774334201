<div class='d-flex flex-column overflow-hidden-100p'>
  <ng-container>
    <header class='header-sub-view'>
      <section class='d-flex flex-row ms-2 p-2 ps-0'>
        <div class='m-0 d-flex flex-fill justify-content-center align-items-center mat-h3'>
          <div class='element-position-absolute-center'>
            <span data-qa='requester-approval-count-label'>{{'stepNavigation.labelRatedAssets' | translate: {
              from: (countOfRatedItems$ | async),
              to: approvalAsset?.numberOfAssets
            } }}</span>
          </div>
        </div>
        <div class='d-flex justify-content-end'>
          <button mat-mini-fab
                  (click)='closeView()'
                  color='primary'
                  class='shadow-none'
                  data-qa='approval-view-close-button'>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </section>
      <mat-divider class='pb-2'></mat-divider>
    </header>
  </ng-container>

  <ng-container>
    <div class='overflow-hidden-100p'>
      <section data-qa='asset-grid-section' class='height-100p overflow-auto d-flex flex-row flex-wrap align-content-start' *ngIf='!!approvalAsset?.containedAssets'>
        <ng-container *ngFor='let asset of containedAssets;let i = index;'>
          <app-approve-reject-asset (loaded)='onAssetLoaded()' [manager]='manager' [approval]='approvalAsset' [asset]='asset'></app-approve-reject-asset>
        </ng-container>
      </section>
    </div>
  </ng-container>
</div>
