import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ApprovalAssetModule } from 'core/components/approval-request-overview/components/approval-asset/approval-asset.module'
import { LoadingSpinnerModule } from 'shared/components/loading-spinner'
import { ApprovalRequestsViewComponent } from './approval-requests-view.component'

@NgModule({
	declarations: [ApprovalRequestsViewComponent],
	imports: [
		CommonModule,
		ApprovalAssetModule,
		MatButtonModule,
		MatDividerModule,
		LoadingSpinnerModule,
		TranslateModule,
		MatIconModule,
	],
	exports: [ApprovalRequestsViewComponent],
})
export class ApprovalRequestsViewModule {}
