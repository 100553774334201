import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ApproveRejectAssetModule } from 'core/components/manage-review-approval-view/components/approve-reject-asset'
import { LoadingSpinnerModule } from 'shared/components/loading-spinner'
import { AssetRequestsViewComponent } from './asset-requests-view.component'

@NgModule({
	declarations: [AssetRequestsViewComponent],
	imports: [
		CommonModule,
		ApproveRejectAssetModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		TranslateModule,
		LoadingSpinnerModule,
	],
	exports: [AssetRequestsViewComponent],
})
export class AssetRequestsViewModule {}
