import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CreateNewApprovalViewComponent } from 'core/components/create-new-approval-view/create-new-approval-view.component'
import { EnumAppEndpoint } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { RouteSchemeData } from 'core/models/routeScheme'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { CanDeactivateGuard } from 'core/services/can-deactivate.guard'
import { UserRoleGuard } from 'core/services/user-role.guard'

export const routes: Routes = [
	{
		path: EnumAppEndpoint.NewApproval,
		component: CreateNewApprovalViewComponent,
		data: { roles: [APP_PERMISSION.request] } as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
		canDeactivate: [CanDeactivateGuard],
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class CreateNewApprovalViewRoutingModule {}
