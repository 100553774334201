import { EnumAssetScheme, EnumFilterStateStatus } from 'core/models/index'

export interface ApprovalModel {
	continuationToken: string
	results: Array<ApprovalAssetModel>
}

export interface ApprovalAssetModel {
	id: string
	name: string
	requester: string
	dateCreated: Date
	status: EnumFilterStateStatus
	numberOfAssets: number
	totalSteps: number
	stepIndex: number
	stepId: string
	stepDueDate: Date
	dueDate: Date
	thumbnailUrl: string
	// detailed approval
	containedAssets: Array<AssetModel>
	steps: Array<StepModel>
	activities: Array<AssetActivities>
	dateClosed: Date
	collection: CollectionModel
}

export interface CollectionModel {
	id: string
	url: string
	name: string
}

export interface ApprovalAssetExtendModel extends ApprovalAssetModel {
	currentStep: CurrentStepModel
	isApprovalEnds: boolean
	relatedUsers: Array<string>
}

export interface CollectionModel {
	id: string
	url: string
	name: string
}

export interface CurrentStepModel {
	step: StepModel
	rating: EnumFilterStateStatus
	activities: Array<AssetActivities>
	newestComment?: AssetComment
}

export interface StepModel {
	id: string
	name: string
	description: string
	approvers: Array<UserModel | string>
	dueDate: Date
	dateFinished: Date
}

export interface UserModel {
	userId: string
	firstName: string
	lastName: string
	tenant: string
	permissions: Array<string>
}

export interface CollectionModel {
	id: string
	name: string
}

export interface UserExtendModel extends UserModel {
	isAssigned: boolean
	index: number
}

export interface UsersInformationResponse {
	users: Array<UserModel>
}

export interface AssetModel {
	id: string
	name: string
	scheme: EnumAssetScheme
	imageUrl: string
	detailsUrl: string
}

export interface AssetExtendModel extends AssetModel {
	hasRating: boolean
	newestActivity?: AssetActivities
	newestComment?: AssetComment
	activities: Array<AssetActivities>
}

export interface AssetActivities {
	id: string
	stepId: string
	contentId: string
	user: string
	date: Date
	rating?: EnumFilterStateStatus
	comments?: Array<AssetComment>
	stepStatus: EnumFilterStateStatus
}

export interface AssetComment {
	activityId: string
	user: string
	comment: string
	date: Date
}

export interface RatingStepStatusResponse {
	status: EnumFilterStateStatus
	stepId: string
	counts: {
		approved: number
		rejected: number
		total: number
		unrated: number
	}
}

export interface Activity {
	id: string
	stepId: string
	contentId: string
	rating?: EnumFilterStateStatus
	comment?: string
}

export interface ActivityResponse extends Activity {
	user: string
	date: Date
	activities: Array<AssetActivities>
}

export interface ApprovalStatusResponse {
	status: EnumFilterStateStatus
}
