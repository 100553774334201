import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class SendReminderService {
	constructor(private backendUrl: BackendUrlService, private httpClient: HttpClient) {
		//
	}

	sendReminder(approvalId: string): Observable<any> {
		const endpointUrl = this.backendUrl.createEndpointUrl(ApiEndpoint.remindApprovers, {
			approvalId,
		})
		return this.httpClient.post(endpointUrl, undefined)
	}
}
