import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ManageReviewApprovalViewComponent } from 'core/components/manage-review-approval-view/manage-review-approval-view.component'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { RouteSchemeData } from 'core/models/routeScheme'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { UserRoleGuard } from 'core/services/user-role.guard'

export const routes: Routes = [
	{
		path: EnumAppEndpoint.ManageApproval + '/' + EnumManager.Requester + '/:id',
		component: ManageReviewApprovalViewComponent,
		data: {
			manager: EnumManager.Requester,
			roles: [APP_PERMISSION.request],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
	{
		path: EnumAppEndpoint.ManageApproval + '/' + EnumManager.Approver + '/:id',
		component: ManageReviewApprovalViewComponent,
		data: {
			manager: EnumManager.Approver,
			roles: [APP_PERMISSION.approve],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
	{ path: EnumAppEndpoint.ManageApproval, redirectTo: 'error', pathMatch: 'full' },
	{
		path: EnumAppEndpoint.ManageApproval + '/' + EnumManager.Requester,
		redirectTo: 'error',
		pathMatch: 'full',
	},
	{
		path: EnumAppEndpoint.ManageApproval + '/' + EnumManager.Approver,
		redirectTo: 'error',
		pathMatch: 'full',
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class ManageReviewApprovalViewRoutingModule {}
