import { Injectable } from '@angular/core'
import { UiConfiguration } from 'core/services'
import { IAccount, IVisitor } from 'shared/services/pendo/pendo.interface'

declare var pendo: any

@Injectable({
	providedIn: 'root',
})
export class PendoService {
	pendoNodeId = 'pendoInit'
	pendoScriptSrc = 'https://cdn.pendo.io/agent/static/2f025824-0a50-4417-7f7c-8f569f219540/pendo.js'

	constructor(private uiConfig: UiConfiguration) {}

	init(): Promise<boolean> {
		if (this.uiConfig.pendo) {
			return this.loadScript(this.pendoNodeId, this.pendoScriptSrc)
		}
		return Promise.resolve(true)
	}

	initialize(visitor: IVisitor, account?: IAccount): void {
		if ((window as any).pendo) {
			pendo.initialize({ visitor, account })
		}
	}

	loadScript(scriptId: string, sourceUrl: string): Promise<boolean> {
		return new Promise((resolve) => {
			const node = document.createElement('script')
			node.id = scriptId
			node.src = sourceUrl
			node.type = 'text/javascript'
			node.async = true
			document.getElementsByTagName('head')[0].appendChild(node)
			resolve(true)
		})
	}
}
