<section class='d-flex overflow-hidden-100p justify-content-center'>
  <section class='p-4 d-flex flex-column justify-content-center'>
    <button data-qa='image-navigation-left-button' mat-mini-fab
            (click)='navigate(NavigateDirectionImage.LEFT)'
            color='primary'
            [ngClass]='{hide: currentActiveAssetIndex < 1 }'
            class='shadow-none'>
                    <span class='material-icons'>
                      chevron_left
                    </span>
    </button>
  </section>
  <section class='d-flex flex-column flex-1 overflow-hidden-100p'>
    <div class='mt-5 mb-5 d-flex flex-1 justify-content-center align-content-center overflow-hidden'>
      <div class='d-flex flex-center overflow-hidden-100p'>
        <img data-qa='loaded-image' #imageElement [src]='detailImageUrl' (load)='onLoadImage()' [ngClass]='imageOrientation'>
      </div>
    </div>
  </section>
  <section class='p-4 d-flex flex-column justify-content-center'>
    <button data-qa='image-navigation-right-button' mat-mini-fab
            (click)='navigate(NavigateDirectionImage.RIGHT)'
            color='primary'
            [ngClass]='{hide: currentActiveAssetIndex >= numberOfAssets-1}'
            class='shadow-none'>
                    <span class='material-icons'>
                      chevron_right
                    </span>
    </button>
  </section>
</section>
