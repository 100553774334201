import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApprovalAssetExtendModel } from 'core/models'
import { ApprovalInteractionService } from 'core/services/approval-interaction.service'
import { BackendUrlService } from 'core/services/backend-url.service'
import { UserService } from 'core/services/user.service'
import { Observable, Subject } from 'rxjs'
import { map, mergeMap, takeUntil } from 'rxjs/operators'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ManageReviewService {
	observeGetApproval$!: Subject<void>

	constructor(
		private http: HttpClient,
		private approvalInteractionService: ApprovalInteractionService,
		private userService: UserService,
		private backendUrlService: BackendUrlService
	) {}

	/**
	 * To search for one approval
	 * @param id approvalId
	 * @param isMock (optional) default false, when true they using the environment.api.mockUrl
	 */
	getApproval(id: string, isMock: boolean = false): Observable<ApprovalAssetExtendModel> {
		if (!!this.observeGetApproval$ && !this.observeGetApproval$.closed) {
			this.observeGetApproval$.next() // trigger the unsubscribe
			this.observeGetApproval$.complete() // finalize & clean up the subject stream
		}
		this.observeGetApproval$ = new Subject()

		let url = this.backendUrlService.createEndpointUrl(ApiEndpoint.approval)
		url += '/' + id

		return this.http.get<ApprovalAssetExtendModel>(url).pipe(
			mergeMap((value) => {
				this.approvalInteractionService.constructApproval(value)
				return this.userService.loadUsersInformation(value.relatedUsers).pipe(map(() => value))
			}),
			takeUntil(this.observeGetApproval$)
		)
	}
}
