import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service'
import { LocalizedString } from 'core/pipes/localized-string'
import { UserStoreService } from 'core/services/user-store.service'
import { BehaviorSubject } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'

@Pipe({
	name: 'localizedString',
})
export class LocalizedStringPipe implements PipeTransform {
	constructor(
		private userStoreService: UserStoreService,
		private translateService: TranslateService
	) {}

	transform(prop: LocalizedString): Observable<string> {
		const localizedValue$ = new BehaviorSubject<string>(this.instant(prop))
		this.translateService.onLangChange.subscribe((_: LangChangeEvent) => {
			localizedValue$.next(this.instant(prop))
		})
		return localizedValue$
	}

	instant(prop: LocalizedString) {
		return prop[this.userStoreService.userLanguage || 'en'] || prop.en
	}
}
