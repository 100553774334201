import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ThumbRatingComponent } from 'shared/components/thumb-rating/thumb-rating.component'

@NgModule({
	declarations: [ThumbRatingComponent],
	imports: [CommonModule, TranslateModule, MatIconModule],
	exports: [ThumbRatingComponent],
})
export class ThumbRatingModule {}
