import { Injectable } from '@angular/core'
import { apiAuthEndpointUrl, apiEndpointUrl } from 'core/http/environment-api'

@Injectable({
	providedIn: 'root',
})
export class BackendUrlService {
	createEndpointUrl(endpoint: string, params?: any, mock: boolean = false): string {
		return '{apiUrl}/{endpoint}'
			.format({
				apiUrl: apiEndpointUrl(mock),
				endpoint,
			})
			.format(params)
	}

	createAuthEndpointUrl(endpoint: string, params?: any, mock: boolean = false): string {
		return '{apiUrl}/{endpoint}'
			.format({
				apiUrl: apiAuthEndpointUrl(mock),
				endpoint,
			})
			.format(params)
	}
}
