import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class UiConfiguration {
	pendo = false
	notification!: NotificationConfig
	defaultLocale!: string
	supportedLocale!: Array<string>
}

export interface NotificationConfig {
	hideTimeout: number
}
