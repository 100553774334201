import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, UrlSegment } from '@angular/router'
import { ApprovalRequestsViewComponent } from 'core/components/approval-request-overview/components/'
import { EnumAppEndpoint, EnumFilterStateStatus, EnumManager } from 'core/models'

@Component({
	selector: 'app-approval-request-overview',
	templateUrl: './approval-request-overview.component.html',
	styleUrls: ['./approval-request-overview.component.scss'],
})
export class ApprovalRequestOverviewComponent implements OnInit, AfterViewInit {
	@ViewChild('approvalRequestsView') approvalRequestsViewComponent!: ApprovalRequestsViewComponent

	activeManager!: EnumManager
	activStatus: EnumFilterStateStatus

	constructor(private route: ActivatedRoute, private router: Router) {
		this.activStatus = EnumFilterStateStatus.ALL
	}

	ngOnInit(): void {
		this.route.url.subscribe((x: UrlSegment[]) => {
			const urlSegment = x[0] || new UrlSegment('', {})
			if (urlSegment.path === EnumAppEndpoint.Requester) {
				this.activeManager = EnumManager.Requester
			}
			if (urlSegment.path === EnumAppEndpoint.Approver) {
				this.activeManager = EnumManager.Approver
			}
		})
	}

	ngAfterViewInit(): void {
		this.approvalRequestsViewComponent?.createNewApproval$?.subscribe((_) =>
			this.navigateCreateNewApproval()
		)
	}

	get ManagerEnum() {
		return EnumManager
	}

	changeManager($event: any) {
		this.activeManager = $event
		if ($event === this.ManagerEnum.Approver) {
			this.router.navigate([EnumAppEndpoint.Approver])
		}
		if ($event === this.ManagerEnum.Requester) {
			this.router.navigate([EnumAppEndpoint.Requester])
		}
	}

	changeFilterStatus($event: EnumFilterStateStatus) {
		if (this.activStatus === $event) {
			this.approvalRequestsViewComponent.doSearchAgain()
		} else {
			this.activStatus = $event
		}
	}

	navigateCreateNewApproval() {
		if (this.activeManager === this.ManagerEnum.Requester) {
			this.router.navigate([EnumAppEndpoint.NewApproval])
		}
	}
}
