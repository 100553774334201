export interface LogoImageDefinition {
	src: URL
	width: number
	minWidth: number
	height: number
}

export interface LogosDefinition {
	normal: LogoImageDefinition
	small: LogoImageDefinition
}
