import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonGroupRatingModule } from 'core/components/button-group-rating/button-group-rating.module'
import { ConsentDetailsSidebarComponent } from 'core/components/consent/consent-details-overview/components/consent-details-sidebar/consent-details-sidebar.component'
import { LocalizedStringPipeModule } from 'core/pipes/localized-string'

@NgModule({
	declarations: [ConsentDetailsSidebarComponent],
	exports: [ConsentDetailsSidebarComponent],
	imports: [CommonModule, ButtonGroupRatingModule, LocalizedStringPipeModule],
})
export class ConsentDetailsSidebarModule {}
