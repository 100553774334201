import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApprovalStatusResponse, EnumFilterStateStatus } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ChangeApprovalStatusService {
	constructor(private backendUrlService: BackendUrlService, private httpClient: HttpClient) {}

	changeStatus(
		approvalId: string,
		status: EnumFilterStateStatus
	): Observable<ApprovalStatusResponse> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.changeApprovalStatus, {
			approvalId,
			status,
		})

		return this.httpClient.post<ApprovalStatusResponse>(url, null)
	}

	resumeApproval(approvalId: string): Observable<ApprovalStatusResponse> {
		return this.changeStatus(approvalId, EnumFilterStateStatus.IN_PROGRESS)
	}

	pauseApproval(approvalId: string): Observable<ApprovalStatusResponse> {
		return this.changeStatus(approvalId, EnumFilterStateStatus.PAUSED)
	}

	closeApproval(approvalId: string): Observable<ApprovalStatusResponse> {
		return this.changeStatus(approvalId, EnumFilterStateStatus.CLOSED)
	}
}
