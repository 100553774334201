import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { SelectListComponent } from './select-list.component'

@NgModule({
	declarations: [SelectListComponent],
	exports: [SelectListComponent],
	imports: [CommonModule, MatIconModule],
})
export class SelectListModule {}
