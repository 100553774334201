import { AbstractControl, ValidationErrors } from '@angular/forms'
import { EnumFilterStateStatus } from 'core/models'

export class InvalidRating implements ValidationErrors {
	ratingGiven!: boolean
	status!: EnumFilterStateStatus
}

export class CustomValidators {
	/**
	 * Returns true if the rating is APPROVED or REJECTED
	 */
	static hasRating(control: AbstractControl): InvalidRating | null {
		if (
			control.value === EnumFilterStateStatus.APPROVED ||
			control.value === EnumFilterStateStatus.REJECTED
		) {
			return null
		} else {
			return { ratingGiven: false, status: control.value } as InvalidRating
		}
	}
}
