import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class ReloadRouterService {
	reloadPath = 'reload'

	constructor(public readonly router: Router) {
		router.config.unshift({ path: this.reloadPath })
	}

	public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		return this.router
			.navigateByUrl(this.reloadPath, { skipLocationChange: true })
			.then(() => this.router.navigate(commands, extras))
	}

	public navigateSelf(extras?: NavigationExtras): Promise<boolean> {
		const commands = [this.router.url]
		return this.router
			.navigateByUrl(this.reloadPath, { skipLocationChange: true })
			.then(() => this.router.navigate(commands, extras))
	}
}
