import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UserModel, UsersInformationResponse } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators'
import { ApiAuthEndpoint, ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	usersInformation: Array<UserModel> = []

	constructor(private backendUrlService: BackendUrlService, private http: HttpClient) {}

	getUser(userId: string): Observable<UserModel> {
		return this.loadUsersInformation([userId]).pipe(
			map((res) => {
				return res.filter((userInfo) => userInfo.userId === userId)[0]
			})
		)
	}

	getUsers(): Observable<Array<UserModel>> {
		const url = this.backendUrlService.createAuthEndpointUrl(ApiAuthEndpoint.users)
		return this.http.get<Array<UserModel>>(url)
	}

	getUserPermissions(): Observable<UserModel> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.user)
		return this.http.get<UserModel>(url)
	}

	loadUsersInformation(searchUserIds: Array<string>): Observable<Array<UserModel>> {
		const url = this.backendUrlService.createAuthEndpointUrl(ApiAuthEndpoint.userinformation)
		const bodyParams = {
			userIds: [...searchUserIds],
		}

		return this.http.post<Array<UserModel>>(url, bodyParams).pipe(
			map((value: UsersInformationResponse | any) => {
				this.usersInformation = value.users
				return value.users
			})
		)
	}
}
