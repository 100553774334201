import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { TranslateModule } from '@ngx-translate/core'
import { ConsentDetailsSidebarModule } from 'core/components/consent/consent-details-overview/components/consent-details-sidebar/consent-details-sidebar.module'
import { ConsentDetailsOverviewComponent } from 'core/components/consent/consent-details-overview/consent-details-overview.component'
import { DetailsViewModule } from 'core/components/details-view'
import { ImageNavigationModule } from 'core/components/details-view/components/image-navigation/image-navigation.module'
import { LoadingSpinnerModule } from 'shared/components'

@NgModule({
	declarations: [ConsentDetailsOverviewComponent],
	imports: [
		CommonModule,
		ConsentDetailsSidebarModule,
		DetailsViewModule,
		MatDividerModule,
		LoadingSpinnerModule,
		ImageNavigationModule,
		MatButtonModule,
		TranslateModule,
	],
})
export class ConsentDetailsOverviewModule {}
