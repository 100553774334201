<div [ngClass]="{'cursor-disabled': (finishStepRunning$ | async)}">
  <div mat-dialog-title class='d-flex flex-row'>
    <h3 class='flex-1'>{{'finishStepDialog.titleDialog' | translate}}</h3>
    <div class='col-2 d-flex justify-content-end'>
      <button mat-mini-fab
              [disabled]='(finishStepRunning$ | async)'
              (click)='closeView()'
              color='primary'
              class='shadow-none'>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content class='col-12 d-flex flex-row flex-nowrap'>
    <div class='col-6'>
      <div class='me-5 can-input-textarea border border-1 border-primary'>
        <section class='d-flex flex-row flex-center'>
            <textarea #commentTextarea rows='8'
                      class='border-0 flex-1'
                      [disabled]='(finishStepRunning$ | async)'
                      [placeholder]="'finishStepDialog.commentPlaceholderText' | translate"
                      [textContent]=''></textarea>
        </section>
      </div>
    </div>
    <div class='d-flex flex-column justify-content-center col-6'>
      <div class='mb-1'>
        <app-thumb-rating
          class='d-flex flex-center flex-row mat-h1'
          [status]='data.statusResponse?.status'
        ></app-thumb-rating>
      </div>
      <div class='d-flex flex-column flex-center'>
        <div *ngIf='data?.statusResponse?.counts.approved > 0'>
          <span>{{'finishStepDialog.labelAboutApproved' | translate: { count: data?.statusResponse.counts.approved } }}</span>
        </div>
        <div *ngIf='data?.statusResponse?.counts.rejected > 0'>
          <span>{{'finishStepDialog.labelAboutRejected' | translate: { count: data?.statusResponse.counts.rejected } }}</span>
        </div>
      </div>
      <div *ngIf='false' class='mt-3'>
        <button mat-button [mat-dialog-close]='true' class='active btn-rounded'>{{'Finish' | translate}}</button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class='d-flex flex-row flex-nowrap'>
    <div class='col-6'></div>
    <div class='col-6 finish-button-wrapper d-flex justify-content-center'>
      <button data-qa='finish-step-dialog-button' mat-button [disabled]='(finishStepRunning$ | async)'
              (click)='doFinishStep()'
              class='active btn-rounded flex-fill'>
        {{'finishStepDialog.buttonFinish' | translate}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
