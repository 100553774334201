import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MaterialModule } from 'shared/modules'
import { ComponentBootstrapRoutingModule } from './component-bootstrap-routing.module'
import { ComponentBootstrapComponent } from './component-bootstrap.component'

@NgModule({
	declarations: [ComponentBootstrapComponent],
	imports: [CommonModule, ComponentBootstrapRoutingModule, MaterialModule],
})
export class ComponentBootstrapModule {}
