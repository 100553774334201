import { Injectable } from '@angular/core'
import { ApprovalAssetModel, StepModel, UserModel } from 'core/models'
import { ApprovalTemplatePersisted, ApprovalTemplateStep } from 'core/models/approvalTemplate'
import { UserService } from 'core/services/user.service'
import { Observable } from 'rxjs/internal/Observable'
import { of } from 'rxjs/internal/observable/of'
import { map } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class ApprovalTemplateConverterService {
	constructor(private userService: UserService) {}

	applyTemplate(
		approval: ApprovalAssetModel,
		template: ApprovalTemplatePersisted
	): Observable<ApprovalAssetModel> {
		approval.steps = []
		if (template.steps?.length > 0) {
			return this.loadAllUsers(template).pipe(
				map((allUsers) => {
					template.steps?.forEach((templateStep) => {
						const step = {} as StepModel
						Object.assign(step, templateStep)
						this.applyApprovers(step, templateStep.approvers, allUsers)
						approval.steps.push(step)
					})
					this.calculateDueDates(approval, template.steps)
					return approval
				})
			)
		}
		return of(approval)
	}

	loadAllUsers(template: ApprovalTemplatePersisted): Observable<UserModel[]> {
		const allUserIds: string[] = []
		template.steps.forEach((step) => allUserIds.push(...step.approvers))
		return this.userService.loadUsersInformation(allUserIds)
	}

	applyApprovers(step: StepModel, userIds: string[], allUsers: UserModel[]) {
		if (userIds.length > 0) {
			step.approvers = allUsers.filter((user) => userIds.includes(user.userId))
		} else {
			step.approvers = []
		}
	}

	calculateDueDates(approval: ApprovalAssetModel, steps: ApprovalTemplateStep[]) {
		const stepCount = steps.length
		if (approval.steps.length !== steps.length) {
			throw new Error(
				'approval step count ' + approval.steps.length + ' does not match ' + steps.length
			)
		}

		if (stepCount > 0) {
			let lastUsedDate = Date.now() / 1000
			for (let i = 0; i < stepCount; ++i) {
				const templateStep = steps[i]
				const approvalStep = approval.steps[i]
				if (templateStep.duration) {
					lastUsedDate = lastUsedDate + templateStep.duration
					approvalStep.dueDate = new Date(lastUsedDate * 1000)
				}
			}
			approval.dueDate = approval.steps[approval.steps.length - 1]?.dueDate
		}
	}
}
