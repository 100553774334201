import { Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { NotificationData, NOTIFICATIONTYPE } from 'core/models/notification'

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
	protected component = 'notification'
	panelClass = 'default'

	constructor(
		public componentRef: MatSnackBarRef<NotificationComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
		private renderer2: Renderer2
	) {
		this.panelClass = this.getPanelClass(data.type)
	}

	ngOnInit(): void {
		const snackElements = document.getElementsByTagName('snack-bar-container')
		if (snackElements.length !== 0) {
			this.renderer2.listen(
				snackElements[snackElements.length - 1],
				'click',
				this.dismiss.bind(this)
			)
		}
	}

	getPanelClass(type: NOTIFICATIONTYPE) {
		return this.component + '-' + type
	}

	dismiss() {
		this.componentRef.dismissWithAction()
	}
}
