<section data-qa='details-sidebar-approval-title' class='mat-h2 d-flex flex-fill-100 justify-content-between'>
  <div class='flex-center'>{{'detailsView.stepApprovalTitle' | translate}}</div>
  <div class='mat-icon-button'>
    <button data-qa='asset-rating-comment-delete-button' mat-icon-button *ngIf='asset?.newestComment && !asset?.hasRating'
            (click)='clearActivity(this.asset.newestComment?.activityId)'>
      <span class='material-icons'>delete</span>
    </button>
  </div>
</section>

<section [ngClass]="{'cursor-disabled': !!asset?.hasRating}">
  <div class='can-input-textarea border border-1 border-primary'>
    <section class='d-flex flex-row flex-center'>
      <textarea data-qa='asset-rating-comment' #commentTextArea class='border-0 flex-1'
                rows='8'
                [formControl]='commentForm'></textarea>
      <button data-qa='asset-rating-comment-save-button' #commentButton
              (click)='clickComment()'
              [disabled]='!commentForm.dirty || !commentForm.valid || $any(updatingActivities$ | async)' mat-mini-fab color='primary'
              class='ms-3 me-3 shadow-none'>
        <!-- [disabled]="this.commentTextArea?.value === '' || !!asset?.hasRating || this.asset?.newestComment?.comment === this.commentTextArea?.value || $any(sendingActivity$ | async)"  -->
        <mat-icon>chat_bubble</mat-icon>
      </button>
    </section>
  </div>
</section>

<div data-qa='asset-rating-status' class='d-flex justify-content-center'>
  <div class='mt-5 mb-3' style='height: 5rem'>
    <!--suppress AngularInvalidExpressionResultType -->
    <app-thumb-rating
      *ngIf='asset?.hasRating'
      class='d-flex flex-center flex-row mat-h1'
      [status]='asset.newestActivity!.rating'
    ></app-thumb-rating>
  </div>
</div>
<div class='btn-toolbar d-flex flex-row justify-content-center'>
  <ng-container *ngIf='!ratingForm.valid'>
    <button mat-button data-qa='asset-rating-approve-button' class='active btn-rounded'
            (click)='clickRating(FilterStateStatus.APPROVED)'
            [disabled]='$any(updatingActivities$ | async)'>
      <mat-icon class='' [inline]='true'>thumb_up</mat-icon>
      {{'detailsView.buttonApproveLabel' | translate}}
    </button>
    <button mat-button data-qa='asset-rating-reject-button' class='active btn-rounded'
            (click)='clickRating(FilterStateStatus.REJECTED)'
            [disabled]='$any(updatingActivities$ | async)'>
      <mat-icon class='' [inline]='true'>thumb_down</mat-icon>
      {{'detailsView.buttonRejectLabel' | translate}}
    </button>
  </ng-container>
  <ng-container *ngIf='ratingForm.valid'>
    <button mat-button data-qa='asset-rating-reset-button' class='active btn-rounded'
            (click)='clearActivity(this.asset.newestActivity?.id)'
            [disabled]='$any(updatingActivities$ | async)'>
      <ng-container *ngIf='ratingForm.value === FilterStateStatus.REJECTED'>
        {{'detailsView.buttonResetRejectionLabel' | translate}}
      </ng-container>
      <ng-container *ngIf='ratingForm.value === FilterStateStatus.APPROVED'>
        {{'detailsView.buttonResetApprovalLabel' | translate}}
      </ng-container>
    </button>
  </ng-container>
</div>
