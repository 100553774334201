import { Component, EventEmitter, Input, Output } from '@angular/core'
import { StepModel } from 'core/models'

@Component({
	selector: 'app-list-approval-steps[steps][editClick][deleteClick][moveClick]',
	templateUrl: './list-approval-steps.component.html',
	styleUrls: ['./list-approval-steps.component.scss'],
})
export class ListApprovalStepsComponent {
	@Input() steps!: Array<StepModel>
	@Output() editClick: EventEmitter<{ index: number; step: StepModel }> = new EventEmitter()
	@Output() deleteClick: EventEmitter<number> = new EventEmitter()
	@Output() moveClick: EventEmitter<{ stepIndex: number; move: number }> = new EventEmitter()

	edit(index: number, step: StepModel) {
		this.editClick.emit({ index, step })
	}

	delete(stepIndex: number) {
		this.deleteClick.emit(stepIndex)
	}

	move(stepIndex: number, move: number) {
		// move up = 1, move down = -1
		this.moveClick.emit({ stepIndex, move })
	}
}
