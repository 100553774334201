import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { indicate } from 'core/http'
import { ApprovalAssetExtendModel, AssetModel, EnumAppEndpoint, EnumManager } from 'core/models'
import { EnumNavigateDirectionImage } from 'core/models/navigationDirection'
import { ManageReviewService } from 'core/services'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-details-view',
	templateUrl: './details-view.component.html',
	styleUrls: ['./details-view.component.scss'],
})
export class DetailsViewComponent implements OnInit {
	loading$ = new Subject<boolean>()

	approvalAsset!: ApprovalAssetExtendModel
	manager!: EnumManager
	currentActiveAssetIndex!: number

	constructor(
		private manageReviewService: ManageReviewService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	get EnumNavigateDirectionImage() {
		return EnumNavigateDirectionImage
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.onAssetChanged()
		})
	}

	setCurrentActiveAssetIndex(assetId: string) {
		const assetIndex = this.approvalAsset.containedAssets?.findIndex((value: AssetModel) => {
			if (assetId === value.id) {
				return value
			}
		})

		this.currentActiveAssetIndex = assetIndex === -1 ? 0 : assetIndex
	}

	onAssetChanged() {
		this.route.params.subscribe(() => {
			this.manager = this.route.snapshot.data.manager
			this.manageReviewService
				.getApproval(this.route.snapshot.params.approvalId)
				.pipe(indicate(this.loading$))
				.subscribe({
					next: (result: ApprovalAssetExtendModel) => {
						this.approvalAsset = result
						this.setCurrentActiveAssetIndex(this.route.snapshot.params.assetId)
					},
					error: (error) => {
						// TODO error implementation later, popup angular snackbar or what ever
						console.error(error)
					},
				})
		})
	}

	onCloseView() {
		if (this.manager === EnumManager.Approver) {
			this.router.navigate([EnumAppEndpoint.ManageApproval, this.manager, this.approvalAsset.id])
		}
	}

	onClickNavigate(navDirectionImage: EnumNavigateDirectionImage) {
		const lastIndex = this.currentActiveAssetIndex

		switch (navDirectionImage) {
			case EnumNavigateDirectionImage.RIGHT:
				this.currentActiveAssetIndex++
				break
			case EnumNavigateDirectionImage.LEFT:
				this.currentActiveAssetIndex--
				break
		}

		if (
			this.currentActiveAssetIndex >= 0 &&
			this.currentActiveAssetIndex < this.approvalAsset.containedAssets.length
		) {
			const nextAsset = this.approvalAsset.containedAssets[this.currentActiveAssetIndex]

			this.doNavigate(nextAsset.id)
		} else {
			this.currentActiveAssetIndex = lastIndex
		}
	}

	doNavigate(nextAssetId: string) {
		this.router.navigate([
			EnumAppEndpoint.ManageApproval,
			this.manager,
			this.approvalAsset.id,
			EnumAppEndpoint.Details,
			this.route.snapshot.params.scheme,
			nextAssetId,
		])
	}
}
