import { defer, Observable, Subject, timer } from 'rxjs'
import { finalize } from 'rxjs/operators'

export const prepare = <T>(callback: () => void) => {
	return (source: Observable<T>): Observable<T> =>
		defer(() => {
			callback()
			return source
		})
}

export const indicate = <T>(
	indicator: Subject<boolean>,
	timeout: number | undefined = undefined
) => {
	return (source: Observable<T>): Observable<T> =>
		source.pipe(
			prepare(() => {
				indicator.next(true)
			}),
			finalize(() => {
				if (timeout) {
					timer(timeout).subscribe((_) => {
						indicator.next(false)
					})
				} else {
					indicator.next(false)
				}
			})
		)
}

/* export const toClass = <T>(ClassType: new() => T) => (source: Observable<T>) => source.pipe(
  map(val => Object.assign(new ClassType(), val))
);*/
