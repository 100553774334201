import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CollectionModel } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class CollectionService {
	constructor(private backendUrlService: BackendUrlService, private http: HttpClient) {}

	getCollections(): Observable<Array<CollectionModel>> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.collections)
		return this.http.get<Array<CollectionModel>>(url)
	}
}
