import { Component, Inject, OnInit } from '@angular/core'
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { MAT_DATE_FORMATS } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { APP_DATE_FORMATS, StepModel } from 'core/models'
import * as moment from 'moment-timezone'
import {
	ModifyApprovalUserComponent,
	ModifyApprovalUserDialogDataModel,
} from './modify-approval-user/modify-approval-user.component'

export interface ModifyApprovalStepDialogDataModel {
	index?: number
	step: StepModel
}

@Component({
	selector: 'app-modify-approval-steps',
	templateUrl: './modify-approval-steps.component.html',
	styleUrls: ['./modify-approval-steps.component.scss'],
	providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})
export class ModifyApprovalStepsComponent implements OnInit {
	formGroupState!: UntypedFormGroup
	minDueDate!: Date

	formFields = {
		stepName: 'stepName',
		dueDate: 'dueDate',
		description: 'description',
		approvers: 'approvers',
	}

	constructor(
		private dialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		public componentDialogRef: MatDialogRef<ModifyApprovalStepsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModifyApprovalStepDialogDataModel
	) {}

	ngOnInit(): void {
		this.minDueDate = moment().toDate()

		/**
		 * when we have no index, we using the dialog as 'add approval step'
		 */
		if (this.data.index === undefined) {
			this.constructAddNewStep()
		}

		this.formGroupState = this.formBuilder.group({
			stepName: new UntypedFormControl(this.data.step.name, Validators.required),
			dueDate: new UntypedFormControl(this.data.step.dueDate),
			description: new UntypedFormControl(this.data.step.description),
			approvers: new UntypedFormControl(this.data.step.approvers, [Validators.required]),
		})
	}

	constructAddNewStep() {
		this.data.step = {
			approvers: [] as Array<string>,
		} as StepModel
	}

	/**
	 * Access to form controls
	 */
	get f() {
		return this.formGroupState.controls
	}

	save() {
		this.data.step = {
			name: this.f[this.formFields.stepName].value,
			dueDate: this.f[this.formFields.dueDate].value,
			description: this.f[this.formFields.description].value,
			approvers: this.data.step.approvers,
		} as StepModel
		this.componentDialogRef.close(this.data)
	}

	openAddApproverDialog() {
		const modifyUserDialog = this.dialog.open(ModifyApprovalUserComponent, {
			hasBackdrop: true,
			panelClass: ['modify-approval-user-dialog'],
			disableClose: true,
			data: {
				assignedApprovers: this.data.step.approvers,
			} as ModifyApprovalUserDialogDataModel,
		})
		modifyUserDialog.afterClosed().subscribe((result: Array<string>) => {
			if (result) {
				// TODO remove the splice when we support multiple approvers
				this.data.step.approvers.splice(0, this.data.step.approvers.length)

				this.data.step.approvers.push(...result)
			}
			this.formGroupState.controls[this.formFields.approvers].updateValueAndValidity()
		})
	}

	removeApprover(index: number) {
		this.data.step.approvers.splice(index, 1)
		this.formGroupState.controls[this.formFields.approvers].updateValueAndValidity()
	}
}
