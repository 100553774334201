export enum EnumFilterStateStatus {
	ALL = 'ALL',
	IN_PROGRESS = 'IN_PROGRESS',
	PAUSED = 'PAUSED',
	OVERDUE = 'OVERDUE',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED',
	CLOSED = 'CLOSED',
	FINISHED = 'FINISHED',
	RESET = 'RESET',
}
