'use strict'

/*String.prototype.fmt = function (hash) {
  var string = this, key; for (key in hash) string = string.replace(new RegExp('\\{' + key + '\\}', 'gm'), hash[key]); return string
}
var person = '{title} {first} {last}'.fmt({ title: 'Agent', first: 'Jack', last: 'Bauer' });
// person = 'Agent Jack Bauer'*/

declare global {
	export interface String {
		format(entries: any): string
	}
}

export declare function format(): string

String.prototype.format = function (entries): any {
	let val = this
	if (entries) {
		const hash = Object.entries(entries)

		hash.forEach((value: any, index) => {
			const find = '{' + value[0] + '}'
			const regExp = new RegExp(find, 'g')
			val = val.replace(regExp, value[1])
		})
	}
	return val
}

/*
  for (key in hash) {
    retval = retval.replace(new RegExp('\\{' + key + '\\}', 'gm'), hash[key]);
  }
  return retval;
*/
/*function mutliStringReplace(object, string) {
  let val = string;
  const entries = Object.entries(object);
  entries.forEach((para) => {
    const find = '{' + para[0] + '}';
    const regExp = new RegExp(find, 'g');
    val = val.replace(regExp, para[1]);
  });
  return val;
}*/
