import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { indicate } from 'core/http'
import { HeaderStateService } from 'core/services'
import { ConsentInteractionService } from 'core/services/consent-interaction.service'
import { ConsentService } from 'core/services/consent.service'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-consent-list-overview',
	templateUrl: './consent-list-overview.component.html',
	styleUrls: ['./consent-list-overview.component.scss'],
})
export class ConsentListOverviewComponent implements OnInit {
	loading$ = new Subject<boolean>()

	constructor(
		private consentService: ConsentService,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private headerStateService: HeaderStateService,
		public consentInteractionService: ConsentInteractionService
	) {}

	ngOnInit(): void {
		this.headerStateService.setLogoClickLink()
		this.translateService.onLangChange.subscribe((_) => {
			this.changeHeaderTitle()
		})
		setTimeout(() => {
			this.route.params.subscribe(() => {
				const workflowId = this.route.snapshot.params.workflowId
				this.consentService
					.getConfiguration(workflowId)
					.pipe(indicate(this.loading$))
					.subscribe((workflowConfig) => {
						this.consentInteractionService.workflowConfig = workflowConfig
						this.changeHeaderTitle()
					})
			})
		})
	}

	get workflowConfig() {
		return this.consentInteractionService.workflowConfig
	}

	changeHeaderTitle() {
		if (this.workflowConfig) {
			this.headerStateService.setTitleLocalizedString(this.workflowConfig.configuration.displayName)
		}
	}
}
