import { EventEmitter, Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LocalizedString, LocalizedStringPipe } from 'core/pipes/localized-string'

@Injectable({
	providedIn: 'root',
})
export class HeaderStateService {
	public headerTitleChanged = new EventEmitter<string>()
	public logoLinkChanged = new EventEmitter<string | undefined>()

	constructor(
		private translate: TranslateService,
		private localizedStringPipe: LocalizedStringPipe
	) {}

	setLogoClickLink(link?: string) {
		setTimeout(() => {
			this.logoLinkChanged.emit(link)
		})
	}

	setTitle(title: string) {
		setTimeout(() => {
			this.headerTitleChanged.emit(title)
		})
	}

	setTitleTranslation(translationKey: string, params?: object) {
		setTimeout(() => {
			this.headerTitleChanged.emit(this.translate.instant(translationKey, params))
		})
	}

	setTitleLocalizedString(prop: LocalizedString) {
		this.setTitle(this.localizedStringPipe.instant(prop))
	}
}
