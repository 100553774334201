import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router'
import { UserModel } from 'core/models'
import { UserService } from 'core/services/user.service'
import { Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { PendoService } from 'shared/services/pendo/pendo.service'
import { isNullOrUndefined } from 'shared/utilities/utils'

@Injectable({
	providedIn: 'root',
})
export class UserRoleGuard implements CanActivate {
	userRoles?: Array<string>

	constructor(
		private userService: UserService,
		private router: Router,
		private pendoService: PendoService
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		// roles to activate the route
		const neededRoles = route.data?.roles as Array<string>
		if (this.userRoles) {
			return of(this.hasPermission(neededRoles, this.userRoles))
		} else {
			return this.userService.getUserPermissions().pipe(
				switchMap((user: UserModel) => {
					const canAccess = this.hasPermission(neededRoles, user.permissions)
					if (!canAccess) {
						// if the user has no permission, we routing him to the app root
						return of(this.router.parseUrl('/'))
					}

					this.pendoService.initialize(
						{
							id: user.userId,
							firstName: user.firstName,
							lastName: user.lastName,
						},
						{
							id: user.tenant,
						}
					)

					this.userRoles = user.permissions
					return of(canAccess)
				})
			)
		}
	}

	hasPermission(
		neededRoles: Array<string> | undefined | null,
		userPermissions: Array<string>
	): boolean {
		// initial, user has no permission
		let canAccess = false

		// when no roles required, user can always access
		if (isNullOrUndefined(neededRoles) || neededRoles?.length === 0) {
			canAccess = true
		}

		neededRoles?.forEach((role) => {
			if (userPermissions.includes(role)) {
				// if the user has any role, he has access
				canAccess = true
				return true
			}
		})

		return canAccess
	}

	hasRole(role: string): boolean {
		return this.userRoles?.includes(role) || false
	}
}
