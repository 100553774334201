export const ApiEndpoint = {
	approval: 'v1/approval',
	approvalTemplates: 'v1/templates',
	approvalTemplate: 'v1/templates/{templateId}',
	activities: 'v1/approval/{id}/activities',
	stepStatus: 'v1/approval/{approvalId}/step/{stepId}/status',
	stepFinish: 'v1/approval/{approvalId}/step/{stepId}/finish',
	stepActivities: 'v1/approval/{approvalId}/step/{stepId}/activities',
	clearActivity: 'v1/approval/{approvalId}/step/{stepId}/asset/{contentId}/activities/{activityId}',
	changeApprovalStatus: 'v1/approval/{approvalId}/status/{status}',
	reset: 'v1/approval/{approvalId}/reset',
	remindApprovers: 'v1/approval/{approvalId}/remind',
	updateConsentStatus: 'v1/consent/{workflowId}/{scheme}/{contentId}?statusId={statusId}',
	removeConsentStatus: 'v1/consent/{workflowId}/{scheme}/{contentId}',
	collections: 'v1/collections',
	user: 'v1/user',
	workflowConfiguration: 'v1/consent/{workflowId}',
}

export const ApiAuthEndpoint = {
	authenticate: 'v1/authenticate?redirect_uri={redirectUri}',
	users: 'v1/users',
	userinformation: 'v1/userinformation',
	logout: 'v1/logout?redirect_uri={redirectUri}',
}
