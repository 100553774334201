<aside class='d-flex flex-center col-10'
       #formDirective='ngForm'
       [formGroup]='formGroupState'>
  <div class='d-flex flex-1 flex-column'>
    <ng-container>
      <section class='d-flex flex-row pb-2 pt-2'>
        <div data-qa='create-new-approval-title' class='mat-h2 flex-1'>
          {{'createNewApproval.titleCreateNewApproval' | translate}}
        </div>
        <div>
          <button data-qa='cancel-save-approval-button' mat-button (click)='cancel()'
                  [disabled]='$any(sendingApproval$ | async)' color='primary'
                  class='m-1 active btn-rounded'>{{'createNewApproval.buttonLabelCancel' | translate}}</button>
          <button data-qa='save-approval-button' (click)='saveApproval()' mat-button
                  [disabled]='$any(formDirective.invalid || (sendingApproval$ | async))'
                  color='primary'
                  class='saveButtonId m-1 active btn-rounded'>{{'createNewApproval.buttonLabelSave' | translate}}</button>
        </div>
      </section>

      <section class='pb-5'>
        <table class='col-12'>
          <tr>
            <td>
              <article>
                <div class='mat-body-1'>
                  {{'createNewApproval.form.areaCollection.headerLabel' | translate}}
                </div>
                <div>
                  <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                    <mat-select data-qa='new-approval-collection-select'
                                (selectionChange)='onCollectionChanged($event)'
                                [formControlName]='formFields.collection' required
                                [placeholder]="(loadingCollections$ | async) ? ('createNewApproval.form.areaCollection.waitingPlaceholder' | translate) : ('createNewApproval.form.areaCollection.inputPlaceholder' | translate)">
                      <mat-option *ngFor='let collection of collections'
                                  [value]='collection'>{{collection.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </article>
            </td>
            <td class='col-1'></td>
            <td class='col-4'>
              <article>
                <div class='mat-body-1'>
                  {{'createNewApproval.form.areaStart.headerLabel' | translate}}
                </div>
                <div>
                  <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                    <mat-select data-qa='new-approval-start-approval-select' [formControlName]='formFields.start'
                                required>
                      <mat-option *ngFor='let key of fieldStartKeysData'
                                  [value]='key'>{{'createNewApproval.form.areaStart.values.' + key | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </article>
            </td>
          </tr>
          <tr>
            <td>
              <article>
                <div class='mat-body-1'>
                  {{'createNewApproval.form.areaApprovalName.headerLabel' | translate}}
                </div>
                <div>
                  <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                    <input matInput
                           data-qa='new-approval-name'
                           autocomplete='off'
                           [formControlName]='formFields.name'
                           [placeholder]="'createNewApproval.form.areaApprovalName.inputPlaceholder' | translate">
                  </mat-form-field>
                </div>
              </article>
            </td>
            <td></td>
            <td>
              <article>
                <div class='mat-body-1'>
                  {{'createNewApproval.form.areaDueDate.headerLabel' | translate}}
                </div>
                <div>
                  <mat-form-field class='d-block mat-form-field-small field-rounded' appearance='outline'>
                    <ng-container *ngIf='f[formFields.calcDueDate].value'>
                      <input value='{{f[formFields.calcDueDate].value | dynamicMoment | async}}' readonly matInput>
                    </ng-container>
                    <ng-container *ngIf='!f[formFields.calcDueDate].value'>
                      <input readonly matInput
                             [placeholder]="'createNewApproval.form.areaDueDate.inputPlaceholder' | translate">
                    </ng-container>
                  </mat-form-field>
                </div>
              </article>
            </td>
          </tr>
        </table>
      </section>
    </ng-container>

    <ng-container>
      <section class='d-flex flex-row pb-3'>
        <div class='mat-body-1 flex-1'>
          {{'createNewApproval.form.areaApprovalSteps.headerLabel' | translate}}
        </div>
        <div>
          <button data-qa='create-approval-load-templates-button' (click)='openTemplatesDialog()' mat-button
                  color='primary'
                  class='m-1 active btn-rounded'>{{'createNewApproval.buttonLabelTemplates' | translate}}</button>
          <button data-qa='modify-approval-steps-button' (click)='openModifyApprovalSteps()' mat-button color='primary'
                  class='m-1 active btn-rounded'>{{'createNewApproval.buttonLabelAdd' | translate}}</button>
        </div>
      </section>
      <section>
        <app-list-approval-steps (moveClick)='moveStep($event)' (deleteClick)='deleteStep($event)'
                                 (editClick)='editStep($event)' [steps]='approval.steps'></app-list-approval-steps>
      </section>
    </ng-container>
  </div>
</aside>
