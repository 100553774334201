<div>
  <div mat-dialog-title class='d-flex flex-row'>
    <h3 class='flex-1'>{{data.title}}</h3>
    <div class='col-2 d-flex justify-content-end'>
      <button *ngIf='!hideClose' mat-mini-fab
              mat-dialog-close
              color='primary'
              class='shadow-none'>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content class='col-12 d-flex flex-row'>
    {{data.contentText}}
  </mat-dialog-content>

  <mat-dialog-actions class='justify-content-end'>
    <button mat-button mat-dialog-close='cancel'
            matDialogClose
            class='active btn-rounded'>
      {{'components.confirmDialog.buttonCancel' | translate}}
    </button>
    <button data-qa='button-ok' mat-button
            (click)='onOk()'
            class='active btn-rounded text-nowrap'>
      <div class='d-flex'>
        <ng-container *ngIf='$any(loadingIndicator$ | async)'>
          <app-loading-spinner diameter='30' class='primary-canto flex-center pe-2'></app-loading-spinner>
        </ng-container>
        {{'components.confirmDialog.buttonOk' | translate}}
      </div>
    </button>
  </mat-dialog-actions>

</div>

