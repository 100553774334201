import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { HttpUrlEncodingCodec } from 'core/http'
import { BackendUrlService } from 'core/services/backend-url.service'
import { ApiAuthEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private loggedIn = false
	private jwt!: string | undefined

	constructor(
		private backendUrlService: BackendUrlService,
		private window: Window,
		private translate: TranslateService
	) {}

	public get isLoggedIn(): boolean {
		return this.loggedIn
	}

	public get token(): string | undefined {
		return this.jwt
	}

	checkJWT(queryParams: Params) {
		const authUrl = this.backendUrlService.createAuthEndpointUrl(ApiAuthEndpoint.authenticate)
		if (queryParams.jwt) {
			// store jwt
			// when we have a jwt, the user is loggedIn
			this.jwt = queryParams.jwt
			this.loggedIn = true
		} else {
			this.jwt = void 0
			this.loggedIn = false
		}

		if (!this.loggedIn) {
			const buildRedirectUri = new URL(this.window.location.pathname, this.window.location.origin)
			// parse any query parameters into redirectUri
			Object.keys(queryParams).map((key) => {
				buildRedirectUri.searchParams.append(key, queryParams[key])
			})

			const url = authUrl.format({
				redirectUri: new HttpUrlEncodingCodec().encodeValue(buildRedirectUri.toString()),
			})
			// redirect to canto authentication
			this.window.location.assign(url)
		}

		return this.loggedIn
	}

	logout() {
		const logoutUrl = this.backendUrlService.createAuthEndpointUrl(ApiAuthEndpoint.logout)
		const buildRedirectUri = new URL(this.window.location.pathname, this.window.location.origin)
		// define query parameters to append
		const queryParams = new Map<string, any>([['locale', this.translate.currentLang]])

		queryParams.forEach((value, key) => {
			buildRedirectUri.searchParams.append(key, value)
		})

		const url = logoutUrl.format({
			redirectUri: new HttpUrlEncodingCodec().encodeValue(buildRedirectUri.toString()),
		})
		this.loggedIn = false
		this.jwt = void 0
		// redirect to canto authentication logout
		this.window.location.assign(url)
	}
}
