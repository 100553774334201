import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { ApprovalTemplateBaseData, ApprovalTemplatePersisted } from 'core/models/approvalTemplate'
import { ApprovalTemplateService } from 'core/services/approval-template.service'
import { AppUiErrorCode } from 'core/services/error/error-code.model'
import { NotificationService } from 'core/services/notification.service'
import { ItemSelectionEvent } from 'shared/components/select-list/item-selection-event'

export interface ChooseApprovalTemplateComponentResult {
	selectedTemplate: ApprovalTemplatePersisted
	err: any
}

@Component({
	selector: 'app-choose-approval-template',
	templateUrl: './choose-approval-template.component.html',
	styleUrls: ['./choose-approval-template.component.scss'],
})
export class ChooseApprovalTemplateComponent implements OnInit {
	templates!: ApprovalTemplateBaseData[]
	selectedTemplateControl!: UntypedFormControl

	constructor(
		private formBuilder: UntypedFormBuilder,
		private approvalTemplateService: ApprovalTemplateService,
		private notificationService: NotificationService,
		private componentDialogRef: MatDialogRef<
			ChooseApprovalTemplateComponent,
			ChooseApprovalTemplateComponentResult
		>
	) {}

	ngOnInit(): void {
		this.selectedTemplateControl = this.formBuilder.control(null, Validators.required)
		this.loadApprovals()
	}

	loadApprovals() {
		this.approvalTemplateService
			.searchTemplates(true)
			.subscribe((searchResult) => this.fillTemplates(searchResult.results))
	}

	fillTemplates(templates: ApprovalTemplateBaseData[]) {
		this.templates = templates
	}

	onSelectionChanged(event: ItemSelectionEvent) {
		const line = event.line
		if (line.selected) {
			this.selectedTemplateControl.setValue(line.data)
		} else if (line.data === this.selectedTemplateControl.value) {
			this.selectedTemplateControl.setValue(null)
		}
	}

	clickOk() {
		if (this.selectedTemplateControl.valid) {
			this.approvalTemplateService.getTemplate(this.selectedTemplateControl.value.id).subscribe({
				next: (template) =>
					this.componentDialogRef.close({
						selectedTemplate: template,
					} as ChooseApprovalTemplateComponentResult),
				error: (err) =>
					this.componentDialogRef.close({ err } as ChooseApprovalTemplateComponentResult),
			})
		} else {
			this.notificationService.showError({ code: AppUiErrorCode.NO_TEMPLATE_SELECTED })
			this.componentDialogRef.close()
		}
	}
}
