<div data-qa='approve-reject-asset-data' class='border border-primary d-flex flex-column'
     (click)='openDetails(manager, approval, asset)'
     [class.cursor-disabled]='approval?.status === FilterStateStatus.APPROVED
     || approval?.status === FilterStateStatus.REJECTED
     || approval?.status === FilterStateStatus.FINISHED
     || approval?.status === FilterStateStatus.CLOSED'>
  <div data-qa='approve-asset-thumbnail' *ngIf='thumbnailUrl' class='can-background-image' [style.background-image]="'url(' + thumbnailUrl + ')'"
       [class.cursor-pointer]='manager === ManagerEnum.Approver'>
    <div class='can-rating-icon bg-primary d-inline-flex'>
      <div class='d-flex flex-1 overflow-hidden-100p flex-center'
           [ngClass]="'status-'+ asset?.newestActivity?.rating?.toLowerCase()">
        <mat-icon data-qa='approve-status-approved' *ngIf='asset?.newestActivity?.rating === FilterStateStatus.APPROVED'>thumb_up</mat-icon>
        <mat-icon data-qa='approve-status-rejected' *ngIf='asset?.newestActivity?.rating === FilterStateStatus.REJECTED'>thumb_down</mat-icon>
      </div>
    </div>
  </div>
</div>
