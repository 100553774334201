import { Injectable } from '@angular/core'
import { EnumAssetScheme } from 'core/models'
import { AssetStatus, ConsentAsset, StatusItem, WorkflowConfig } from 'core/models/consents'

@Injectable({
	providedIn: 'root',
})
export class ConsentInteractionService {
	workflowConfig!: WorkflowConfig

	getAssetStatusListItems(asset: ConsentAsset): StatusItem[] {
		const assetStatusList: StatusItem[] = []

		asset.status.forEach((value) => {
			const result = this.getStatus(value)
			if (result) {
				assetStatusList.push(result)
			}
		})

		return assetStatusList
	}

	getStatusListFromAsset(scheme: EnumAssetScheme, contentId: string): string[] {
		return (
			this.workflowConfig.containedAssets.find(
				(asset) => asset.scheme === scheme && asset.id === contentId
			)?.status || []
		)
	}

	updateStatusListFromAsset(
		scheme: EnumAssetScheme,
		contentId: string,
		newStatusList: AssetStatus
	) {
		const assetIndex = this.getAssetIndex(scheme, contentId)
		this.workflowConfig.containedAssets[assetIndex].status = newStatusList
	}

	getAssetIndex(scheme: EnumAssetScheme, contentId: string): number {
		return this.workflowConfig.containedAssets.findIndex(
			(asset) => asset.scheme === scheme && asset.id === contentId
		)
	}

	getStatus(status: string): StatusItem | undefined {
		const foundIcon = this.workflowConfig.configuration.statusList.find(
			(value) => value.id === status
		)
		if (!foundIcon) {
			console.error('Status {{status}} not found in Configuration'.format({ status }))
		}
		return foundIcon || undefined
	}

	getCountOfRatedAssets(): number {
		return this.workflowConfig.containedAssets.filter((value) => value.status.length > 0).length
	}
}
