import { HttpBackend } from '@angular/common/http'
import { LOCALE_ID, NgModule } from '@angular/core'
import {
	MissingTranslationHandler,
	TranslateCompiler,
	TranslateLoader,
	TranslateModule,
} from '@ngx-translate/core'
import { ApprovalRequestOverviewModule } from 'core/components/approval-request-overview'
import { ApprovalRequestsViewModule } from 'core/components/approval-request-overview/components'
import { ConsentDetailsOverviewModule } from 'core/components/consent/consent-details-overview/consent-details-overview.module'
import { ConsentListOverviewModule } from 'core/components/consent/consent-list-overview/consent-list-overview.module'
import { CreateNewApprovalViewModule } from 'core/components/create-new-approval-view/create-new-approval-view.module'
import { DetailsViewModule } from 'core/components/details-view/details-view.module'
import { ManageReviewApprovalViewModule } from 'core/components/manage-review-approval-view'
import { HeaderComponent } from 'core/header'
import { I18nMissingTranslationHandler, TranslateLoaderFactory } from 'core/services'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { LogoModule } from 'shared/components/logo'

@NgModule({
	exports: [HeaderComponent],
	imports: [
		LogoModule,
		ManageReviewApprovalViewModule,
		DetailsViewModule,
		CreateNewApprovalViewModule,
		TranslateModule.forRoot({
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: I18nMissingTranslationHandler,
			},
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler,
			},
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateLoaderFactory,
				deps: [HttpBackend],
			},
			useDefaultLang: true,
		}),
		ApprovalRequestOverviewModule,
		ApprovalRequestsViewModule,
		ConsentListOverviewModule,
		ConsentDetailsOverviewModule,
	],
	providers: [
		{ provide: Window, useValue: window },
		{ provide: LOCALE_ID, useValue: 'en' },
	],
	declarations: [HeaderComponent],
})
export class CoreModule {}
