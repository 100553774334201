import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NotificationConfig } from 'core/models/notification'
import { NotificationComponent } from './notification.component'

@NgModule({
	declarations: [NotificationComponent],
	providers: [{ provide: NotificationConfig }],
	imports: [CommonModule],
})
export class NotificationModule {}
