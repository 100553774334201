import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { LoadingSpinnerModule } from 'shared/components/loading-spinner'
import { DetailsSidebarModule } from './components/details-sidebar/details-sidebar.module'
import { ImageNavigationModule } from './components/image-navigation/image-navigation.module'
import { DetailsViewComponent } from './details-view.component'

@NgModule({
	declarations: [DetailsViewComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		ImageNavigationModule,
		DetailsSidebarModule,
		MatButtonModule,
		MatIconModule,
		MatDividerModule,
		TranslateModule,
		RouterModule,
	],
	exports: [],
})
export class DetailsViewModule {}
