<div class='d-flex flex-column overflow-hidden-100p'>
  <ng-container>
    <ng-container>
      <header class='header-sub-view'>
        <section class='d-flex flex-row ms-2 p-2 ps-0'>
          <div>
            <ng-container *ngIf='manager === ManagerEnum.Requester'>
              <button mat-button
                      (click)='clickNewApproval()'
                      color='primary'
                      class='active btn-rounded shadow-none'
                      data-qa='new-approval-button'>
                {{'approvalAsset.newButton' | translate}}
              </button>
            </ng-container>
          </div>
          <div class='m-0 d-flex flex-fill justify-content-center align-items-center mat-h3'>
            <div class='element-position-absolute-center' *ngIf='(loading$ | async) === false && approvalList.results'>
              <span data-qa='approver-approval-count-label'
                    *ngIf='manager === ManagerEnum.Approver'>{{'approvalAsset.titleHeaderApprover' | translate: { count: approvalList.results?.length } }}</span>
              <span data-qa='requester-approval-count-label'
                    *ngIf='manager === ManagerEnum.Requester'>{{'approvalAsset.titleHeaderRequester' | translate: { count: approvalList.results?.length } }}</span>
            </div>
          </div>
          <div class='d-flex justify-content-end'></div>
        </section>
        <mat-divider class='pb-2'></mat-divider>
      </header>
    </ng-container>
    <ng-container>
      <div class='overflow-hidden-100p'>
        <section class=''>
          <ng-container *ngIf='loading$ | async'>
            <app-loading-spinner class='primary-canto'></app-loading-spinner>
          </ng-container>
        </section>
        <section data-qa='approval-request-grid-section'
                 class='height-100p overflow-auto d-flex flex-row flex-wrap align-content-start'
                 *ngIf='!!approvalList.results'>
          <ng-container *ngFor='let asset of approvalList.results;let i = index'>
            <app-approval-asset data-qa='app-approval-asset' [approvalAsset]='asset'
                                [manager]='manager'></app-approval-asset>
          </ng-container>
        </section>
      </div>
    </ng-container>
  </ng-container>
</div>
