import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UpdateStatusResponse, WorkflowConfig } from 'core/models/consents'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ConsentService {
	observeGetConfiguration$!: Subject<void>

	constructor(private http: HttpClient, private backendUrlService: BackendUrlService) {}

	getConfiguration(workflowId: string): Observable<WorkflowConfig> {
		if (!!this.observeGetConfiguration$ && !this.observeGetConfiguration$.closed) {
			this.observeGetConfiguration$.next() // trigger the unsubscribe
			this.observeGetConfiguration$.complete() // finalize & clean up the subject stream
		}

		this.observeGetConfiguration$ = new Subject<void>()

		const endpointUrl = this.backendUrlService.createEndpointUrl(
			ApiEndpoint.workflowConfiguration,
			{
				workflowId,
			}
		)

		return this.http.get<WorkflowConfig>(endpointUrl).pipe(takeUntil(this.observeGetConfiguration$))
	}

	updateStatus(
		workflowId: string,
		scheme: string,
		contentId: string,
		statusId?: string
	): Observable<UpdateStatusResponse> {
		let endpointUrl

		if (statusId) {
			endpointUrl = this.backendUrlService.createEndpointUrl(ApiEndpoint.updateConsentStatus, {
				workflowId,
				scheme,
				contentId,
				statusId,
			})
		} else {
			endpointUrl = this.backendUrlService.createEndpointUrl(ApiEndpoint.removeConsentStatus, {
				workflowId,
				scheme,
				contentId,
			})
		}

		return this.http.put<UpdateStatusResponse>(endpointUrl, null).pipe(
			map((value) => {
				Object.assign(value, { scheme, contentId })
				return value
			})
		)
	}
}
