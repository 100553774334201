import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import {
	CommentDialogDataModel,
	CommentViewDialogComponent,
} from 'core/components/manage-review-approval-view/components/comment-view-dialog/comment-view-dialog.component'
import {
	FinishDialogDataModel,
	FinishViewDialogComponent,
} from 'core/components/manage-review-approval-view/components/finish-view-dialog'
import { indicate } from 'core/http'
import {
	ApprovalAssetExtendModel,
	ApprovalStatusResponse,
	AssetActivities,
	CurrentStepModel,
	EnumAppEndpoint,
	EnumFilterStateStatus,
	EnumManager,
	RatingStepStatusResponse,
} from 'core/models'

import { RatingService } from 'core/services'
import { ApprovalInteractionService } from 'core/services/approval-interaction.service'
import { ChangeApprovalStatusService } from 'core/services/change-approval-status.service'
import { ResetService } from 'core/services/reset.service'
import { SendReminderService } from 'core/services/send-reminder.service'
import { Subject } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'
import {
	ConfirmDialogComponent,
	ConfirmDialogDataModel,
} from 'shared/components/confirm-dialog/confirm-dialog.component'
import { deepClone } from 'shared/prototypings'
import { ReloadRouterService } from 'shared/services/reload-router.service'

export interface FinishStepDialogResultModel {
	activity: AssetActivities
	status: EnumFilterStateStatus
}

@Component({
	selector: 'app-step-navigation[approvalAsset][manager]',
	templateUrl: './step-navigation.component.html',
	styleUrls: ['./step-navigation.component.scss'],
})
export class StepNavigationComponent
	extends ApprovalBaseComponent
	implements OnChanges, AfterViewInit, OnDestroy
{
	@Input() approvalAsset!: ApprovalAssetExtendModel
	@Input() manager!: EnumManager

	sendRemindTimeout = 3000

	subscriptionRating: Subscription
	loadingStepFinishStatus$ = new Subject<boolean>()
	sendingReminder$ = new Subject<boolean>()
	changingApprovalStatus$ = new Subject<boolean>()
	resetStep$ = new Subject<boolean>()

	finishButtonDisabled = true
	allAssetsHaveRatings = false

	@ViewChild('StepNavigationContainer') scrollingContainerElementRef!: ElementRef
	@ViewChildren('StepNavigationItem') stepItemsElementRef!: QueryList<ElementRef>

	constructor(
		private router: Router,
		private reloadRouter: ReloadRouterService,
		private translate: TranslateService,
		private interaction: ApprovalInteractionService,
		private dialog: MatDialog,
		private ratingService: RatingService,
		private resetService: ResetService,
		private sendReminderService: SendReminderService,
		private changeApprovalStatusService: ChangeApprovalStatusService
	) {
		super()
		this.subscriptionRating = this.interaction.ratingSubject$.subscribe((rate: boolean) => {
			this.finishButtonDisabled = !rate
			this.allAssetsHaveRatings = rate
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.approvalAsset.currentValue) {
			this.calculateEndOfApprovalStep()
		}
	}

	ngAfterViewInit() {
		this.scrollToCurrentStep()
	}

	calculateEndOfApprovalStep() {
		/**
		 * Handle Approved or Closed Approvals
		 * UI needs a fake step to display the last currentStep in DOM
		 * we add a new step, it is a clone from current step (but it is the last step too)
		 */
		if (
			this.approvalAsset.status === EnumFilterStateStatus.APPROVED ||
			this.approvalAsset.status === EnumFilterStateStatus.CLOSED
		) {
			this.approvalAsset.steps.push(deepClone(this.approvalAsset.currentStep.step))
			this.approvalAsset.isApprovalEnds = true
			this.approvalAsset.stepIndex++
		}
	}

	ngOnDestroy(): void {
		this.subscriptionRating.unsubscribe()
	}

	private scrollToCurrentStep() {
		const currentStepElement = this.stepItemsElementRef.get(this.approvalAsset?.stepIndex)
		if (currentStepElement) {
			currentStepElement.nativeElement.scrollIntoView()
		}
	}

	onClickViewComments(currentStepIndex: number) {
		const commentDialog = this.dialog.open(CommentViewDialogComponent, {
			hasBackdrop: true,
			panelClass: ['comment-dialog'],
			data: {
				approvalId: this.approvalAsset.id,
				containedAssets: this.approvalAsset.containedAssets,
				currentStep: {
					rating:
						currentStepIndex < this.approvalAsset.stepIndex
							? EnumFilterStateStatus.APPROVED
							: this.approvalAsset.status,
					step: this.approvalAsset.steps[currentStepIndex],
				} as CurrentStepModel,
			} as CommentDialogDataModel,
		})
		commentDialog.afterClosed().subscribe((_) => {
			// currently we don't need this
		})
	}

	onClickFinish() {
		this.ratingService
			.getStepStatus(this.approvalAsset.id, this.approvalAsset.stepId)
			.pipe(indicate(this.loadingStepFinishStatus$))
			.subscribe((value: RatingStepStatusResponse) => {
				const finishDialog = this.dialog.open(FinishViewDialogComponent, {
					hasBackdrop: true,
					panelClass: ['finish-dialog'],
					data: {
						approvalId: this.approvalAsset.id,
						statusResponse: value,
					} as FinishDialogDataModel,
				})
				finishDialog.afterClosed().subscribe((result: FinishStepDialogResultModel) => {
					if (result) {
						this.updateApprovalAfterFinishedStep(result.activity, result.status)
					}
				})
			})
	}

	/**
	 * Update Approval Object to fake the newest currentStep activity when step finished
	 */
	updateApprovalAfterFinishedStep(activity: AssetActivities, status: EnumFilterStateStatus) {
		if (this.approvalAsset) {
			this.approvalAsset.status = activity?.stepStatus

			Object.assign(this.approvalAsset.currentStep, {
				rating: status,
			})
		}
	}

	onSendReminder() {
		this.sendReminderService
			.sendReminder(this.approvalAsset.id)
			.pipe(indicate(this.sendingReminder$, this.sendRemindTimeout))
			.subscribe((_) => {})
	}

	onChangeApprovalStatus(status: EnumFilterStateStatus, callback?: () => void) {
		this.changeApprovalStatusService
			.changeStatus(this.approvalAsset.id, status)
			.pipe(indicate(this.changingApprovalStatus$))
			.subscribe((newStatus: ApprovalStatusResponse) => {
				this.approvalAsset.status = newStatus.status
				if (callback) {
					callback.apply(this)
				}
			})
	}

	closeView(): void {
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigate([EnumAppEndpoint.Requester])
	}

	openResetStepDialog(stepId: string) {
		const resetDialog = this.dialog.open(ConfirmDialogComponent, {
			hasBackdrop: true,
			panelClass: ['reset-dialog'],
			disableClose: true,
			data: {
				title: this.translate.instant('stepNavigation.resetStep.dialogTitle'),
				contentText: this.translate.instant('stepNavigation.resetStep.dialogMessage'),
			} as ConfirmDialogDataModel,
		})
		resetDialog.componentInstance.loadingIndicator$ = this.resetStep$

		resetDialog.componentInstance.ok.subscribe(() => this.resetStep(resetDialog, stepId))
	}

	resetStep(dialog: MatDialogRef<ConfirmDialogComponent>, stepId: string) {
		dialog.componentInstance.componentDialogRef.addPanelClass('cursor-disabled')
		this.resetService
			.resetStep(this.approvalAsset.id, stepId)
			.pipe(indicate(this.resetStep$))
			.subscribe({
				next: () => {
					dialog.close()
					// noinspection JSIgnoredPromiseFromCall
					this.reloadRouter.navigateSelf()
				},
				error: () => {
					dialog.componentInstance.componentDialogRef.removePanelClass('cursor-disabled')
				},
			})
	}
}
