import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import { ConsentAsset, StatusItem } from 'core/models/consents'
import { APP_FALLBACK_IMAGE, ImageUtilsService } from 'core/services'
import { ConsentInteractionService } from 'core/services/consent-interaction.service'
import { Subscription } from 'rxjs/internal/Subscription'

const DEFAULT_THUMBNAIL_SIZE = 150

@Component({
	selector: 'app-consent-list-asset[asset]',
	templateUrl: './consent-list-asset.component.html',
	styleUrls: ['./consent-list-asset.component.scss'],
})
export class ConsentListAssetComponent implements OnInit, OnDestroy {
	@Input() asset!: ConsentAsset
	thumbnailUrl!: string
	assetStatusList: StatusItem[] = []
	loadImage$!: Subscription

	constructor(
		private imageUtilsService: ImageUtilsService,
		private consentInteractionService: ConsentInteractionService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.constructAsset()
	}

	ngOnDestroy(): void {
		if (this.loadImage$) {
			this.loadImage$.unsubscribe()
		}
	}

	get workflowConfig() {
		return this.consentInteractionService.workflowConfig
	}

	constructAsset() {
		if (this.asset) {
			this.assetStatusList = this.consentInteractionService.getAssetStatusListItems(this.asset)

			const imgUrl = this.imageUtilsService.computeImage(
				this.asset.imageUrl,
				DEFAULT_THUMBNAIL_SIZE
			)

			this.loadImage$ = this.imageUtilsService.loadImage(imgUrl).subscribe({
				next: (value) => {
					this.thumbnailUrl = value.src
				},
				error: (_) => (this.thumbnailUrl = APP_FALLBACK_IMAGE),
			})
		}
	}

	openDetails() {
		void this.router.navigate([
			EnumAppEndpoint.Consent,
			EnumManager.Approver,
			this.workflowConfig.configuration.id,
			EnumAppEndpoint.Details,
			this.asset.scheme,
			this.asset.id,
		])
	}
}
