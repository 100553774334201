import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ComponentBootstrapComponent } from './component-bootstrap.component'

const routes: Routes = [
	{
		path: 'bootstrap',
		component: ComponentBootstrapComponent,
		resolve: {},
	},
]

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ComponentBootstrapRoutingModule {}
