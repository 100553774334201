<div mat-dialog-title class='d-flex flex-row'>
  <h3 class='flex-1'>
    <span>{{'modifyApprovalStepUser.titleHeader' | translate}}</span>
  </h3>
  <div class='col-2 d-flex justify-content-end'>
    <button mat-mini-fab
            [mat-dialog-close]
            color='primary'
            class='shadow-none'>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content [formGroup]='formGroupState'
                    #formDirective='ngForm' class='pb-3 overflow-hidden-100p'>
  <section class='d-flex p-3 border border-primary rounded-3'>
    <section class='d-flex flex-1 flex-row flex-nowrap'>
      <div
        class='flex-1 overflow-hidden'>
        <article data-qa='list-all-users' class='overflow-hidden-100p'>
          <div class='d-flex flex-column overflow-hidden-100p'>
            <div>
              <mat-form-field #searchField class='d-block mat-form-field-small icon-prefix field-rounded' appearance='outline'>
                <mat-icon matPrefix>search</mat-icon>
                <input data-qa='user-search-field' matInput
                       autocomplete='off'
                       [(ngModel)]='searchQuery'
                       [ngModelOptions]='{standalone: true}'
                       type='text'>
              </mat-form-field>
            </div>
            <ng-container *ngIf='loadingUsers$ | async'>
              <app-loading-spinner class='primary-canto'></app-loading-spinner>
            </ng-container>
            <div class='overflow-auto height-100p'>
              <div [ngClass]="{'list-disabled': approversList.length>0}"
                   [class.cursor-disabled]='approversList.length>0'>
                <!--suppress TypeScriptUnresolvedVariable -->
                <div [attr.aria-disabled]='user.isAssigned'
                     [ngClass]="{'disabled opacity-4': user.isAssigned}"
                     *ngFor="let user of allUsers | search: 'firstName,lastName':searchQuery:'startsWith'; let index = index">
                  <app-user [user]='user' (addClick)='assignUser(user)'></app-user>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class='border border-primary border-0 border-start ms-3 me-3'></div>
      <div class='flex-1 overflow-hidden'>
        <article data-qa='list-assigned-users'>
          <div class='d-flex flex-column'>
            <div [style.height.px]='searchFieldHeight' class='d-flex flex-column'></div>
            <div *ngFor='let user of approversList; let index = index'>
              <app-user [user]='user' (removeClick)='removeAssigned(user, index)'></app-user>
            </div>
          </div>
        </article>
      </div>
    </section>
  </section>
</mat-dialog-content>

<mat-dialog-actions class='justify-content-end'>
  <button data-qa='modify-approval-user-cancel-button' mat-button [mat-dialog-close]
          class='active btn-rounded'>{{'modifyApprovalStepUser.buttonLabelCancel' | translate}}</button>
  <button data-qa='modify-approval-user-ok-button' mat-button (click)='save()' [disabled]='formDirective.invalid'
          class='active btn-rounded'>{{'modifyApprovalStepUser.buttonLabelSave' | translate}}</button>
</mat-dialog-actions>
