import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { EnumFilterStateStatus } from 'core/models'

@Component({
	selector: 'app-thumb-rating[status]',
	templateUrl: './thumb-rating.component.html',
	styleUrls: ['./thumb-rating.component.scss'],
})
export class ThumbRatingComponent implements OnChanges {
	@Input() status!: EnumFilterStateStatus.APPROVED | EnumFilterStateStatus.REJECTED

	matIcon!: string

	ngOnChanges(changes: SimpleChanges): void {
		switch (changes.status.currentValue) {
			case EnumFilterStateStatus.APPROVED:
				this.matIcon = 'thumb_up'
				break
			case EnumFilterStateStatus.REJECTED:
				this.matIcon = 'thumb_down'
				break
		}
	}
}
