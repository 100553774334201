<div mat-dialog-title class='d-flex flex-row'>
  <h3 class='flex-1'>
    <span>{{'chooseApprovalTemplate.titleHeader' | translate}}</span>
  </h3>
  <div class='col-2 d-flex justify-content-end'>
    <button mat-mini-fab
            [mat-dialog-close]
            color='primary'
            class='shadow-none'>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div data-qa='select-approval-template-list'>
    <app-select-list [data]='templates' (itemSelected)='onSelectionChanged($event)'>
      <ng-template #entryTemplate let-line>
        <div>{{line.data.name}}</div>
      </ng-template>
    </app-select-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions class='justify-content-end align-content-end'>
  <div>
    <button mat-button data-qa='choose-template-cancel-button' [mat-dialog-close]
            class='active btn-rounded'>{{'chooseApprovalTemplate.buttonLabelCancel' | translate}}</button>
    <button mat-button data-qa='choose-template-ok-button' (click)='clickOk()'
            [disabled]='selectedTemplateControl.invalid'
            class='active btn-rounded'>{{'chooseApprovalTemplate.buttonLabelSave' | translate}}</button>
  </div>
</mat-dialog-actions>
