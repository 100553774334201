<div class='select-list'>
  <div class='select-list-line' *ngFor='let formControl of linesForms?.controls; let index = index'>
    <div class='entry' (click)='clickedLine(formControl, index)'>
      <ng-container
        [ngTemplateOutlet]='lineTemplateRef || defaultLineTemplate'
        [ngTemplateOutletContext]='{ $implicit: formControl.value} '>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #defaultLineTemplate let-line>
  <mat-icon *ngIf='line.selected === true; else notSelected'>{{checkedIcon}}</mat-icon>
  <ng-container
    [ngTemplateOutlet]='entryTemplateRef || defaultEntryTemplate'
    [ngTemplateOutletContext]='{ $implicit: line} '>
  </ng-container>
</ng-template>

<ng-template #notSelected>
  <mat-icon>{{uncheckedIcon}}</mat-icon>
</ng-template>

<ng-template #defaultEntryTemplate let-line>
  <div class='entry-text'>{{line.data}}</div>
</ng-template>
