import { Component, OnInit } from '@angular/core'
import { ConsentInteractionService } from 'core/services/consent-interaction.service'

@Component({
	selector: 'app-consent-list-view',
	templateUrl: './consent-list-view.component.html',
	styleUrls: ['./consent-list-view.component.scss'],
})
export class ConsentListViewComponent implements OnInit {
	assetsRatedCount!: number

	constructor(private consentInteractionService: ConsentInteractionService) {}

	ngOnInit(): void {
		this.assetsRatedCount = this.consentInteractionService.getCountOfRatedAssets()
	}

	get workflowConfig() {
		return this.consentInteractionService.workflowConfig
	}

	get assets() {
		return this.workflowConfig.containedAssets
	}
}
