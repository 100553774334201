import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { apiEndpointUrl } from 'core/http/environment-api'
import { HttpUrlEncodingCodec } from 'core/http/http-encoding-codec'
import { ApprovalModel, EnumFilterStateStatus, EnumManager } from 'core/models'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ApprovalService {
	observeSearchSubject$ = new Subject<void>()

	constructor(private http: HttpClient) {}

	/**
	 * To search for approvals
	 * @param manager an approver or requester
	 * @param status status to filter
	 * @param isMock (optional) default false, when true they using the environment.api.mockUrl
	 */
	search(
		manager: EnumManager,
		status: EnumFilterStateStatus | any,
		isMock: boolean = false
	): Observable<ApprovalModel> {
		this.observeSearchSubject$.next() // trigger the unsubscribe
		this.observeSearchSubject$.complete() // finalize & clean up the subject stream

		const endpointUrl = '{apiUrl}/{endpoint}'.format({
			apiUrl: apiEndpointUrl(isMock),
			endpoint: ApiEndpoint.approval,
		})

		let params = new HttpParams({
			fromObject: { role: manager },
			encoder: new HttpUrlEncodingCodec(),
		})

		if (status != EnumFilterStateStatus.ALL) {
			params = params.set('status', status)
		}

		const headers = new HttpHeaders()

		return this.http
			.get<ApprovalModel>(endpointUrl, {
				headers,
				params,
			})
			.pipe(
				takeUntil(this.observeSearchSubject$)
				// map(x => x) // we not currently need a map here
			)
	}
}
