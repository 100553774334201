import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	ApprovalTemplate,
	ApprovalTemplatePersisted,
	ApprovalTemplateSearchResult,
} from 'core/models/approvalTemplate'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ApprovalTemplateService {
	constructor(private backendUrlService: BackendUrlService, private http: HttpClient) {}

	createTemplate(template: ApprovalTemplate): Observable<ApprovalTemplatePersisted> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.approvalTemplates)
		return this.http.put<ApprovalTemplatePersisted>(url, template)
	}

	searchTemplates(activeOnly: boolean): Observable<ApprovalTemplateSearchResult> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.approvalTemplates)
		const params = new HttpParams({ fromObject: { activeOnly } })
		return this.http.get<ApprovalTemplateSearchResult>(url, { params })
	}

	getTemplate(templateId: string): Observable<ApprovalTemplatePersisted> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.approvalTemplate, {
			templateId,
		})
		return this.http.get<ApprovalTemplatePersisted>(url)
	}

	deleteTemplate(templateId: string): Observable<void> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.approvalTemplate, {
			templateId,
		})
		return this.http.delete<void>(url)
	}
}
