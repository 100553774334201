import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { EnumNavigateDirectionImage } from 'core/models/navigationDirection'
import { ImageOrientation, ImageUtilsService } from 'core/services'

@Component({
	selector:
		'app-image-navigation[imageSrc][eventNavigate][currentActiveAssetIndex][numberOfAssets]',
	templateUrl: './image-navigation.component.html',
	styleUrls: ['./image-navigation.component.scss'],
})
export class ImageNavigationComponent implements OnChanges {
	@Input() currentActiveAssetIndex!: number
	@Input() numberOfAssets!: number
	@Input() imageSrc!: string
	@Output() eventNavigate = new EventEmitter<EnumNavigateDirectionImage>()
	@ViewChild('imageElement') imageElement!: ElementRef

	private DEFAULT_DETAILSIMAGE_SIZE = 1600

	detailImageUrl?: string
	imageOrientation: ImageOrientation = ImageOrientation.LANDSCAPE

	constructor(private imageUtilsService: ImageUtilsService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.imageSrc?.currentValue) {
			this.detailImageUrl = this.imageUtilsService.computeImage(
				changes.imageSrc.currentValue,
				this.DEFAULT_DETAILSIMAGE_SIZE
			)
		} else {
			this.detailImageUrl = void 0
		}
	}

	get NavigateDirectionImage() {
		return EnumNavigateDirectionImage
	}

	onLoadImage() {
		this.imageOrientation = this.imageUtilsService.getImageOrientation(this.imageElement)
	}

	navigate(navDirectionImage: EnumNavigateDirectionImage) {
		this.eventNavigate.emit(navDirectionImage)
	}
}
