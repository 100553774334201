import { Pipe, PipeTransform } from '@angular/core'
import { UserService } from 'core/services/user.service'

@Pipe({
	name: 'userFullname',
})
export class UserFullnamePipe implements PipeTransform {
	constructor(private userService: UserService) {}

	transform(userId: string): string {
		const foundUser = this.userService.usersInformation
			.filter((users) => {
				return users.userId === userId
			})
			.shift()
		let userFullName
		if (
			foundUser !== undefined &&
			(foundUser as any).hasOwnProperty('firstName') &&
			(foundUser as any).hasOwnProperty('lastName')
		) {
			userFullName = foundUser.firstName + ' ' + foundUser.lastName
		}

		return userFullName || userId
	}
}
