import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { EnumFilterStateStatus, EnumManager } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { StateFilterService } from 'core/services'
import { UserRoleGuard } from 'core/services/user-role.guard'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'

@Component({
	selector: 'app-filter-nav-state[manager][eventChangeManager][eventChangeStatus]',
	templateUrl: './filter-nav-state.component.html',
	styleUrls: ['./filter-nav-state.component.scss'],
})
export class FilterNavStateComponent extends ApprovalBaseComponent implements OnChanges {
	@Input() manager!: EnumManager
	@Output() eventChangeManager = new EventEmitter<EnumManager>()
	@Output() eventChangeStatus = new EventEmitter<EnumFilterStateStatus>()

	activeStateIndex!: number
	stateFilterList!: Array<any>

	isPermissionRequester = false

	constructor(
		private stateFilterService: StateFilterService,
		private userRoleGuard: UserRoleGuard
	) {
		super()
		this.isPermissionRequester = this.userRoleGuard.hasRole(APP_PERMISSION.request)
		this.activeStateIndex = 0
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.manager && changes.manager.currentValue) {
			this.getFilterList()
		}
	}

	getFilterList() {
		this.stateFilterService.getStatusList(this.manager).subscribe({
			next: (data) => {
				this.stateFilterList = data
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	search(state: EnumFilterStateStatus, index: number) {
		this.activeStateIndex = index
		this.eventChangeStatus.emit(state)
	}

	changeManager(manager: EnumManager) {
		this.eventChangeManager.emit(manager)
	}
}
