import { Injectable } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { Title } from '@angular/platform-browser'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { UserStoreService } from 'core/services/user-store.service'
import * as moment from 'moment-timezone'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class PlatformBrowserService {
	translationChangedSubject$ = new BehaviorSubject<string>(moment.locale())

	// Provider Module in app.module.ts
	constructor(
		private dateAdapter: DateAdapter<any>,
		private browserTitle: Title,
		private translate: TranslateService,
		private userStoreService: UserStoreService
	) {
		this.userStoreService.userLanguage = this.translate.getBrowserLang()
	}

	init() {
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.userStoreService.userLanguage = event.lang
			// Change moment to current locale
			moment.locale(event.lang)
			// Change Mat-Datepicker locale
			this.dateAdapter.setLocale(moment.locale())

			// Change page title when user changes language preference
			this.setBrowserTitle()
			this.translationChangedSubject$.next(moment.locale())
		})
	}

	setBrowserTitle() {
		this.translate.get('app.title').subscribe((newTitle) => {
			this.browserTitle.setTitle(newTitle)
		})
	}
}
