import { Injectable, NgZone } from '@angular/core'
import { UiError } from 'core/services/error/ui-error'
import { NotificationService } from 'core/services/notification.service'

@Injectable({
	providedIn: 'root',
})
export class DisplayErrorService {
	constructor(private zone: NgZone, private notification: NotificationService) {}

	show(error: any) {
		// handle different errors
		this.zone.run(() => {
			if (this.isApiErrorResponse(error)) {
				this.notification.showError(error.error)
			} else if (this.isUiError(error)) {
				this.notification.showError({ code: error.uiCode })
			} else {
				this.notification.showError({ message: error.message ? error.message : error.toString() })
			}
		})
	}

	isApiErrorResponse(err: any): boolean {
		if (err && err.error) {
			return err.error.code !== undefined && err.error.message !== undefined
		}
		return false
	}

	isUiError(err: any): boolean {
		return (!!err && err.uiCode && err instanceof UiError) || false
	}
}
