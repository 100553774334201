import { Component } from '@angular/core'
import { EnumFilterStateStatus, EnumManager } from 'core/models'

@Component({
	template: '',
	styleUrls: ['./approval-base.component.scss'],
})
export abstract class ApprovalBaseComponent {
	get ManagerEnum() {
		return EnumManager
	}

	get FilterStateStatus() {
		return EnumFilterStateStatus
	}
}
