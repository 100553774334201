import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ConsentListOverviewComponent } from 'core/components/consent'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { RouteSchemeData } from 'core/models/routeScheme'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { UserRoleGuard } from 'core/services/user-role.guard'

export const routesConsentListOverview: Routes = [
	{
		path: EnumAppEndpoint.Consent + '/' + EnumManager.Approver + '/:workflowId',
		component: ConsentListOverviewComponent,
		data: {
			roles: [APP_PERMISSION.approve],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
]

@NgModule({
	imports: [RouterModule.forChild(routesConsentListOverview)],
	exports: [RouterModule],
})
export class ConsentListOverviewRoutingModule {}
