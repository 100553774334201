export enum EnumManager {
	Approver = 'approver',
	Requester = 'requester',
}

export enum EnumAppEndpoint {
	Approver = 'approvals',
	Consent = 'consent',
	Requester = 'requests',
	ManageApproval = 'approval',
	Details = 'details',
	NewApproval = 'newapproval',
	Error = 'error',
}
