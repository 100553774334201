import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DetailsViewComponent } from 'core/components/details-view/details-view.component'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { RouteSchemeData } from 'core/models/routeScheme'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { UserRoleGuard } from 'core/services/user-role.guard'

export const routes: Routes = [
	{
		path:
			EnumAppEndpoint.ManageApproval +
			'/' +
			EnumManager.Approver +
			'/:approvalId/' +
			EnumAppEndpoint.Details +
			'/:scheme/:assetId',
		component: DetailsViewComponent,
		data: {
			manager: EnumManager.Approver,
			roles: [APP_PERMISSION.approve],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class DetailsViewRoutingModule {}
