import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from 'core/services/auth.service'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		/**
		 * when we need different intercepts for different apis,
		 * then create multiple httpClients for each api
		 * now we do a simple url match check
		 */
		const isApprovalApi = request.url.includes(environment.api.url)
		const isAuthenticatorApi = request.url.includes(environment.apiAuth.url)
		if (
			(isApprovalApi || isAuthenticatorApi) &&
			this.authService.isLoggedIn &&
			this.authService.token
		) {
			request = request.clone({
				setHeaders: {
					'X-jwt': this.authService.token,
				},
			})
		}
		return next.handle(request)
	}
}
