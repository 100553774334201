import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { indicate } from 'core/http'
import { EnumAppEndpoint, EnumAssetScheme, EnumManager } from 'core/models'
import { AssetStatus } from 'core/models/consents'
import { EnumNavigateDirectionImage } from 'core/models/navigationDirection'
import { HeaderStateService } from 'core/services'
import { ConsentInteractionService } from 'core/services/consent-interaction.service'
import { ConsentService } from 'core/services/consent.service'
import { AppUiErrorCode } from 'core/services/error/error-code.model'
import { UiError } from 'core/services/error/ui-error'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-consent-details-overview',
	templateUrl: './consent-details-overview.component.html',
	styleUrls: ['./consent-details-overview.component.scss'],
})
export class ConsentDetailsOverviewComponent implements OnInit {
	loading$ = new Subject<boolean>()
	currentActiveAssetIndex!: number

	workflowId!: string
	scheme!: EnumAssetScheme
	contentId!: string
	assetStatus!: AssetStatus

	constructor(
		private consentService: ConsentService,
		private route: ActivatedRoute,
		private router: Router,
		private headerStateService: HeaderStateService,
		private translateService: TranslateService,
		private consentInteractionService: ConsentInteractionService
	) {}

	ngOnInit(): void {
		this.headerStateService.setLogoClickLink()
		this.translateService.onLangChange.subscribe((_) => {
			this.changeHeaderTitle()
		})
		setTimeout(() => {
			this.onAssetChanged()
		})
	}

	onAssetChanged() {
		this.route.params.subscribe(() => {
			this.workflowId = this.route.snapshot.params.workflowId
			this.scheme = this.route.snapshot.params.scheme
			this.contentId = this.route.snapshot.params.contentId
			this.consentService
				.getConfiguration(this.workflowId)
				.pipe(indicate(this.loading$))
				.subscribe((workflowConfig) => {
					this.consentInteractionService.workflowConfig = workflowConfig
					this.currentActiveAssetIndex = this.consentInteractionService.getAssetIndex(
						this.scheme,
						this.contentId
					)
					if (this.currentActiveAssetIndex >= 0) {
						this.assetStatus = this.consentInteractionService.getStatusListFromAsset(
							this.scheme,
							this.contentId
						)
						this.changeHeaderTitle()
					} else {
						this.handleErrorAndThrow(AppUiErrorCode.CONTENT_ID_NOT_FOUND_IN_CONSENT_WORKFLOW)
					}
				})
		})
	}

	handleErrorAndThrow(uiCode: AppUiErrorCode): never {
		this.onCloseView()
		throw new UiError(uiCode)
	}

	get workflowConfig() {
		return this.consentInteractionService.workflowConfig
	}

	onCloseView() {
		void this.router.navigate([
			EnumAppEndpoint.Consent,
			EnumManager.Approver,
			this.route.snapshot.params.workflowId,
		])
	}

	get EnumNavigateDirectionImage() {
		return EnumNavigateDirectionImage
	}

	onClickNavigate(navDirectionImage: EnumNavigateDirectionImage) {
		const lastIndex = this.currentActiveAssetIndex

		switch (navDirectionImage) {
			case EnumNavigateDirectionImage.RIGHT:
				this.currentActiveAssetIndex++
				break
			case EnumNavigateDirectionImage.LEFT:
				this.currentActiveAssetIndex--
				break
		}

		if (
			this.currentActiveAssetIndex >= 0 &&
			this.currentActiveAssetIndex < this.workflowConfig.containedAssets.length
		) {
			const nextAsset = this.workflowConfig.containedAssets[this.currentActiveAssetIndex]

			this.doNavigate(nextAsset.id)
		} else {
			this.currentActiveAssetIndex = lastIndex
		}
	}

	changeHeaderTitle() {
		if (this.workflowConfig) {
			this.headerStateService.setTitleLocalizedString(this.workflowConfig.configuration.displayName)
		}
	}

	doNavigate(nextAssetId: string) {
		void this.router.navigate([
			EnumAppEndpoint.Consent,
			EnumManager.Approver,
			this.route.snapshot.params.workflowId,
			EnumAppEndpoint.Details,
			this.route.snapshot.params.scheme,
			nextAssetId,
		])
	}
}
