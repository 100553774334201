import { HttpBackend } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader'

export function TranslateLoaderFactory(http: HttpBackend) {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n/', suffix: '.json' },
		// {prefix: './custom/i18n/', suffix: '.json'},
	])
}

@Injectable()
export class I18nMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		console.log('%cTranslation key not found:', 'color: orange', `${params.key}`)
		if (params.interpolateParams?.hasOwnProperty('default')) {
			const defaultTranslate = Object.getOwnPropertyDescriptor(params.interpolateParams, 'default')
			return defaultTranslate?.value
		}
		return `${params.key} *i18n*`
	}
}
