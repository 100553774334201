<div class='d-flex flex-column p-2'>
  <ng-container *ngIf='manager === undefined'>
    <p><i>Select your work:</i></p>
  </ng-container>

  <ng-container>
    <header class='header-sub-view'>
      <div class='pt-2'>
        <ng-container *ngIf='isPermissionRequester'>
          <!--suppress HtmlUnknownAttribute -->
          <div class='btn-group btn-rounded' color='primary' role='group'>
            <button mat-button
                    class='shadow-none'
                    [ngClass]="{'active': manager===ManagerEnum.Requester}"
                    (click)='changeManager(ManagerEnum.Requester)'
                    data-qa='requests-button'>{{'filterNavState.buttonRequests' | translate}}
            </button>
            <button mat-button
                    [ngClass]="{'active': manager===ManagerEnum.Approver}"
                    (click)='changeManager(ManagerEnum.Approver)'
                    data-qa='approvals-button'
                    class='shadow-none'>{{'filterNavState.buttonApprovals' | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf='!isPermissionRequester'>
          <div data-qa='approvals-label' class='mat-h2 m-0'>{{'filterNavState.labelApprovals' | translate}}</div>
        </ng-container>
      </div>
    </header>

    <div class='overflow-hidden-100p pt-4'>
      <div class='overflow-auto height-100p d-flex flex-grow-1 flex-column'>
        <ng-container *ngFor='let state of stateFilterList; let i = index;'>
          <span class='state-item'>
            <a mat-button
               role='listitem'
               (click)='search(state, i)'
               class='justify-content-start btn-rounded'
               [attr.data-qa]="'activeState-button-' + state"
               [ngClass]='{active: activeStateIndex === i}'
            >{{'filterStates.' + manager + '.' + state | translate}}</a>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>

</div>
