import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpUrlEncodingCodec } from 'core/http'
import { ActivityResponse } from 'core/models'
import { BackendUrlService } from 'core/services/backend-url.service'
import { Observable } from 'rxjs/internal/Observable'
import { ApiEndpoint } from '../../endpoints/endpoints'

@Injectable({
	providedIn: 'root',
})
export class ActivityService {
	constructor(private backendUrlService: BackendUrlService, private http: HttpClient) {}

	getActivities(
		approvalId: string,
		stepId: string,
		commentLimit: number = 1
	): Observable<ActivityResponse> {
		const url = this.backendUrlService.createEndpointUrl(ApiEndpoint.stepActivities, {
			approvalId,
			stepId,
		})
		let params: HttpParams
		params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
		params = params.set('commentLimit', commentLimit.toString())

		return this.http.get<ActivityResponse>(url, { params })
	}
}
