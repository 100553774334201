import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ApprovalProgressModule } from 'core/components/approval-request-overview/components/approval-progress/approval-progress.module'
import { MomentModule } from 'ngx-moment'
import { DynamicMomentPipeModule } from 'shared/pipes/dynamic-moment-pipe.module'
import { ApprovalAssetComponent } from './approval-asset.component'

@NgModule({
	declarations: [ApprovalAssetComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatCardModule,
		MomentModule,
		DynamicMomentPipeModule,
		ApprovalProgressModule,
		MatIconModule,
		TranslateModule,
	],
	exports: [ApprovalAssetComponent],
})
export class ApprovalAssetModule {}
