import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { LoadingSpinnerModule } from 'shared/components'
import { SearchPipeModule } from 'shared/pipes/search-pipe.module'
import { ModifyApprovalUserComponent } from './modify-approval-user.component'
import { UserComponent } from './user/user.component'

@NgModule({
	declarations: [ModifyApprovalUserComponent, UserComponent],
	imports: [
		CommonModule,
		TranslateModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		LoadingSpinnerModule,
		SearchPipeModule,
		FormsModule,
	],
	exports: [ModifyApprovalUserComponent],
})
export class ModifyApprovalUserModule {}
