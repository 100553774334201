import { Component, OnInit } from '@angular/core'
import { HeaderStateService } from 'core/services'

export const DEFAULT_HEADER_LOGO_LINK_PATH = '/'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	link: string | undefined = DEFAULT_HEADER_LOGO_LINK_PATH

	constructor(private headerStateService: HeaderStateService) {}

	ngOnInit(): void {
		this.headerStateService.logoLinkChanged.subscribe((link) => {
			this.link = link
		})
	}
}
