import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { FinishViewDialogModule } from 'core/components/manage-review-approval-view/components/finish-view-dialog/finish-view-dialog.module'
import { UserFullnamePipe } from 'core/pipes/user-fullname.pipe'
import { ConfirmDialogModule } from 'shared/components/confirm-dialog/confirm-dialog.module'
import { ThumbRatingModule } from 'shared/components/thumb-rating/thumb-rating.module'
import { DynamicMomentPipeModule } from 'shared/pipes/dynamic-moment-pipe.module'
import { StepNavigationComponent } from './step-navigation.component'

@NgModule({
	declarations: [StepNavigationComponent, UserFullnamePipe],
	imports: [
		CommonModule,
		FinishViewDialogModule,
		TranslateModule,
		DynamicMomentPipeModule,
		MatIconModule,
		MatFormFieldModule,
		MatButtonModule,
		MatExpansionModule,
		ThumbRatingModule,
		ConfirmDialogModule,
		MatDividerModule,
	],
	exports: [StepNavigationComponent],
})
export class StepNavigationModule {}
