import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { indicate } from 'core/http'
import { ApprovalAssetModel, EnumAppEndpoint, EnumManager } from 'core/models'
import { HeaderStateService, ManageReviewService } from 'core/services'
import { Subject } from 'rxjs'

@Component({
	selector: 'app-manage-review-approval-view',
	templateUrl: './manage-review-approval-view.component.html',
	styleUrls: ['./manage-review-approval-view.component.scss'],
})
export class ManageReviewApprovalViewComponent implements OnInit {
	loading$ = new Subject<boolean>()

	approvalAsset!: ApprovalAssetModel
	manager!: EnumManager

	constructor(
		private manageReviewService: ManageReviewService,
		private router: Router,
		private route: ActivatedRoute,
		private headerStateService: HeaderStateService
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.route.params.subscribe(() => {
				this.manager = this.route.snapshot.data.manager
				this.manageReviewService
					.getApproval(this.route.snapshot.params.id)
					.pipe(indicate(this.loading$))
					.subscribe({
						next: (result: ApprovalAssetModel) => {
							this.approvalAsset = result
							this.changeHeaderTitle()
						},
						error: (error) => {
							// TODO error implementation later, popup angular snackbar or what ever
							console.error(error)
						},
					})
			})
		})
	}

	changeHeaderTitle() {
		if (this.manager === EnumManager.Approver) {
			this.headerStateService.setTitleTranslation('stepNavigation.approver.headerTitle', {
				title: this.approvalAsset.name,
			})
		}
		if (this.manager === EnumManager.Requester) {
			this.headerStateService.setTitleTranslation('stepNavigation.requester.headerTitle', {
				title: this.approvalAsset.name,
			})
		}
	}

	onCloseView() {
		if (this.manager === EnumManager.Approver) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([EnumAppEndpoint.Approver])
		}
		if (this.manager === EnumManager.Requester) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([EnumAppEndpoint.Requester])
		}
	}
}
