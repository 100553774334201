import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ConsentListAssetComponent } from 'core/components/consent/consent-list-overview/components/consent-list-asset/consent-list-asset.component'
import { LocalizedStringPipeModule } from 'core/pipes/localized-string'

@NgModule({
	declarations: [ConsentListAssetComponent],
	imports: [CommonModule, MatIconModule, LocalizedStringPipeModule],
	exports: [ConsentListAssetComponent],
})
export class ConsentListAssetModule {}
