import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { ManageReviewApprovalViewComponent } from 'core/components/manage-review-approval-view/manage-review-approval-view.component'
import { LoadingSpinnerModule } from 'shared/components/loading-spinner'
import { AssetRequestsViewModule } from './components/asset-requests-view/asset-requests-view.module'
import { StepNavigationModule } from './components/step-navigation/step-navigation.module'

@NgModule({
	declarations: [ManageReviewApprovalViewComponent],
	imports: [
		CommonModule,
		StepNavigationModule,
		AssetRequestsViewModule,
		LoadingSpinnerModule,
		MatDividerModule,
	],
	exports: [ManageReviewApprovalViewComponent],
})
export class ManageReviewApprovalViewModule {}
