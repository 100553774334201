import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { EnumAppEndpoint, EnumManager } from 'core/models'
import {
	ApprovalAssetExtendModel,
	ApprovalAssetModel,
	AssetExtendModel,
	AssetModel,
} from 'core/models/approvals'
import { ApprovalInteractionService } from 'core/services/approval-interaction.service'
import { APP_FALLBACK_IMAGE, ImageUtilsService } from 'core/services/image-utils.service'
import { Subscription } from 'rxjs/internal/Subscription'
import { ApprovalBaseComponent } from 'shared/components/approval-base/approval-base.component'

@Component({
	selector: 'app-approve-reject-asset[asset][loaded][manager][approval]',
	templateUrl: './approve-reject-asset.component.html',
	styleUrls: ['./approve-reject-asset.component.scss'],
})
export class ApproveRejectAssetComponent
	extends ApprovalBaseComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	@Input() manager!: EnumManager
	@Input() approval!: ApprovalAssetExtendModel
	@Input() asset!: AssetExtendModel
	@Output() loaded = new EventEmitter()

	thumbnailUrl!: string

	loadImage$!: Subscription

	constructor(
		private interaction: ApprovalInteractionService,
		private router: Router,
		private imageUtilsService: ImageUtilsService
	) {
		super()
	}

	ngOnInit(): void {
		this.constructAsset()
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loaded.emit()
		})
	}

	ngOnDestroy(): void {
		if (this.loadImage$) {
			this.loadImage$.unsubscribe()
		}
	}

	constructAsset() {
		if (this.asset) {
			const imgUrl = this.imageUtilsService.computeImage(this.asset.imageUrl, 150)

			this.loadImage$ = this.imageUtilsService.loadImage(imgUrl).subscribe({
				next: (value) => {
					this.thumbnailUrl = value.src
				},
				error: (_) => (this.thumbnailUrl = APP_FALLBACK_IMAGE),
			})
		}
	}

	openDetails(manager: EnumManager, approval: ApprovalAssetModel, asset: AssetModel) {
		// only navigate to details view, if user is requester
		if (manager === EnumManager.Approver) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigate([
				EnumAppEndpoint.ManageApproval,
				manager,
				approval.id,
				EnumAppEndpoint.Details,
				asset.scheme,
				asset.id,
			])
		}
	}
}
