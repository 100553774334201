import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service'
import { LocalizedString } from 'core/pipes/localized-string'
import { UserStoreService } from 'core/services/user-store.service'
import { BehaviorSubject } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'

@Pipe({
	name: 'localizedHtmlString',
})
export class LocalizedHtmlStringPipe implements PipeTransform {
	constructor(
		private userStoreService: UserStoreService,
		private translateService: TranslateService,
		private sanitizer: DomSanitizer,
	) {}

	transform(prop: LocalizedString): Observable<SafeHtml> {
		const localizedValue$ = new BehaviorSubject<SafeHtml>(this.instant(prop))
		this.translateService.onLangChange.subscribe((_: LangChangeEvent) => {
			localizedValue$.next(this.instant(prop))
		})
		return localizedValue$
	}

	instant(prop: LocalizedString) {
		return this.sanitize(prop[this.userStoreService.userLanguage || 'en'] || prop.en)
	}

	private sanitize(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html)
	}
}
