<div class='d-flex flex-column overflow-hidden-100p'>
  <ng-container>
    <header class='header-sub-view'>
      <section class='d-flex flex-row ms-2 p-2 ps-0'>
        <div class='m-0 d-flex flex-fill justify-content-center align-items-center mat-h3'>
          <div class='element-position-absolute-center'>
            <span data-qa='header-rated-assets-label'>{{'stepNavigation.labelRatedAssets' | translate: {
              from: assetsRatedCount,
              to: assets.length
            } }}</span>
          </div>
        </div>
      </section>
      <mat-divider class='pb-2'></mat-divider>
    </header>
  </ng-container>

  <ng-container>
    <div class='overflow-hidden-100p'>
      <section data-qa='asset-grid-section'
               class='height-100p overflow-auto d-flex flex-row flex-wrap align-content-start'
               *ngIf='!!workflowConfig.containedAssets'>
        <ng-container *ngFor='let asset of workflowConfig.containedAssets'>
          <app-consent-list-asset [attr.data-qa]="'asset-id$' + asset.id" [asset]='asset'></app-consent-list-asset>
        </ng-container>
      </section>
    </div>
  </ng-container>
</div>
