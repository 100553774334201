<div class='border border-1 border-primary ms-3 me-3'>
  <div class='can-progress-bar' [ngClass]="'status-' + this.approvalAsset.status.toLowerCase()">

    <ng-container *ngIf='manager === ManagerEnum.Requester'>
      <ng-container *ngIf='approvalAsset.status === FilterStateStatus.IN_PROGRESS'>
        <div class='indicator' [ngClass]="'indicator-' + this.approvalAsset.status.toLowerCase()"
             [style.width.%]='(this.approvalAsset.stepIndex) * 100 / (this.approvalAsset.totalSteps)'></div>
        <div class='content'>
          {{approvalAsset.stepIndex === approvalAsset.totalSteps ? (approvalAsset.stepIndex) : (approvalAsset.stepIndex + 1)}} / {{approvalAsset.totalSteps}}
        </div>
      </ng-container>
      <ng-container
        *ngIf='approvalAsset.status === FilterStateStatus.OVERDUE ||
          approvalAsset.status === FilterStateStatus.PAUSED ||
          approvalAsset.status === FilterStateStatus.REJECTED ||
          approvalAsset.status === FilterStateStatus.CLOSED ||
          approvalAsset.status === FilterStateStatus.APPROVED
    '>
        <div class='indicator' [ngClass]="'indicator-' + this.approvalAsset.status.toLowerCase()" [style.width.%]='100'></div>
        <div class='content'>
          <ng-container *ngTemplateOutlet='progressIconTemplate; context: approvalAsset'></ng-container>
          {{'filterStates.' + manager + '.' + approvalAsset.status | translate}}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf='manager === ManagerEnum.Approver'>
      <div class='indicator' [ngClass]="'indicator-' + this.approvalAsset.status.toLowerCase()" [style.width.%]='100'></div>
      <div class='content'>
        <ng-container *ngTemplateOutlet='progressIconTemplate; context: approvalAsset'></ng-container>
        {{'filterStates.' + manager + '.' + approvalAsset.status | translate}}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #progressIconTemplate let-status='status'>
  <span class='me-2'>
    <ng-container *ngIf='status === FilterStateStatus.APPROVED || status === FilterStateStatus.CLOSED'>
      <mat-icon class='' [inline]='true'>thumb_up</mat-icon>
    </ng-container>
    <ng-container *ngIf='status === FilterStateStatus.REJECTED'>
      <mat-icon class='' [inline]='true'>thumb_down</mat-icon>
    </ng-container>
    <ng-container *ngIf='status === FilterStateStatus.PAUSED'>
      <mat-icon class='' [inline]='true'>pause</mat-icon>
    </ng-container>
    <ng-container *ngIf='status === FilterStateStatus.OVERDUE'>
      <mat-icon class='' [inline]='true'>alarm</mat-icon>
    </ng-container>
  </span>
</ng-template>


