<div data-qa='consent-list-asset' class='border border-primary d-flex flex-column'
     (click)='openDetails()'
>
  <div data-qa='consent-asset-thumbnail' *ngIf='thumbnailUrl' class='can-background-image'
       [style.background-image]="'url(' + thumbnailUrl + ')'" [class.cursor-pointer]='true'>
    <div>
      <ng-container *ngFor='let status of assetStatusList'>
        <ng-container
          *ngTemplateOutlet='statusIconTemplate; context: {status}'></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #statusIconTemplate let-status='status'>
  <div class='can-rating-icon bg-primary d-inline-flex'>
    <div class='d-flex flex-1 overflow-hidden-100p flex-center'
         [ngStyle]='{backgroundColor: status.iconBackgroundColor}'
         [title]='status.displayName | localizedString | async'>
      <mat-icon [attr.data-qa]="'asset-status$' + status.id"
                [ngStyle]='{color: status.iconColor}'>
        {{status.muiIconName}}
      </mat-icon>
    </div>
  </div>
</ng-template>
