import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ConsentListViewModule } from 'core/components/consent/consent-list-overview/components/consent-list-view/consent-list-view.module'
import { ConsentListOverviewComponent } from 'core/components/consent/consent-list-overview/consent-list-overview.component'
import { LoadingSpinnerModule } from 'shared/components'

@NgModule({
	declarations: [ConsentListOverviewComponent],
	exports: [ConsentListOverviewComponent],
	imports: [CommonModule, ConsentListViewModule, LoadingSpinnerModule],
})
export class ConsentListOverviewModule {}
