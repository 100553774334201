import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ApprovalRequestOverviewComponent } from 'core/components/approval-request-overview/approval-request-overview.component'
import { EnumAppEndpoint } from 'core/models'
import { APP_PERMISSION } from 'core/models/appPermissions'
import { RouteSchemeData } from 'core/models/routeScheme'
import { AuthGuardService } from 'core/services/auth-guard.service'
import { UserRoleGuard } from 'core/services/user-role.guard'

export const routes: Routes = [
	{
		path: EnumAppEndpoint.Requester,
		component: ApprovalRequestOverviewComponent,
		data: {
			roles: [APP_PERMISSION.request],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
	{
		path: EnumAppEndpoint.Approver,
		component: ApprovalRequestOverviewComponent,
		data: {
			roles: [APP_PERMISSION.approve],
		} as RouteSchemeData,
		canActivate: [AuthGuardService, UserRoleGuard],
	},
]

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ApprovalRequestOverviewRoutingModule {}
