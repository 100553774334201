import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { LoadingSpinnerModule } from 'shared/components'
import { ConfirmDialogComponent } from 'shared/components/confirm-dialog/confirm-dialog.component'

@NgModule({
	declarations: [ConfirmDialogComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		LoadingSpinnerModule,
	],
	exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
