import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(
		value: any,
		keys: string,
		term: string,
		operator: 'contains' | 'startsWith' = 'contains'
	) {
		if (!term) {
			return value
		}
		let flags: string
		let pattern: string
		switch (operator) {
			case 'startsWith':
				flags = 'i'
				pattern = '^' + term
				break
			default: // default is contains
				flags = 'gi'
				pattern = term
		}
		return (value || []).filter((item: any) =>
			keys
				.split(',')
				.some(
					(key) =>
						item.hasOwnProperty(key.trim()) && new RegExp(pattern, flags).test(item[key.trim()])
				)
		)
	}
}
