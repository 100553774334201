<div class='d-flex flex-column overflow-auto height-100p'>
  <section class='border-bottom border-primary'>
    <article>
      <div class='mat-body-1'>
        <div>
          <div
            data-qa='details-sidebar-currentStep-name'><span>{{ workflowConfig.configuration.title | localizedString | async }}</span>
          </div>
        </div>
        <div data-qa='details-sidebar-currentStep-description' class='mat-body-2'
             [innerHTML]='workflowConfig.configuration.description | localizedHtmlString | async'></div>
      </div>
    </article>
  </section>
  <section>
    <article>
      <app-button-group-rating (clickStatus)='onStatusClick($event)'
                               [assetStatus]='assetStatus'
                               [loading$]='updatingStatus$'
                               [statusList]='statusList'>
      </app-button-group-rating>
    </article>
  </section>
</div>
