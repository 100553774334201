import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FinishStepDialogResultModel } from 'core/components/manage-review-approval-view/components/step-navigation'
import { indicate } from 'core/http'
import { AssetActivities, EnumFilterStateStatus, RatingStepStatusResponse } from 'core/models'
import { RatingService } from 'core/services'
import { Subject } from 'rxjs'
import { ApprovalBaseComponent } from 'shared/components'

export interface FinishDialogDataModel {
	approvalId: string
	statusResponse: RatingStepStatusResponse
}

@Component({
	selector: 'app-finish-view-dialog',
	templateUrl: './finish-view-dialog.component.html',
	styleUrls: ['./finish-view-dialog.component.scss'],
})
export class FinishViewDialogComponent extends ApprovalBaseComponent {
	finishStepRunning$ = new Subject<boolean>()
	@ViewChild('commentTextarea') commentTextarea!: ElementRef

	constructor(
		private ratingService: RatingService,
		public componentDialogRef: MatDialogRef<FinishViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: FinishDialogDataModel
	) {
		super()

		this.finishStepRunning$.subscribe((value) => this.handleCanDialogClose(value))
	}

	closeView() {
		this.componentDialogRef.close()
	}

	handleCanDialogClose(disableClose: boolean) {
		this.componentDialogRef.disableClose = disableClose
	}

	doFinishStep() {
		this.ratingService
			.finishStep(
				this.data.approvalId,
				this.data.statusResponse.stepId,
				this.commentTextarea.nativeElement.value
			)
			.pipe(indicate(this.finishStepRunning$))
			.subscribe({
				next: (value) => {
					if (value.stepStatus === EnumFilterStateStatus.FINISHED) {
						this.stepNowFinished(value)
					}
				},
				error: (error) => {
					// TODO error implementation when in Sprint
					console.error(error)
				},
			})
	}

	stepNowFinished(response: AssetActivities) {
		this.componentDialogRef.close({
			activity: response,
			status: this.data.statusResponse.status,
		} as FinishStepDialogResultModel)
	}
}
