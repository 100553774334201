import { HttpClient } from '@angular/common/http'
import { Configuration } from 'core/models/configuration'
import { UiConfiguration } from 'core/services'
import { of } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { ObservableInput } from 'rxjs/internal/types'
import { catchError, map } from 'rxjs/operators'

export function loadConfig(http: HttpClient, uiConfig: UiConfiguration): Promise<boolean> {
	return new Promise<boolean>((resolve: (a: boolean) => void): void => {
		http
			.get('./assets/app.config.json', { responseType: 'text' })
			.pipe(
				map((configString: string) => {
					let result = ''
					configString
						.split('\n')
						.filter((line) => !line.trim().startsWith('//'))
						.forEach((line) => (result += line))
					return JSON.parse(result)
				}),
				map((x: Configuration) => {
					Object.assign(uiConfig, x.uiConfiguration)
					resolve(true)
					return x
				}),
				catchError(
					(
						x: { status: number; message: string },
						_: Observable<Configuration>
					): ObservableInput<{}> => {
						console.warn(x.message)
						resolve(false)
						return of({})
					}
				)
			)
			.subscribe()
	})
}
