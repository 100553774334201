import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { TranslateModule } from '@ngx-translate/core'
import { ConsentListAssetModule } from 'core/components/consent/consent-list-overview/components/consent-list-asset/consent-list-asset.module'
import { ConsentListViewComponent } from 'core/components/consent/consent-list-overview/components/consent-list-view/consent-list-view.component'

@NgModule({
	declarations: [ConsentListViewComponent],
	exports: [ConsentListViewComponent],
	imports: [CommonModule, ConsentListAssetModule, MatDividerModule, TranslateModule],
})
export class ConsentListViewModule {}
