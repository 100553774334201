<ng-template #ListOfAssetCommentsTemplate>
  <table>
    <thead>
    <tr>
      <th class='mat-body-1'>{{'viewComments.labelAsset' | translate}}</th>
      <th class='mat-body-1' colspan='2'>{{'viewComments.labelComment' | translate}}</th>
    </tr>
    </thead>
    <tr>
      <td colspan='3'>
        <mat-divider></mat-divider>
      </td>
    </tr>
    <tbody>
    <ng-container *ngFor='let asset of assets'>
      <tr *ngIf='asset?.newestComment?.comment'>
        <td>{{asset.name}}</td>
        <td class='rating table-cell-width-auto me-2'
            [ngClass]="'rating-icon-color-status-'+ asset?.newestActivity.rating?.toLowerCase()">
          <mat-icon class='width-100p' [inline]='true'
                    *ngIf='asset?.newestActivity.rating === FilterStateStatus.APPROVED'>thumb_up
          </mat-icon>
          <mat-icon class='width-100p' [inline]='true'
                    *ngIf='asset?.newestActivity.rating === FilterStateStatus.REJECTED'>thumb_down
          </mat-icon>
        </td>
        <td>{{asset?.newestComment?.comment}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #StepCommentTemplate>
  <table>
    <thead>
    <tr>
      <th class='mat-body-1'>{{'viewComments.labelStepComment' | translate}}</th>
    </tr>
    </thead>
    <tr>
      <td colspan='2'>
        <mat-divider></mat-divider>
      </td>
    </tr>
    <tbody>
    <tr>
      <td>{{step?.newestComment?.comment}}</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<div mat-dialog-title class='d-flex flex-row'>
  <h3 class='flex-1'>{{data.currentStep?.step.name}} - {{'viewComments.titleDialog' | translate}}</h3>
  <div class='col-2 d-flex justify-content-end'>
    <button mat-mini-fab
            (click)='closeView()'
            color='primary'
            class='shadow-none'>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div>
    <ng-template #loadingSpinner>
      <app-loading-spinner class='primary-canto'></app-loading-spinner>
    </ng-template>
    <ng-container *ngIf='((loading$ | async) === false) else loadingSpinner'>
      <section class='mb-3'>
        <ng-container *ngTemplateOutlet='StepCommentTemplate'></ng-container>
      </section>
      <section class='comments'>
        <ng-container *ngTemplateOutlet='ListOfAssetCommentsTemplate'></ng-container>
      </section>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions class='justify-content-end'>
  <button mat-button [mat-dialog-close]='true'
          class='active btn-rounded'>{{'shared.buttonCloseLabel' | translate}}</button>
</mat-dialog-actions>
