export const environment = {
	zone: 'test',
	fqdn: 'EU', // fully qualified domain name
	production: false,
	api: {
		url: 'https://api.test.approval.canto.solutions',
		mockUrl: 'https://066256de-e899-4812-afc7-d6dbd7e8620e.mock.pstmn.io',
	},
	apiAuth: {
		url: 'https://api.test.auth.canto.solutions',
		mockUrl: 'https://d5259501-b6c7-41a9-8321-728d6d2e9f77.mock.pstmn.io',
	},
}
